import { readableColor } from 'polished'
import { PropsWithChildren } from 'react'
import styled, { DefaultTheme } from 'styled-components/macro'

export enum BadgeVariant {
  DEFAULT = 'DEFAULT',
  NEGATIVE = 'NEGATIVE',
  POSITIVE = 'POSITIVE',
  PRIMARY = 'PRIMARY',
  WARNING = 'WARNING',
  PROMOTIONAL = 'PROMOTIONAL',
  BRANDED = 'BRANDED',
  SOFT = 'SOFT',

  WARNING_OUTLINE = 'WARNING_OUTLINE',
}

interface BadgeProps {
  variant?: BadgeVariant
}

function pickBackgroundColor(variant: BadgeVariant | undefined, theme: DefaultTheme): string {
  switch (variant) {
    case BadgeVariant.BRANDED:
      return theme.brandedGradient
    case BadgeVariant.PROMOTIONAL:
      return theme.promotionalGradient
    case BadgeVariant.NEGATIVE:
      return theme.accentCritical
    case BadgeVariant.POSITIVE:
      return theme.accentSuccess
    case BadgeVariant.SOFT:
      return theme.accentActionSoft
    case BadgeVariant.PRIMARY:
      return theme.accentAction
    case BadgeVariant.WARNING:
      return theme.accentWarning
    case BadgeVariant.WARNING_OUTLINE:
      return 'transparent'
    default:
      return theme.backgroundInteractive
  }
}

function pickBorder(variant: BadgeVariant | undefined, theme: DefaultTheme): string {
  switch (variant) {
    case BadgeVariant.WARNING_OUTLINE:
      return `1px solid ${theme.accentWarning}`
    default:
      return 'unset'
  }
}

function pickFontColor(variant: BadgeVariant | undefined, theme: DefaultTheme): string {
  switch (variant) {
    case BadgeVariant.BRANDED:
      return theme.darkMode ? theme.accentTextDarkPrimary : theme.white
    case BadgeVariant.NEGATIVE:
      return readableColor(theme.accentFailure)
    case BadgeVariant.POSITIVE:
      return readableColor(theme.accentSuccess)
    case BadgeVariant.SOFT:
      return theme.accentAction
    case BadgeVariant.WARNING:
      return readableColor(theme.accentWarning)
    case BadgeVariant.WARNING_OUTLINE:
      return theme.accentWarning
    default:
      return readableColor(theme.backgroundInteractive)
  }
}

const Badge = styled.div<PropsWithChildren<BadgeProps>>`
  align-items: center;
  border-radius: 8px;
  display: inline-flex;
  padding: 8px 10px;
  background: ${({ theme }) => theme.badgeBackground};
  justify-content: center;
  font-size: 16px;
  font-weight: 700;
`

export default Badge
