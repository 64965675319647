import { Trans } from '@lingui/macro'
import { TableDataContainer } from 'components/Table'
import { MAX_WIDTH_MEDIA_BREAKPOINT } from 'components/Tokens/constants'
import { PAGE_SIZE } from 'graphql/data/TopTokens'
import { validateUrlChainParam } from 'graphql/data/util'
import { ReactNode } from 'react'
import { AlertTriangle } from 'react-feather'
import { useParams } from 'react-router-dom'
import styled from 'styled-components/macro'

import { HeaderRow, LoadedRow, LoadingRow } from './DetailRow'

const GridContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: ${MAX_WIDTH_MEDIA_BREAKPOINT};
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  align-items: center;
`

const NoTokenDisplay = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  height: 60px;
  color: ${({ theme }) => theme.textSecondary};
  font-size: 16px;
  font-weight: 500;
  align-items: center;
  padding: 0px 28px;
  gap: 8px;
`

function NoTokensState({ message }: { message: ReactNode }) {
  return (
    <GridContainer>
      <HeaderRow />
      <NoTokenDisplay>{message}</NoTokenDisplay>
    </GridContainer>
  )
}

const LoadingRows = ({ rowCount }: { rowCount: number }) => (
  <>
    {Array(rowCount)
      .fill(null)
      .map((_, index) => {
        return <LoadingRow key={index} first={index === 0} last={index === rowCount - 1} />
      })}
  </>
)

function LoadingTokenTable({ rowCount = PAGE_SIZE }: { rowCount?: number }) {
  return (
    <GridContainer>
      <HeaderRow />
      <TableDataContainer>
        <LoadingRows rowCount={rowCount} />
      </TableDataContainer>
    </GridContainer>
  )
}

export default function Table() {
  const chainName = validateUrlChainParam(useParams<{ chainName?: string }>().chainName)

  const loadingLeaderboards = true
  // const leaderboardList = []
  const leaderboardList = [
    { token: 'ROX-ETH', leaderboardAmount: 214349313.99, leaderboardPower: 214349313.99, wallet: 2, staked: 1 },
    { token: 'ROX-ETH', leaderboardAmount: 214349313.99, leaderboardPower: 214349313.99, wallet: 2, staked: 1 },
    { token: 'ROX-ETH', leaderboardAmount: 214349313.99, leaderboardPower: 214349313.99, wallet: 2, staked: 1 },
  ]
  /* loading and error state */
  if (loadingLeaderboards && !leaderboardList) {
    return <LoadingTokenTable rowCount={PAGE_SIZE} />
  } else if (!leaderboardList) {
    return (
      <NoTokensState
        message={
          <>
            <AlertTriangle size={16} />
            <Trans>An error occurred loading leaderboardList. Please try again.</Trans>
          </>
        }
      />
    )
  } else if (leaderboardList?.length === 0) {
    return <NoTokensState message={<Trans>No leaderboardList found</Trans>} />
  } else {
    return (
      <>
        <HeaderRow />
        <TableDataContainer>
          {leaderboardList.map(
            (leaderboard, index) =>
              leaderboard?.token && (
                <LoadedRow
                  key={index}
                  leaderboardListIndex={index}
                  leaderboardListLength={leaderboardList.length}
                  leaderboard={leaderboard}
                />
              )
          )}
        </TableDataContainer>
      </>
    )
  }
}
