import { Currency, Token } from '@uniswap/sdk-core'
import { FeeAmount } from '@uniswap/v3-sdk'
import { useActiveChainId } from 'connection/useActiveChainId'
import { useSingleContractMultipleData } from 'lib/hooks/multicall'
import { useMemo } from 'react'

import { useV3Factory } from './useContract'

export function useTradePositionPool(
  currencyA: Currency | undefined,
  currencyB: Currency | undefined,
  feeAmount: FeeAmount | undefined
): string | undefined {
  const { chainId } = useActiveChainId()
  const poolfactoryContract = useV3Factory()
  const poolKeys: [Currency | undefined, Currency | undefined, FeeAmount | undefined][] = useMemo(
    () => [[currencyA, currencyB, feeAmount]],
    [currencyA, currencyB, feeAmount]
  )
  const poolTokens: ([Token, Token, FeeAmount] | undefined)[] = useMemo(() => {
    if (!chainId) return new Array(poolKeys.length)

    return poolKeys.map(([currencyA, currencyB, feeAmount]) => {
      if (currencyA && currencyB && feeAmount) {
        const tokenA = currencyA.wrapped
        const tokenB = currencyB.wrapped
        if (tokenA.equals(tokenB)) return undefined

        return tokenA.sortsBefore(tokenB) ? [tokenA, tokenB, feeAmount] : [tokenB, tokenA, feeAmount]
      }
      return undefined
    })
  }, [chainId, poolKeys])

  const v3Prams = useMemo(() => {
    return poolTokens.map((value) => {
      if (!value) return [undefined]
      return [value[0].address, value[1].address, value[2].toFixed()]
    })
  }, [poolTokens])

  const paArrRes = useSingleContractMultipleData(poolfactoryContract, 'getTradePool', v3Prams)

  const poolAddresses: [string | undefined] = useMemo(() => {
    return paArrRes.reduce((acc: any, curr: any) => {
      acc.push(curr?.result?.[0])
      return acc
    }, [])
  }, [paArrRes])

  return poolAddresses[0]
}
