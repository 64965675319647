import { Trans } from '@lingui/macro'
import { ButtonConnect } from 'components/Button'
import LearnMore from 'components/LearnMore'
import Row from 'components/Row'
import { PageWrapper } from 'components/swap/styleds'
import { TitleDesc, TitleRow } from 'nft/components/profile/list/shared'
import { Link } from 'react-router-dom'
import styled from 'styled-components/macro'
import { ThemedText } from 'theme'

import ContentWrapper from './ContentWrapper'
import Table from './Table'

const LiquidityBox = styled(Row)``

export default function Liquidity() {
  return (
    <PageWrapper data-testid="liquidity-page">
      <TitleRow>
        <ThemedText.LargeHeader>
          <Trans>Liquidity</Trans>
        </ThemedText.LargeHeader>
      </TitleRow>
      <TitleDesc>
        <ThemedText.HeaderDesc lineHeight="16px">
          <Trans>Pair your tokens to provide liquidity.Stake LP tokens to earn $ROX.</Trans>
        </ThemedText.HeaderDesc>
        <Row ml="8px" width="auto">
          <LearnMore />
        </Row>
      </TitleDesc>
      <Row mt="20px">
        <ButtonConnect as={Link} to="/add/ETH">
          Add Liquidity
        </ButtonConnect>
      </Row>
      <ContentWrapper />
      <Table />
    </PageWrapper>
  )
}
