import { Trans } from '@lingui/macro'
import { useTrace } from 'analytics'
import AnimatedDropdown from 'components/AnimatedDropdown'
import { RowFixed } from 'components/Row'
import { useState } from 'react'
import { useTheme } from 'styled-components/macro'
import { ThemedText } from 'theme'

import { ReactComponent as DownIcon } from '../../assets/imgs/down.svg'
import AdvancedFeeDetails from './AdvancedFeeDetails'
import { DropdownWrapper, RotatingArrow, StyledHeaderRow, SwapDetailsWrapper } from './DropDownComponents'

export default function SwapFeeDropdown() {
  const theme = useTheme()

  const [showDetails, setShowDetails] = useState(false)
  const trace = useTrace()

  return (
    <div>
      <DropdownWrapper>
        <StyledHeaderRow
          data-testid="swap-fee-header-row"
          onClick={() => setShowDetails(!showDetails)}
          disabled={false}
          open={showDetails}
        >
          <RowFixed>
            <ThemedText.BodySmall color="textSecondary">
              <Trans>Network fees</Trans>
            </ThemedText.BodySmall>
          </RowFixed>
          <RowFixed>
            <ThemedText.BodySmall color="black" mr="4px">
              $0.13
            </ThemedText.BodySmall>
            <RotatingArrow open={Boolean(showDetails)}>
              <DownIcon />
            </RotatingArrow>
          </RowFixed>
        </StyledHeaderRow>
        <AnimatedDropdown open={showDetails}>
          <SwapDetailsWrapper data-testid="advanced-swap-fee-details">
            <AdvancedFeeDetails />
          </SwapDetailsWrapper>
        </AnimatedDropdown>
      </DropdownWrapper>
    </div>
  )
}
