import { formatPrice, NumberType } from '@uniswap/conedison/format'
import { Currency, Price } from '@uniswap/sdk-core'
import Column from 'components/Column'
import { OpacityHoverState } from 'components/Common'
import { AutoRow } from 'components/Row'
import { useUSDPrice } from 'hooks/useUSDPrice'
import tryParseCurrencyAmount from 'lib/utils/tryParseCurrencyAmount'
import { useMemo, useState } from 'react'
import styled, { useTheme } from 'styled-components/macro'
import { ThemedText } from 'theme'

import { TabPeriod } from './types'

const Wrapper = styled(Column)`
  width: 100%;
  border: 1px solid ${({ theme }) => theme.swapSectionBorder};
  border-radius: 16px;
  padding: 8px 16px 16px;
  margin-bottom: 8px;
  align-items: flex-start;
`
const Filter = styled.div`
  display: flex;
  background: ${({ theme }) => theme.backgroundTab};
  border-radius: 20px;
`
const Selector = styled.div<{ active: boolean }>`
  padding: 8px;
  border-radius: 20px;
  background: ${({ active, theme }) => (active ? theme.backgroundTabActive : 'none')};
  cursor: pointer;
  ${OpacityHoverState}
`
const StyledSelectorText = styled(ThemedText.SubHeader)<{ active: boolean }>`
  color: ${({ theme, active }) => (active ? theme.textTabActive : theme.textTab)};
`

export function TradeType() {
  const [tabPeriod, setTabPeriod] = useState<TabPeriod>(TabPeriod.Market)
  const tabOptions: { label: string; value: TabPeriod }[] = [
    { label: 'Market', value: TabPeriod.Market },
    { label: 'Limit', value: TabPeriod.Limit },
  ]
  return (
    <Filter>
      {tabOptions.map((tabOption) => {
        return (
          <Selector
            key={tabOption.value}
            active={tabOption.value === tabPeriod}
            onClick={() => setTabPeriod(tabOption.value)}
          >
            <StyledSelectorText lineHeight="20px" active={tabOption.value === tabPeriod}>
              {tabOption.label}
            </StyledSelectorText>
          </Selector>
        )
      })}
    </Filter>
  )
}

type SwapDetailsProps = {
  price?: Price<Currency, Currency>
  showInverted?: number
}

export default function SwapDetails({ price, showInverted }: SwapDetailsProps) {
  const theme = useTheme()

  // const [showInverted, setShowInverted] = useState<boolean>(false)

  const showInverteds = useMemo(() => {
    return showInverted === 1
  }, [showInverted])
  const { baseCurrency, quoteCurrency } = price ?? {}
  const formattedPrice = useMemo(() => {
    if (!price) return '0'
    try {
      return formatPrice(showInverteds ? price : price.invert(), NumberType.TokenTx)
    } catch {
      return '0'
    }
  }, [price, showInverteds])

  const { data: usdPrice } = useUSDPrice(tryParseCurrencyAmount('1', showInverteds ? baseCurrency : quoteCurrency))

  const label = showInverteds ? `${price?.quoteCurrency?.symbol}` : `${price?.baseCurrency?.symbol} `
  const labelInverted = showInverteds ? `${price?.baseCurrency?.symbol} ` : `${price?.quoteCurrency?.symbol}`
  // const flipPrice = useCallback(() => setShowInverted(!showInverted), [setShowInverted, showInverted])

  return (
    <Wrapper>
      <AutoRow justify="space-between">
        <ThemedText.BodySmall color="color84">1 {labelInverted}= ($413.91)</ThemedText.BodySmall>
        <TradeType />
      </AutoRow>
      <ThemedText.BodySmall color="color06" mt="8px " fontSize={28} fontWeight={500}>
        {formattedPrice ?? '-'} {label}
      </ThemedText.BodySmall>
    </Wrapper>
  )
}
