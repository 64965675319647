import { Trans } from '@lingui/macro'
import { ButtonConfirmed } from 'components/Button'
import { AutoColumn } from 'components/Column'
import SwapCurrencyInputPanel from 'components/CurrencyInputPanel/SwapCurrencyInputPanel'
import MaxTab from 'components/MaxTab'
import Modal from 'components/Modal'
import Row, { RowBetween } from 'components/Row'
import { TradeType } from 'components/swap/SwapDetails'
import { SwapSection } from 'pages/Swap/Styled'
import { ReactNode, useCallback, useState } from 'react'
import styled from 'styled-components/macro'
import { ThemedText } from 'theme'

import { ReactComponent as CloseIcon } from '../../../assets/imgs/close.svg'

interface EditPositionModalProps {
  isOpen: boolean
  onDismiss: () => void
}

const Wrapper = styled.div`
  background-color: ${({ theme }) => theme.backgroundSurface};
  border-radius: 20px;
  outline: 1px solid ${({ theme }) => theme.backgroundOutline};
  width: 800px;
  max-width: 100%;
  padding: 16px 8px;
`

const EditWrapper = styled.div`
  width: 364px;
`

const EditInfo = styled.div`
  flex: 1;
  padding: 12px 16px;
  background-color: ${({ theme }) => theme.backgroundInteractive};
  border-radius: 16px;
  .infoItem {
    padding: 8px 0;
  }
  .border-bottom {
    border-bottom-width: 1px;
    border-bottom-color: ${({ theme }) => theme.swapSectionBorder};
  }
  .border-solid {
    border-bottom-style: solid;
  }
  .border-dashed {
    border-bottom-style: dashed;
  }
`

const CheckWapper = styled(Row)`
  align-items: center;
  padding: 10px;
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.swapSectionBorder};
`

const EditSwapSection = styled(SwapSection)`
  border-radius: 12px;
  margin-top: 8px;
`

function EditPositionModalContent({
  title,
  onDismiss,
  modalContent,
}: {
  title: ReactNode
  onDismiss: () => void
  modalContent: () => ReactNode
}) {
  return (
    <Wrapper>
      <AutoColumn gap="sm">
        <Row>
          <Row justify="center" marginLeft="24px">
            <ThemedText.SubHeader>{title}</ThemedText.SubHeader>
          </Row>
          <CloseIcon onClick={onDismiss} data-testid="confirmation-close-icon" style={{ marginRight: '8px' }} />
        </Row>
      </AutoColumn>
      {modalContent()}
    </Wrapper>
  )
}

function ModalContent() {
  const [inputValue, setInputValue] = useState('')
  const handleTypeInput = (e: any) => {
    // setInputValue(e.target.value)
  }

  return (
    <Row gap="24px" mt="16px" align="flex-start">
      <EditWrapper>
        <AutoColumn gap="sm">
          <Row width="auto">
            <TradeType />
          </Row>
          <EditSwapSection>
            <SwapCurrencyInputPanel
              label={<Trans>Edit</Trans>}
              disabled={false}
              value={inputValue}
              showMaxButton={false}
              id="close-input"
              onUserInput={handleTypeInput}
              fiatValue={{ data: 300, isLoading: false }}
              loading={false}
            />
            <MaxTab />
          </EditSwapSection>
          <RowBetween mt="8px">
            <ThemedText.TextSecondary fontSize={14}>Leverage</ThemedText.TextSecondary>
            <ThemedText.TextPrimary fontWeight={500}>8.3x</ThemedText.TextPrimary>
          </RowBetween>
          <RowBetween>
            <ThemedText.TextSecondary fontSize={14}>Receive</ThemedText.TextSecondary>
            <ThemedText.TextPrimary fontWeight={500}>-0.005%</ThemedText.TextPrimary>
          </RowBetween>
          <ButtonConfirmed data-testid="confirm-trade-button" id="confirm-trade-button" mt="8px">
            <ThemedText.HeadlineSmall color="accentTextLightPrimary">
              <Trans>Submit</Trans>
            </ThemedText.HeadlineSmall>
          </ButtonConfirmed>
        </AutoColumn>
      </EditWrapper>
      <EditInfo>
        <RowBetween className="infoItem border-bottom border-dashed">
          <ThemedText.TextSecondary fontSize={14}>Size</ThemedText.TextSecondary>
          <ThemedText.TextPrimary fontWeight={500}>$0.331</ThemedText.TextPrimary>
        </RowBetween>
        <RowBetween className="infoItem border-bottom border-solid">
          <ThemedText.TextSecondary fontSize={14}>Collateral</ThemedText.TextSecondary>
          <ThemedText.TextPrimary fontWeight={500}>$0.331</ThemedText.TextPrimary>
        </RowBetween>
        <RowBetween className="infoItem border-bottom border-dashed" mt="8px">
          <ThemedText.TextSecondary fontSize={14}>Market Price</ThemedText.TextSecondary>
          <ThemedText.TextPrimary fontWeight={500}>$0.331</ThemedText.TextPrimary>
        </RowBetween>
        <RowBetween className="infoItem border-bottom border-dashed">
          <ThemedText.TextSecondary fontSize={14}>Liq.Price</ThemedText.TextSecondary>
          <ThemedText.TextPrimary fontWeight={500}>$3,944,501</ThemedText.TextPrimary>
        </RowBetween>
        <RowBetween className="infoItem border-bottom border-dashed">
          <ThemedText.TextSecondary fontSize={14}>Execution Fee</ThemedText.TextSecondary>
          <ThemedText.TextPrimary fontWeight={500}>0.0001% / 1h</ThemedText.TextPrimary>
        </RowBetween>
      </EditInfo>
    </Row>
  )
}
export default function EditPositionModal({ isOpen, onDismiss }: EditPositionModalProps) {
  const onModalDismiss = useCallback(() => {
    onDismiss()
  }, [onDismiss])
  const modalContent = () => {
    return <ModalContent />
  }
  return (
    <Modal isOpen={isOpen} $scrollOverlay={true} onDismiss={onDismiss} maxHeight={90} width="800px">
      <EditPositionModalContent
        title={<Trans>Edit Long ROX</Trans>}
        onDismiss={onModalDismiss}
        modalContent={modalContent}
      />
    </Modal>
  )
}
