import { useCallback } from 'react'
import { useAppDispatch } from 'state/hooks'

import { selectPercent } from './actions'

export function useTradeSliderActionHandlers(): {
  onPercentSelect: (percent: number) => void
} {
  const dispatch = useAppDispatch()

  const onPercentSelect = useCallback(
    (percent: number) => {
      dispatch(selectPercent({ percent }))
    },
    [dispatch]
  )

  return {
    onPercentSelect,
  }
}
