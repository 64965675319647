import { Trans } from '@lingui/macro'
import Row from 'components/Row'
import { ThemedText } from 'theme/components/text'

export default function LockInfo() {
  return (
    <Row justify="space-between" className="lockInfo">
      <Row flexDirection="column" align="center">
        <ThemedText.TextSecondary>
          <Trans>TVL</Trans>
        </ThemedText.TextSecondary>
        <ThemedText.TextPrimary fontSize={24} fontWeight={700} mt="10px">
          214,349,313.99 ROX
        </ThemedText.TextPrimary>
        <ThemedText.TextSecondary fontSize={14} mt="4px">
          $214,349,313.99
        </ThemedText.TextSecondary>
      </Row>
      <Row flexDirection="column" align="center">
        <ThemedText.TextSecondary>
          <Trans>%Cir. Locked</Trans>
        </ThemedText.TextSecondary>
        <ThemedText.TextPrimary fontSize={24} fontWeight={700} mt="10px">
          78.90%
        </ThemedText.TextPrimary>
      </Row>
      <Row flexDirection="column" align="center">
        <ThemedText.TextSecondary>
          <Trans>Avg. Lock Time</Trans>
        </ThemedText.TextSecondary>
        <ThemedText.TextPrimary fontSize={24} fontWeight={700} mt="10px">
          3.43 Years
        </ThemedText.TextPrimary>
      </Row>
    </Row>
  )
}
