import { BigNumber } from '@ethersproject/bignumber'
import { t } from '@lingui/macro'
import { Currency, Percent, TradeType } from '@uniswap/sdk-core'
import { FeeOptions, SwapRouter, Trade } from '@uniswap/v3-sdk'
import { useTrace } from 'analytics'
import { useActiveChainId } from 'connection/useActiveChainId'
import { SWAP_ROUTER_ADDRESSES } from 'constants/addresses'
import { useCallback, useMemo } from 'react'
import { TradeFillType } from 'state/routing/types'
import { trace } from 'tracing/trace'
import { calculateGasMargin } from 'utils/calculateGasMargin'
import { UserRejectedRequestError } from 'utils/errors'
import isZero from 'utils/isZero'
import { didUserReject, swapErrorToUserReadableMessage } from 'utils/swapErrorToUserReadableMessage'

import { useArgentWalletContract } from './useArgentWalletContract'
import { PermitSignature } from './usePermitAllowance'
import useTransactionDeadline from './useTransactionDeadline'

interface SwapCall {
  address: string
  calldata: string
  value: string
}

interface SwapCallEstimate {
  call: SwapCall
}

interface SuccessfulCall extends SwapCallEstimate {
  call: SwapCall
  gasEstimate: BigNumber
}

interface FailedCall extends SwapCallEstimate {
  call: SwapCall
  error: Error
}

/** Thrown when gas estimation fails. This class of error usually requires an emulator to determine the root cause. */
class GasEstimationError extends Error {
  constructor() {
    super(t`Your swap is expected to fail.`)
  }
}

/**
 * Thrown when the user modifies the transaction in-wallet before submitting it.
 * In-wallet calldata modification nullifies any safeguards (eg slippage) from the interface, so we recommend reverting them immediately.
 */
class ModifiedSwapError extends Error {
  constructor() {
    super(
      t`Your swap was modified through your wallet. If this was a mistake, please cancel immediately or risk losing your funds.`
    )
  }
}

interface SwapOptions {
  slippageTolerance: Percent
  deadline?: BigNumber
  permit?: PermitSignature
  feeOptions?: FeeOptions
}

export function useV3SwapCallback(allowedSlippage: Percent, trade?: Trade<Currency, Currency, TradeType>) {
  const { account, chainId, provider } = useActiveChainId()
  const analyticsContext = useTrace()
  const swapCalls = useSwapCallArguments(trade, allowedSlippage)

  return useCallback(async () => {
    return trace('swap.send', async ({ setTraceData, setTraceStatus, setTraceError }) => {
      try {
        if (!account) throw new Error('missing account')
        if (!chainId) throw new Error('missing chainId')
        if (!provider) throw new Error('missing provider')
        if (!trade) throw new Error('missing trade')

        const estimatedCalls: SwapCallEstimate[] = await Promise.all(
          swapCalls.map((call) => {
            const { address, calldata, value } = call

            const tx =
              !value || isZero(value)
                ? { from: account, to: address, data: calldata }
                : {
                    from: account,
                    to: address,
                    data: calldata,
                    value,
                  }
            // console.log(library.estimateGas)
            return provider
              .estimateGas(tx)
              .then((gasEstimate) => {
                return {
                  call,
                  gasEstimate,
                }
              })
              .catch((gasError) => {
                console.debug('Gas estimate failed, trying eth_call to extract error', call)
                return provider
                  .call(tx)
                  .then((result) => {
                    console.debug('Unexpected successful call after failed estimate gas', call, gasError, result)
                    return { call, error: new Error('Unexpected issue with estimating the gas. Please try again.') }
                  })
                  .catch((callError) => {
                    console.debug('Call threw error', call, callError)
                    return { call, error: new Error(swapErrorToUserReadableMessage(callError)) }
                  })
              })
          })
        )

        // a successful estimation is a bignumber gas estimate and the next call is also a bignumber gas estimate
        let bestCallOption: SuccessfulCall | SwapCallEstimate | undefined = estimatedCalls.find(
          (el, ix, list): el is SuccessfulCall =>
            'gasEstimate' in el && (ix === list.length - 1 || 'gasEstimate' in list[ix + 1])
        )

        // check if any calls errored with a recognizable error
        if (!bestCallOption) {
          const errorCalls = estimatedCalls.filter((call): call is FailedCall => 'error' in call)
          if (errorCalls.length > 0) throw errorCalls[errorCalls.length - 1].error
          const firstNoErrorCall = estimatedCalls.find<SwapCallEstimate>(
            (call): call is SwapCallEstimate => !('error' in call)
          )
          if (!firstNoErrorCall) throw new Error('Unexpected error. Could not estimate gas for the swap.')
          bestCallOption = firstNoErrorCall
        }
        const {
          call: { address, calldata, value },
        } = bestCallOption

        const response = await provider.getSigner().sendTransaction({
          from: account,
          to: address,
          data: calldata,
          // let the wallet try if we can't estimate the gas
          // TODO   set gaslimit
          ...('gasEstimate' in bestCallOption ? { gasLimit: calculateGasMargin(bestCallOption.gasEstimate) } : {}),
          // ...addcc,
          // ...('gasEstimate' in bestCallOption ? { gasLimit: 594812, gasPrice: 5000000000 } : {}),
          ...(value && !isZero(value) ? { value } : {}),
        })
        return {
          type: TradeFillType.Classic as const,
          response,
        }
      } catch (swapError: unknown) {
        console.log('[swapError]:', swapError)
        if (swapError instanceof ModifiedSwapError) throw swapError

        // GasEstimationErrors are already traced when they are thrown.
        if (!(swapError instanceof GasEstimationError)) setTraceError(swapError)

        // Cancellations are not failures, and must be accounted for as 'cancelled'.
        if (didUserReject(swapError)) {
          setTraceStatus('cancelled')
          // This error type allows us to distinguish between user rejections and other errors later too.
          throw new UserRejectedRequestError(swapErrorToUserReadableMessage(swapError))
        }

        throw new Error(swapErrorToUserReadableMessage(swapError))
      }
    })
  }, [account, chainId, provider, swapCalls, trade])
}

function useSwapCallArguments(
  trade: Trade<Currency, Currency, TradeType> | undefined, // trade to execute, required
  allowedSlippage: Percent // in bips
): SwapCall[] {
  const { account, chainId, provider: library } = useActiveChainId()

  const recipient = account
  const deadline = useTransactionDeadline()
  const argentWalletContract = useArgentWalletContract()

  return useMemo(() => {
    if (!trade || !recipient || !library || !account || !chainId || !deadline) return []
    const swapRouterAddress = SWAP_ROUTER_ADDRESSES[chainId]
    // trade is V3Trade
    if (!swapRouterAddress) return []
    const { value, calldata } = SwapRouter.swapCallParameters(trade, {
      recipient,
      slippageTolerance: allowedSlippage,
      deadline: deadline.toString(),
    })
    if (argentWalletContract && trade.inputAmount.currency.isToken && swapRouterAddress) {
      return [
        {
          address: argentWalletContract.address,
          calldata: argentWalletContract.interface.encodeFunctionData('wc_multiCall', [
            [
              // approveAmountCalldata(trade.maximumAmountIn(allowedSlippage), swapRouterAddress),
              {
                to: swapRouterAddress,
                value: value.toString(),
                data: calldata,
              },
            ],
          ]),
          value: '0x0',
        },
      ]
    }
    return [
      {
        address: swapRouterAddress,
        calldata,
        value,
      },
    ]
  }, [account, allowedSlippage, argentWalletContract, chainId, deadline, library, recipient, trade])
}
