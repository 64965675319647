import { Trans } from '@lingui/macro'
import { ClaimButton } from 'components/Button'
import { IconLoadingBubble, LoadingBubble, MediumLoadingBubble } from 'components/Loading'
import Row from 'components/Row'
import { Cell, StyledTableRow } from 'components/Table'
import {
  LARGE_MEDIA_BREAKPOINT,
  MAX_WIDTH_MEDIA_BREAKPOINT,
  MEDIUM_MEDIA_BREAKPOINT,
  SMALL_MEDIA_BREAKPOINT,
} from 'components/Tokens/constants'
import { CSSProperties, ForwardedRef, forwardRef, ReactNode } from 'react'
import { NavLink } from 'react-router-dom'
import styled, { css } from 'styled-components/macro'
import { ThemedText } from 'theme'

import { ReactComponent as NftIcon } from '../../assets/imgs/nft.svg'
import { TokenSortMethod } from './state'

const StyledLeaderboardRow = styled(StyledTableRow)<{
  first?: boolean
  last?: boolean
  $loading?: boolean
}>`
  align-items: stretch;
  grid-template-columns: 0.5fr 1.5fr 2fr 2fr 2fr 2fr;
  max-width: ${MAX_WIDTH_MEDIA_BREAKPOINT};
  min-width: 390px;
  padding: 0;
  margin: 0;
  ${({ first, last }) => css`
    /* height: ${first || last ? '72px' : '64px'}; */
  `}
  transition: ${({
    theme: {
      transition: { duration, timing },
    },
  }) => css`background-color ${duration.medium} ${timing.ease}`};
  transition-duration: ${({ theme }) => theme.transition.duration.fast};

  &:hover {
    ${({ $loading, theme }) =>
      !$loading &&
      css`
        background-color: ${theme.hoverDefault};
      `}
    ${({ last }) =>
      last &&
      css`
        border-radius: 0px 0px 8px 8px;
      `}
  }

  @media only screen and (max-width: ${MAX_WIDTH_MEDIA_BREAKPOINT}) {
    grid-template-columns: 0.5fr 1.5fr 2fr 2fr 2fr 2fr;
  }

  @media only screen and (max-width: ${LARGE_MEDIA_BREAKPOINT}) {
    grid-template-columns: 0.5fr 1.5fr 2fr 2fr 2fr 2fr;
  }

  @media only screen and (max-width: ${MEDIUM_MEDIA_BREAKPOINT}) {
    grid-template-columns: 0.5fr 1.5fr 2fr 2fr 2fr 2fr;
  }

  @media only screen and (max-width: ${SMALL_MEDIA_BREAKPOINT}) {
    grid-template-columns: 0.5fr 1.5fr 2fr 2fr 2fr 2fr;
    min-width: unset;
    border-bottom: 0.5px solid ${({ theme }) => theme.backgroundModule};

    :last-of-type {
      border-bottom: none;
    }
  }
`

const StyledHeaderRow = styled(StyledLeaderboardRow)`
  justify-content: center;
  color: ${({ theme }) => theme.textSecondary};
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;

  &:hover {
    background-color: transparent;
  }

  @media only screen and (max-width: ${SMALL_MEDIA_BREAKPOINT}) {
    justify-content: space-between;
  }
`

const RankCell = styled(Cell)`
  justify-content: flex-start;
  align-items: flex-start;
  padding: 16px 8px 14px;
  min-width: 80px;
`
const TextLeftCell = styled(Cell)`
  padding: 16px 8px 14px;
  align-items: flex-start;
  @media only screen and (max-width: ${MEDIUM_MEDIA_BREAKPOINT}) {
    display: none;
  }
`

const TextRightCell = styled(Cell)`
  padding: 16px 8px 14px;
  align-items: flex-start;
  @media only screen and (max-width: ${MEDIUM_MEDIA_BREAKPOINT}) {
    display: none;
  }
`

/* Token Row: skeleton row component */
function LeaderboardRow({
  header,
  rank,
  trader,
  swapVolume,
  preVolume,
  totalVolume,
  Rewards,
  ...rest
}: {
  first?: boolean
  header: boolean
  $loading?: boolean
  preVolume: ReactNode
  trader: ReactNode
  swapVolume: ReactNode
  rank: ReactNode
  totalVolume: ReactNode
  Rewards: ReactNode
  last?: boolean
  style?: CSSProperties
}) {
  const rowCells = (
    <>
      <RankCell data-testid="rank-cell">{rank}</RankCell>
      <TextLeftCell data-testid="trader-cell">{trader}</TextLeftCell>
      <TextLeftCell data-testid="swap-volume-cell">{swapVolume}</TextLeftCell>
      <TextLeftCell data-testid="pre-volume-cell">{preVolume}</TextLeftCell>
      <TextLeftCell data-testid="my-volume-cell">
        {totalVolume}
      </TextLeftCell>
      <TextRightCell data-testid="my-leaderboard-cell">
        {Rewards}
      </TextRightCell>
    </>
  )
  if (header) return <StyledHeaderRow data-testid="leaderboard-header-row">{rowCells}</StyledHeaderRow>
  return <StyledLeaderboardRow {...rest}>{rowCells}</StyledLeaderboardRow>
}

/* Header Row: top header row component for table */
export function HeaderRow() {
  return (
    <LeaderboardRow
      header={true}
      rank={<Trans>{TokenSortMethod.RANK}</Trans>}
      trader={
        <Row >
          <Trans>{TokenSortMethod.TRADER}</Trans>
        </Row>
      }
      swapVolume={
        <Row >
          <Trans>{TokenSortMethod.SWAP_VOLUME}</Trans>
        </Row>
      }
      preVolume={
        <Row >
          <Trans>{TokenSortMethod.PRE_VOLUME}</Trans>
        </Row>
      }
      totalVolume={
        <Row >
          <Trans>{TokenSortMethod.TOTAL_VOLUME}</Trans>
        </Row>
      }
      Rewards={
        <Row justify="end">
          <Trans>{TokenSortMethod.REWARDS}</Trans>
        </Row>
      }
    />
  )
}
/* Loading State: row component with loading bubbles */
export function LoadingRow(props: { first?: boolean; last?: boolean }) {
  return (
    <LeaderboardRow
      header={false}
      $loading
      rank={
        <Row>
          <MediumLoadingBubble />
        </Row>
      }
      trader={
        <Row >
          <MediumLoadingBubble />
        </Row>
      }
      swapVolume={
        <Row >
          <MediumLoadingBubble />
        </Row>
      }
      preVolume={
        <Row >
          <MediumLoadingBubble />
        </Row>
      }
      totalVolume={
        <Row >
          <MediumLoadingBubble />
        </Row>
      }
      Rewards={
        <Row justify="end" >
          <MediumLoadingBubble />
        </Row>
      }
      {...props}
    />
  )
}

interface LoadedRowProps {
  leaderboardListIndex: number
  leaderboardListLength: number
  leaderboard: any
}

/* Loaded State: row component with token information */
export const LoadedRow = forwardRef((props: LoadedRowProps, ref: ForwardedRef<HTMLDivElement>) => {
  const { leaderboardListIndex, leaderboardListLength, leaderboard } = props

  return (
    <NavLink to="/LeaderboardDetail" style={{ textDecoration: 'none' }}>
      <div ref={ref} data-testid={`leaderboard-table-row-${leaderboard.token}`}>
        <LeaderboardRow
          header={false}
          rank={
            <Row>
              <ThemedText.TextSecondary fontWeight={500}>
                #1
              </ThemedText.TextSecondary>
            </Row>
          }
          trader={
            <Row >
              <ThemedText.BodySmall fontWeight={500} fontSize={16}>0x949...394F</ThemedText.BodySmall>
            </Row>
          }
          swapVolume={
            <Row >
              <ThemedText.TextPrimary fontWeight={500} fontSize={16}>$214,349,313.99</ThemedText.TextPrimary>
            </Row>
          }
          preVolume={
           <Row >
              <ThemedText.TextPrimary fontWeight={500} fontSize={16}>$214,349,313.99</ThemedText.TextPrimary>
            </Row>
          }
          totalVolume={
            <Row >
              <ThemedText.TextPrimary fontWeight={500} fontSize={16}>$214,349,313.99</ThemedText.TextPrimary>
            </Row>
          }
          Rewards={
            <Row justify='end'>
              <ThemedText.TextPrimary fontWeight={500} fontSize={16}>$214,349,313.99</ThemedText.TextPrimary>
            </Row>
          }
          first={leaderboardListIndex === 0}
          last={leaderboardListIndex === leaderboardListLength - 1}
        />
      </div>
    </NavLink>
  )
})

LoadedRow.displayName = 'LoadedRow'
