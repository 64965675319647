import { Trans } from '@lingui/macro'
import { PageWrapper } from 'components/swap/styleds'
import { TitleDesc, TitleRow } from 'nft/components/profile/list/shared'
import { ThemedText } from 'theme'

import ContentWrapper from './ContentWrapper'
import Table from './Table'

export default function Leaderboard() {
  return (
    <PageWrapper data-testid="leaderboard-page">
      <TitleRow>
        <ThemedText.LargeHeader>
          <Trans>Trade 2 Earn</Trans>
        </ThemedText.LargeHeader>
      </TitleRow>
      <TitleDesc>
        <ThemedText.HeaderDesc lineHeight="16px">
          <Trans>Ranked By Trading Volume</Trans>
        </ThemedText.HeaderDesc>
      </TitleDesc>
      <ContentWrapper />
      <Table />
    </PageWrapper>
  )
}
