import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { Dayjs } from 'dayjs'
import * as React from 'react'
import styled from 'styled-components/macro'

const MyDatePicker = styled(DatePicker)`
  &.MyDatePicker {
    .MuiInputBase-formControl {
      flex-direction: row-reverse;
    }
  }
`

export default function BasicDatePicker() {
  const [value, setValue] = React.useState<Dayjs | null>()

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <MyDatePicker
        className="MyDatePicker"
        value={value}
        disablePast
        orientation="portrait"
        onChange={(newValue: any) => {
          setValue(newValue)
        }}
      />
    </LocalizationProvider>
  )
}
