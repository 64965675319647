import { createAction } from '@reduxjs/toolkit'

export enum Field {
  INPUT = 'INPUT',
  OUTPUT = 'OUTPUT',
}

export const positionSelectCurrency = createAction<{ field: Field; currencyId: string }>('positionSwap/selectCurrency')
export const positionSwitchCurrencies = createAction<void>('positionSwap/switchCurrencies')
export const positionTypeInput = createAction<{ field: Field; typedValue: string }>('positionSwap/typeInput')
export const replacePositionState = createAction<{
  field: Field
  typedValue: string
  inputCurrencyId?: string
  outputCurrencyId?: string
  isLong: boolean
  sliederLever: number
}>('swap/replaceSwapState')
export const setLong = createAction<{ isLong: boolean }>('positionSwap/Long')

export const setSliederLever = createAction<{ sliederLever: number }>('positionSwap/sliederLever')
