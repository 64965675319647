import { AddressZero } from '@ethersproject/constants'
import { Contract } from '@ethersproject/contracts'
import { providers } from 'ethers/lib/ethers'

import { isAddress } from './addresses'

export function getContract(
  address: string,
  ABI: any,
  // provider: providers.JsonRpcProvider | providers.FallbackProvider
  provider: any
): Contract {
  if (!isAddress(address) || address === AddressZero) {
    throw Error(`Invalid 'address' parameter '${address}'.`)
  }

  return new Contract(address, ABI, provider as any)
}
// account is not optional
// function getSigner(provider: providers.JsonRpcProvider |providers.FallbackProvider , account: string): JsonRpcSigner {
//   return provider.getSigner(account).connectUnchecked()
// }
// account is optional
function getProviderOrSigner(
  provider: providers.JsonRpcProvider | providers.FallbackProvider
): providers.JsonRpcProvider | providers.FallbackProvider {
  return provider
}
