import { Trans } from '@lingui/macro'
import { ButtonConnect } from 'components/Button'
import Column from 'components/Column'
import Row, { RowBetween } from 'components/Row'
import { useState } from 'react'
import styled from 'styled-components/macro'
import { ThemedText } from 'theme'

import { ReactComponent as Roguex } from '../../assets/imgs/tokens/roguex.svg'
import LockInfo from './LockInfo'
import CreateLockModal from './LockModal/CreateLockModal'
const LockContentBox = styled.div`
  margin-top: 50px;
  .lockContentHeader {
    padding: 24px 0;
    border-bottom: 1px solid ${({ theme }) => theme.primaryBorder};
  }
  .lockInfo {
    padding: 16px 0 46px;
    border-bottom: 1px solid ${({ theme }) => theme.primaryBorder};
  }
`

export default function ContentWrapper() {
  const [showCreateLock, setShowCreateLock] = useState(false)
  const handleDismissCreate = () => {
    setShowCreateLock(false)
  }
  return (
    <LockContentBox>
      <RowBetween className="lockContentHeader">
        <Row width="auto">
          <Roguex width={72} height={72} />
          <Row ml="24px" width="auto">
            <Column>
              <ThemedText.TextPrimary fontSize={24} fontWeight={800}>
                ROX
              </ThemedText.TextPrimary>
              <ThemedText.TextSecondary fontSize={14}>
                <Trans>RougeX Token</Trans>
              </ThemedText.TextSecondary>
            </Column>
          </Row>
          <Row ml="128px" width="auto">
            <Row flexDirection="column" align="center">
              <ThemedText.TextSecondary>
                <Trans>APR</Trans>
              </ThemedText.TextSecondary>
              <ThemedText.TextPrimary fontSize={24} fontWeight={700}>
                18.99-785.1%
              </ThemedText.TextPrimary>
            </Row>
          </Row>
        </Row>
        <ButtonConnect onClick={() => setShowCreateLock(true)}>Create Lock</ButtonConnect>
      </RowBetween>
      <LockInfo />
      {showCreateLock && <CreateLockModal isOpen={showCreateLock} onDismiss={handleDismissCreate} />}
    </LockContentBox>
  )
}
