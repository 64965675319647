import { BigNumber } from '@ethersproject/bignumber'
import type { TransactionResponse } from '@ethersproject/providers'
import { Trans } from '@lingui/macro'
import { InterfacePageName } from '@uniswap/analytics-events'
import { formatPrice, NumberType } from '@uniswap/conedison/format'
import { Currency, CurrencyAmount, Fraction, Percent, Price, Token } from '@uniswap/sdk-core'
import { NonfungiblePositionManager, Pool, Position } from '@uniswap/v3-sdk'
import { Trace } from 'analytics'
import { sendEvent } from 'components/analytics'
import Badge from 'components/Badge'
import { ButtonConfirmed, ButtonGray, ButtonPrimary } from 'components/Button'
import { DarkCard, LightCard, LiquidityCard } from 'components/Card'
import Column, { AutoColumn } from 'components/Column'
import DoubleCurrencyLogo from 'components/DoubleLogo'
import CurrencyLogo from 'components/Logo/CurrencyLogo'
import Row, { RowBetween, RowFixed } from 'components/Row'
import { Dots } from 'components/swap/styleds'
import Toggle from 'components/Toggle'
import TransactionConfirmationModal, { ConfirmationModalContent } from 'components/TransactionConfirmationModal'
import { useActiveChainId } from 'connection/useActiveChainId'
import { CHAIN_IDS_TO_NAMES, ChainId, isSupportedChain } from 'constants/chains'
import { isGqlSupportedChain } from 'graphql/data/util'
import { useToken } from 'hooks/Tokens'
import { useV3NFTPositionManagerContract } from 'hooks/useContract'
import useIsTickAtLimit from 'hooks/useIsTickAtLimit'
import { PoolState, usePool } from 'hooks/usePools'
import useStablecoinPrice from 'hooks/useStablecoinPrice'
import { useV3PositionFees } from 'hooks/useV3PositionFees'
import { useV3PositionFromTokenId } from 'hooks/useV3Positions'
import { useSingleCallResult } from 'lib/hooks/multicall'
import useNativeCurrency from 'lib/hooks/useNativeCurrency'
import { useCallback, useMemo, useRef, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { Bound } from 'state/mint/v3/actions'
import { useIsTransactionPending, useTransactionAdder } from 'state/transactions/hooks'
import styled, { useTheme } from 'styled-components/macro'
import { ExternalLink, ThemedText } from 'theme'
import { currencyId } from 'utils/currencyId'
import { formatCurrencyAmount } from 'utils/formatCurrencyAmount'
import { formatTickPrice } from 'utils/formatTickPrice'
import { unwrappedToken } from 'utils/unwrappedToken'

import { getPriceOrderingFromPositionForUI } from '../../components/PositionListItem'
import { RateToggle2 } from '../../components/RateToggle'
import { SwitchLocaleLink } from '../../components/SwitchLocaleLink'
import { usePositionTokenURI } from '../../hooks/usePositionTokenURI'
import { TransactionType } from '../../state/transactions/types'
import { calculateGasMargin } from '../../utils/calculateGasMargin'
import { ExplorerDataType, getExplorerLink } from '../../utils/getExplorerLink'
import { LoadingRows } from './styleds'

const getTokenLink = (chainId: ChainId, address: string) => {
  if (isGqlSupportedChain(chainId)) {
    const chainName = CHAIN_IDS_TO_NAMES[chainId]
    return `${window.location.origin}/#/tokens/${chainName}/${address}`
  } else {
    return getExplorerLink(chainId, address, ExplorerDataType.TOKEN)
  }
}

const PositionPageButtonPrimary = styled(ButtonPrimary)`
  width: 228px;
  height: 40px;
  font-size: 16px;
  line-height: 20px;
  border-radius: 12px;
`

const PageWrapper = styled.div`
  padding: 68px 16px 16px 16px;

  min-width: 800px;
  max-width: 960px;

  @media only screen and (max-width: ${({ theme }) => `${theme.breakpoint.md}px`}) {
    min-width: 100%;
    padding: 16px;
  }

  @media only screen and (max-width: ${({ theme }) => `${theme.breakpoint.sm}px`}) {
    min-width: 100%;
    padding: 16px;
  }
`

const BadgeText = styled.div`
  font-size: 16px;
  font-weight: 700;
`

// responsive text
// disable the warning because we don't use the end prop, we just want to filter it out
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const Label = styled(({ end, ...props }) => <ThemedText.TextSecondary {...props} />)<{ end?: boolean }>`
  display: flex;
  justify-content: ${({ end }) => (end ? 'flex-end' : 'flex-start')};
  align-items: center;
`

const ExtentsText = styled.span`
  color: ${({ theme }) => theme.textSecondary};
  font-size: 14px;
  text-align: center;
  margin-right: 4px;
  font-weight: 500;
`

const HoverText = styled(ThemedText.DeprecatedMain)`
  text-decoration: none;
  color: ${({ theme }) => theme.textTertiary};
  :hover {
    color: ${({ theme }) => theme.textPrimary};
    text-decoration: none;
  }
`

const ResponsiveRow = styled(RowBetween)`
  @media only screen and (max-width: ${({ theme }) => `${theme.breakpoint.sm}px`}) {
    flex-direction: column;
    align-items: flex-start;
    row-gap: 16px;
    width: 100%;
  }
`

const ActionButtonResponsiveRow = styled(ResponsiveRow)`
  width: 100%;
  justify-content: space-between;

  @media only screen and (max-width: ${({ theme }) => `${theme.breakpoint.sm}px`}) {
    width: 100%;
    flex-direction: row;
    * {
      width: 100%;
    }
  }
`

const IncreaseButton = styled(ButtonGray)`
  flex: 1;
  color: ${({ theme }) => theme.textPrimary};
  font-size: 14px;
  font-weight: 700;
  height: 36px;
  border-radius: 16px;
  background-color: transparent;
  border: 1px solid ${({ theme }) => theme.connectBtnBackground};
  &:hover {
    color: ${({ theme }) => theme.white};
    background-color: ${({ theme }) => theme.connectBtnBackground};
  }
`

const CollectButton = styled(ButtonConfirmed)`
  margin-top: 6px;
  border-radius: 8px;
  padding: 4px 16px;
  width: fit-content;
  font-size: 14px;
  font-weight: 700;
  color: ${({ theme }) => theme.textSecondary};
`

export const PriceCard = styled(Row)`
  padding: 6px 10px;
  gap: 5px;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  background-color: ${({ theme }) => theme.backgroundInteractive};
`

export const DoubleArrow = styled.span`
  color: ${({ theme }) => theme.textTertiary};
  margin: 0 1rem;
`

const ResponsiveButtonConfirmed = styled(ButtonConfirmed)`
  border-radius: 12px;
  padding: 6px 8px;
  width: fit-content;
  font-size: 16px;

  @media only screen and (max-width: ${({ theme }) => `${theme.breakpoint.md}px`}) {
    width: fit-content;
  }

  @media only screen and (max-width: ${({ theme }) => `${theme.breakpoint.sm}px`}) {
    width: fit-content;
  }
`

const NFTGrid = styled.div`
  display: grid;
  grid-template: 'overlap';
  min-height: 400px;
`

const NFTCanvas = styled.canvas`
  grid-area: overlap;
`

const NFTImage = styled.img`
  grid-area: overlap;
  height: 400px;
  /* Ensures SVG appears on top of canvas. */
  z-index: 1;
`

function CurrentPriceCard({
  inverted,
  pool,
  currencyQuote,
  currencyBase,
  manuallyInverted,
  setManuallyInverted,
}: {
  inverted?: boolean
  pool?: Pool | null
  currencyQuote?: Currency
  currencyBase?: Currency
  manuallyInverted?: boolean | null
  setManuallyInverted?: any
}) {
  if (!pool || !currencyQuote || !currencyBase) {
    return null
  }

  const tokenA = currencyBase?.wrapped
  const tokenB = currencyQuote?.wrapped
  const isSorted = tokenA && tokenB && tokenA.sortsBefore(tokenB)

  return (
    // <LightCard padding="12px">
    //   <AutoColumn gap="sm" justify="center">
    //     <ExtentsText>
    //       <Trans>Current price</Trans>
    //     </ExtentsText>
    //     <ThemedText.DeprecatedMediumHeader textAlign="center">
    //       {formatPrice(inverted ? pool.token1Price : pool.token0Price, NumberType.TokenTx)}
    //     </ThemedText.DeprecatedMediumHeader>
    //     <ExtentsText>
    //       <Trans>
    //         {currencyQuote?.symbol} per {currencyBase?.symbol}
    //       </Trans>
    //     </ExtentsText>
    //   </AutoColumn>
    // </LightCard>

    <Column>
      <Row gap="sm" justify="flex-end">
        <ThemedText.TextSecondary fontSize={14}>
          <Trans>Price</Trans>
        </ThemedText.TextSecondary>
        <RateToggle2
          currencyA={currencyBase}
          currencyB={currencyQuote}
          handleRateToggle={() => setManuallyInverted(!manuallyInverted)}
        />
      </Row>
      <ThemedText.TextPrimary fontSize={16} fontWeight={700}>
        {formatPrice(inverted ? pool.token1Price : pool.token0Price, NumberType.TokenTx)}{' '}
        {isSorted ? currencyBase?.symbol : currencyQuote?.symbol}
      </ThemedText.TextPrimary>
    </Column>
  )
}

function LinkedCurrency({ chainId, currency }: { chainId?: number; currency?: Currency }) {
  const address = (currency as Token)?.address

  if (typeof chainId === 'number' && address) {
    return (
      <ExternalLink href={getTokenLink(chainId, address)}>
        <RowFixed>
          <CurrencyLogo currency={currency} size="30px" style={{ marginRight: '0.5rem' }} />
          <ThemedText.TextPrimary fontSize={16} fontWeight={700}>
            {currency?.symbol} ↗
          </ThemedText.TextPrimary>
        </RowFixed>
      </ExternalLink>
    )
  }

  return (
    <RowFixed>
      <CurrencyLogo currency={currency} size="30px" style={{ marginRight: '0.5rem' }} />
      <ThemedText.TextPrimary fontSize={16} fontWeight={700}>
        {currency?.symbol}
      </ThemedText.TextPrimary>
    </RowFixed>
  )
}

function getRatio(
  lower: Price<Currency, Currency>,
  current: Price<Currency, Currency>,
  upper: Price<Currency, Currency>
) {
  try {
    if (!current.greaterThan(lower)) {
      return 100
    } else if (!current.lessThan(upper)) {
      return 0
    }

    const a = Number.parseFloat(lower.toSignificant(15))
    const b = Number.parseFloat(upper.toSignificant(15))
    const c = Number.parseFloat(current.toSignificant(15))

    const ratio = Math.floor((1 / ((Math.sqrt(a * b) - Math.sqrt(b * c)) / (c - Math.sqrt(b * c)) + 1)) * 100)

    if (ratio < 0 || ratio > 100) {
      throw Error('Out of range')
    }

    return ratio
  } catch {
    return undefined
  }
}

// snapshots a src img into a canvas
function getSnapshot(src: HTMLImageElement, canvas: HTMLCanvasElement, targetHeight: number) {
  const context = canvas.getContext('2d')

  if (context) {
    let { width, height } = src

    // src may be hidden and not have the target dimensions
    const ratio = width / height
    height = targetHeight
    width = Math.round(ratio * targetHeight)

    // Ensure crispness at high DPIs
    canvas.width = width * devicePixelRatio
    canvas.height = height * devicePixelRatio
    canvas.style.width = width + 'px'
    canvas.style.height = height + 'px'
    context.scale(devicePixelRatio, devicePixelRatio)

    context.clearRect(0, 0, width, height)
    context.drawImage(src, 0, 0, width, height)
  }
}

function NFT({ image, height: targetHeight }: { image: string; height: number }) {
  const [animate, setAnimate] = useState(false)

  const canvasRef = useRef<HTMLCanvasElement>(null)
  const imageRef = useRef<HTMLImageElement>(null)

  return (
    <NFTGrid
      onMouseEnter={() => {
        setAnimate(true)
      }}
      onMouseLeave={() => {
        // snapshot the current frame so the transition to the canvas is smooth
        if (imageRef.current && canvasRef.current) {
          getSnapshot(imageRef.current, canvasRef.current, targetHeight)
        }
        setAnimate(false)
      }}
    >
      <NFTCanvas ref={canvasRef} />
      <NFTImage
        ref={imageRef}
        src={image}
        hidden={!animate}
        onLoad={() => {
          // snapshot for the canvas
          if (imageRef.current && canvasRef.current) {
            getSnapshot(imageRef.current, canvasRef.current, targetHeight)
          }
        }}
      />
    </NFTGrid>
  )
}

const useInverter = ({
  priceLower,
  priceUpper,
  quote,
  base,
  invert,
}: {
  priceLower?: Price<Token, Token>
  priceUpper?: Price<Token, Token>
  quote?: Token
  base?: Token
  invert?: boolean
}): {
  priceLower?: Price<Token, Token>
  priceUpper?: Price<Token, Token>
  quote?: Token
  base?: Token
} => {
  return {
    priceUpper: invert ? priceLower?.invert() : priceUpper,
    priceLower: invert ? priceUpper?.invert() : priceLower,
    quote: invert ? base : quote,
    base: invert ? quote : base,
  }
}

export function PositionPageUnsupportedContent() {
  return (
    <PageWrapper>
      <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
        <ThemedText.HeadlineLarge style={{ marginBottom: '8px' }}>
          <Trans>Position unavailable</Trans>
        </ThemedText.HeadlineLarge>
        <ThemedText.BodyPrimary style={{ marginBottom: '32px' }}>
          <Trans>To view a position, you must be connected to the network it belongs to.</Trans>
        </ThemedText.BodyPrimary>
        <PositionPageButtonPrimary as={Link} to="/pools" width="fit-content">
          <Trans>Back to Pools</Trans>
        </PositionPageButtonPrimary>
      </div>
    </PageWrapper>
  )
}

export default function PositionPage() {
  const { chainId } = useActiveChainId()
  if (isSupportedChain(chainId)) {
    return <PositionPageContent />
  } else {
    return <PositionPageUnsupportedContent />
  }
}

function PositionPageContent() {
  const { tokenId: tokenIdFromUrl } = useParams<{ tokenId?: string }>()
  const { chainId, account, provider } = useActiveChainId()
  const theme = useTheme()

  const parsedTokenId = tokenIdFromUrl ? BigNumber.from(tokenIdFromUrl) : undefined
  const { loading, position: positionDetails } = useV3PositionFromTokenId(parsedTokenId)

  const {
    token0: token0Address,
    token1: token1Address,
    fee: feeAmount,
    liquidity,
    tickLower,
    tickUpper,
    tokenId,
  } = positionDetails || {}

  const removed = liquidity?.eq(0)

  const metadata = usePositionTokenURI(parsedTokenId)

  const token0 = useToken(token0Address)
  const token1 = useToken(token1Address)

  const currency0 = token0 ? unwrappedToken(token0) : undefined
  const currency1 = token1 ? unwrappedToken(token1) : undefined

  // flag for receiving WETH
  const [receiveWETH, setReceiveWETH] = useState(false)
  const nativeCurrency = useNativeCurrency(chainId)
  const nativeWrappedSymbol = nativeCurrency.wrapped.symbol

  // construct Position from details returned
  const [poolState, pool] = usePool(token0 ?? undefined, token1 ?? undefined, feeAmount)
  const position = useMemo(() => {
    if (pool && liquidity && typeof tickLower === 'number' && typeof tickUpper === 'number') {
      return new Position({ pool, liquidity: liquidity.toString(), tickLower, tickUpper })
    }
    return undefined
  }, [liquidity, pool, tickLower, tickUpper])

  const tickAtLimit = useIsTickAtLimit(feeAmount, tickLower, tickUpper)

  const pricesFromPosition = getPriceOrderingFromPositionForUI(position)
  const [manuallyInverted, setManuallyInverted] = useState(false)

  // handle manual inversion
  const { priceLower, priceUpper, base } = useInverter({
    priceLower: pricesFromPosition.priceLower,
    priceUpper: pricesFromPosition.priceUpper,
    quote: pricesFromPosition.quote,
    base: pricesFromPosition.base,
    invert: manuallyInverted,
  })

  const inverted = token1 ? base?.equals(token1) : undefined
  const currencyQuote = inverted ? currency0 : currency1
  const currencyBase = inverted ? currency1 : currency0

  const ratio = useMemo(() => {
    return priceLower && pool && priceUpper
      ? getRatio(
          inverted ? priceUpper.invert() : priceLower,
          pool.token0Price,
          inverted ? priceLower.invert() : priceUpper
        )
      : undefined
  }, [inverted, pool, priceLower, priceUpper])

  // fees
  const [feeValue0, feeValue1] = useV3PositionFees(pool ?? undefined, positionDetails?.tokenId, receiveWETH)

  // these currencies will match the feeValue{0,1} currencies for the purposes of fee collection
  const currency0ForFeeCollectionPurposes = pool ? (receiveWETH ? pool.token0 : unwrappedToken(pool.token0)) : undefined
  const currency1ForFeeCollectionPurposes = pool ? (receiveWETH ? pool.token1 : unwrappedToken(pool.token1)) : undefined

  const [collecting, setCollecting] = useState<boolean>(false)
  const [collectMigrationHash, setCollectMigrationHash] = useState<string | null>(null)
  const isCollectPending = useIsTransactionPending(collectMigrationHash ?? undefined)
  const [showConfirm, setShowConfirm] = useState(false)

  // usdc prices always in terms of tokens
  const price0 = useStablecoinPrice(token0 ?? undefined)
  const price1 = useStablecoinPrice(token1 ?? undefined)

  const fiatValueOfFees: CurrencyAmount<Currency> | null = useMemo(() => {
    if (!price0 || !price1 || !feeValue0 || !feeValue1) return null

    // we wrap because it doesn't matter, the quote returns a USDC amount
    const feeValue0Wrapped = feeValue0?.wrapped
    const feeValue1Wrapped = feeValue1?.wrapped

    if (!feeValue0Wrapped || !feeValue1Wrapped) return null

    const amount0 = price0.quote(feeValue0Wrapped)
    const amount1 = price1.quote(feeValue1Wrapped)
    return amount0.add(amount1)
  }, [price0, price1, feeValue0, feeValue1])

  const fiatValueOfLiquidity: CurrencyAmount<Token> | null = useMemo(() => {
    if (!price0 || !price1 || !position) return null
    const amount0 = price0.quote(position.amount0)
    const amount1 = price1.quote(position.amount1)
    return amount0.add(amount1)
  }, [price0, price1, position])

  const addTransaction = useTransactionAdder()
  const positionManager = useV3NFTPositionManagerContract()
  const collect = useCallback(() => {
    if (
      !currency0ForFeeCollectionPurposes ||
      !currency1ForFeeCollectionPurposes ||
      !chainId ||
      !positionManager ||
      !account ||
      !tokenId ||
      !provider
    )
      return

    setCollecting(true)

    // we fall back to expecting 0 fees in case the fetch fails, which is safe in the
    // vast majority of cases
    const { calldata, value } = NonfungiblePositionManager.collectCallParameters({
      tokenId: tokenId.toString(),
      expectedCurrencyOwed0: feeValue0 ?? CurrencyAmount.fromRawAmount(currency0ForFeeCollectionPurposes, 0),
      expectedCurrencyOwed1: feeValue1 ?? CurrencyAmount.fromRawAmount(currency1ForFeeCollectionPurposes, 0),
      recipient: account,
    })

    const txn = {
      to: positionManager.address,
      data: calldata,
      value,
    }

    provider
      .getSigner()
      .estimateGas(txn)
      .then((estimate) => {
        const newTxn = {
          ...txn,
          gasLimit: calculateGasMargin(estimate),
        }

        return provider
          .getSigner()
          .sendTransaction(newTxn)
          .then((response: TransactionResponse) => {
            setCollectMigrationHash(response.hash)
            setCollecting(false)

            sendEvent({
              category: 'Liquidity',
              action: 'CollectV3',
              label: [currency0ForFeeCollectionPurposes.symbol, currency1ForFeeCollectionPurposes.symbol].join('/'),
            })

            addTransaction(response, {
              type: TransactionType.COLLECT_FEES,
              currencyId0: currencyId(currency0ForFeeCollectionPurposes),
              currencyId1: currencyId(currency1ForFeeCollectionPurposes),
              expectedCurrencyOwed0:
                feeValue0?.quotient.toString() ??
                CurrencyAmount.fromRawAmount(currency0ForFeeCollectionPurposes, 0).toExact(),
              expectedCurrencyOwed1:
                feeValue1?.quotient.toString() ??
                CurrencyAmount.fromRawAmount(currency1ForFeeCollectionPurposes, 0).toExact(),
            })
          })
      })
      .catch((error) => {
        setCollecting(false)
        console.error(error)
      })
  }, [
    chainId,
    feeValue0,
    feeValue1,
    currency0ForFeeCollectionPurposes,
    currency1ForFeeCollectionPurposes,
    positionManager,
    account,
    tokenId,
    addTransaction,
    provider,
  ])

  const owner = useSingleCallResult(tokenId ? positionManager : null, 'ownerOf', [tokenId]).result?.[0]
  const ownsNFT = owner === account || positionDetails?.operator === account

  const feeValueUpper = inverted ? feeValue0 : feeValue1
  const feeValueLower = inverted ? feeValue1 : feeValue0

  // check if price is within range
  const below = pool && typeof tickLower === 'number' ? pool.tickCurrent < tickLower : undefined
  const above = pool && typeof tickUpper === 'number' ? pool.tickCurrent >= tickUpper : undefined
  const inRange: boolean = typeof below === 'boolean' && typeof above === 'boolean' ? !below && !above : false

  function modalHeader() {
    return (
      <AutoColumn gap="md" style={{ marginTop: '20px' }}>
        <LightCard padding="12px 16px">
          <AutoColumn gap="md">
            <RowBetween>
              <RowFixed>
                <CurrencyLogo currency={feeValueUpper?.currency} size="30px" style={{ marginRight: '0.5rem' }} />
                <ThemedText.TextPrimary fontSize={16} fontWeight={700}>
                  {feeValueUpper ? formatCurrencyAmount(feeValueUpper, 4) : '-'}
                </ThemedText.TextPrimary>
              </RowFixed>
              <ThemedText.TextSecondary fontWeight={700}>{feeValueUpper?.currency?.symbol}</ThemedText.TextSecondary>
            </RowBetween>
            <RowBetween>
              <RowFixed>
                <CurrencyLogo currency={feeValueLower?.currency} size="30px" style={{ marginRight: '0.5rem' }} />
                <ThemedText.TextPrimary fontSize={16} fontWeight={700}>
                  {feeValueLower ? formatCurrencyAmount(feeValueLower, 4) : '-'}
                </ThemedText.TextPrimary>
              </RowFixed>
              <ThemedText.TextSecondary fontWeight={700}>{feeValueLower?.currency?.symbol}</ThemedText.TextSecondary>
            </RowBetween>
          </AutoColumn>
        </LightCard>
        <ThemedText.DeprecatedItalic>
          <Trans>Collecting fees will withdraw currently available fees for you.</Trans>
        </ThemedText.DeprecatedItalic>
        <ButtonPrimary data-testid="modal-collect-fees-button" onClick={collect}>
          <Trans>Collect</Trans>
        </ButtonPrimary>
      </AutoColumn>
    )
  }

  const showCollectAsWeth = Boolean(
    ownsNFT &&
      (feeValue0?.greaterThan(0) || feeValue1?.greaterThan(0)) &&
      currency0 &&
      currency1 &&
      (currency0.isNative || currency1.isNative) &&
      !collectMigrationHash
  )

  if (!positionDetails && !loading) {
    return <PositionPageUnsupportedContent />
  }

  return loading || poolState === PoolState.LOADING || !feeAmount ? (
    <LoadingRows>
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
    </LoadingRows>
  ) : (
    <Trace page={InterfacePageName.POOL_PAGE} shouldLogImpression>
      <>
        <PageWrapper>
          <TransactionConfirmationModal
            isOpen={showConfirm}
            onDismiss={() => setShowConfirm(false)}
            attemptingTxn={collecting}
            hash={collectMigrationHash ?? ''}
            reviewContent={() => (
              <ConfirmationModalContent
                title={<Trans>Claim fees</Trans>}
                onDismiss={() => setShowConfirm(false)}
                topContent={modalHeader}
              />
            )}
            pendingText={<Trans>Collecting fees</Trans>}
          />
          <AutoColumn gap="md">
            <AutoColumn gap="sm">
              <Link
                data-cy="visit-pool"
                style={{ textDecoration: 'none', width: 'fit-content', marginBottom: '0.5rem' }}
                to="/pools"
              >
                <HoverText>
                  <Trans>← Back to Pools</Trans>
                </HoverText>
              </Link>
              <ResponsiveRow>
                <RowFixed>
                  <DoubleCurrencyLogo currency0={currencyBase} currency1={currencyQuote} size={30} margin={true} />
                  <ThemedText.TextPrimary fontSize="36px" fontWeight={800} mr="12px">
                    &nbsp;{currencyQuote?.symbol}&nbsp;/&nbsp;{currencyBase?.symbol}
                  </ThemedText.TextPrimary>
                  <Badge>
                    <BadgeText className="active-gradient-text">
                      <Trans>{new Percent(feeAmount, 1_000_000).toSignificant()}%</Trans>
                    </BadgeText>
                  </Badge>
                </RowFixed>
                <CurrentPriceCard
                  inverted={inverted}
                  pool={pool}
                  currencyQuote={currencyQuote}
                  currencyBase={currencyBase}
                  manuallyInverted={manuallyInverted}
                  setManuallyInverted={setManuallyInverted}
                />
              </ResponsiveRow>
            </AutoColumn>
            <PriceCard>
              <ThemedText.TextSecondary fontSize={14}>
                <Trans>Min price</Trans>
              </ThemedText.TextSecondary>
              <ThemedText.TextSecondary fontWeight={700}>
                {formatTickPrice({
                  price: priceLower,
                  atLimit: tickAtLimit,
                  direction: Bound.LOWER,
                  numberType: NumberType.TokenTx,
                })}
              </ThemedText.TextSecondary>
              <DoubleArrow>————</DoubleArrow>
              <ThemedText.TextSecondary fontSize={14}>
                <Trans>Max price</Trans>
              </ThemedText.TextSecondary>
              <ThemedText.TextSecondary fontWeight={700}>
                {formatTickPrice({
                  price: priceUpper,
                  atLimit: tickAtLimit,
                  direction: Bound.UPPER,
                  numberType: NumberType.TokenTx,
                })}
              </ThemedText.TextSecondary>
            </PriceCard>
            <ResponsiveRow align="flex-start">
              <RowBetween gap="xmd" align="stretch" style={{ width: '100%', height: '100%' }}>
                <DarkCard>
                  <AutoColumn gap="xmd" style={{ width: '100%' }}>
                    <RowBetween
                      gap="md"
                      padding="0 0 16px 0"
                      style={{ borderBottom: '1px dashed rgba(0, 0, 0, 0.05)' }}
                    >
                      <Label>
                        <Trans>Liquidity</Trans>
                      </Label>
                      {fiatValueOfLiquidity?.greaterThan(new Fraction(1, 100)) ? (
                        <ThemedText.TextPrimary fontSize={24} fontWeight={700}>
                          <Trans>${fiatValueOfLiquidity.toFixed(2, { groupSeparator: ',' })}</Trans>
                        </ThemedText.TextPrimary>
                      ) : (
                        <ThemedText.TextPrimary fontSize={24} fontWeight={700} color={theme.textPrimary}>
                          <Trans>$-</Trans>
                        </ThemedText.TextPrimary>
                      )}
                    </RowBetween>
                    <LiquidityCard>
                      <AutoColumn gap="xmd">
                        <RowBetween>
                          <LinkedCurrency chainId={chainId} currency={currencyQuote} />
                          <RowFixed>
                            <ThemedText.DeprecatedMain>
                              {inverted ? position?.amount0.toSignificant(4) : position?.amount1.toSignificant(4)}
                            </ThemedText.DeprecatedMain>
                            {typeof ratio === 'number' && !removed ? (
                              <Badge style={{ marginLeft: '10px' }}>
                                <BadgeText className="active-gradient-text">
                                  {inverted ? ratio : 100 - ratio}%
                                </BadgeText>
                              </Badge>
                            ) : null}
                          </RowFixed>
                        </RowBetween>
                        <RowBetween>
                          <LinkedCurrency chainId={chainId} currency={currencyBase} />
                          <RowFixed>
                            <ThemedText.DeprecatedMain>
                              {inverted ? position?.amount1.toSignificant(4) : position?.amount0.toSignificant(4)}
                            </ThemedText.DeprecatedMain>
                            {typeof ratio === 'number' && !removed ? (
                              <Badge style={{ marginLeft: '10px' }}>
                                <BadgeText className="active-gradient-text">
                                  {inverted ? 100 - ratio : ratio}%
                                </BadgeText>
                              </Badge>
                            ) : null}
                          </RowFixed>
                        </RowBetween>
                      </AutoColumn>
                    </LiquidityCard>
                    {ownsNFT && (
                      <ActionButtonResponsiveRow>
                        {currency0 && currency1 && feeAmount && tokenId ? (
                          <IncreaseButton
                            as={Link}
                            to={`/increase/${currencyId(currency0)}/${currencyId(currency1)}/${feeAmount}/${tokenId}`}
                            padding="6px 8px"
                            width="fit-content"
                            $borderRadius="12px"
                            style={{ marginRight: '8px' }}
                          >
                            <Trans>+ Increase</Trans>
                          </IncreaseButton>
                        ) : null}
                        {tokenId && !removed ? (
                          <IncreaseButton
                            as={Link}
                            to={`/remove/${tokenId}`}
                            padding="6px 8px"
                            width="fit-content"
                            $borderRadius="12px"
                          >
                            <Trans>- Remove</Trans>
                          </IncreaseButton>
                        ) : null}
                      </ActionButtonResponsiveRow>
                    )}
                  </AutoColumn>
                </DarkCard>
                <DarkCard>
                  <AutoColumn gap="xmd" style={{ width: '100%' }}>
                    <RowBetween
                      gap="md"
                      padding="0 0 16px 0"
                      style={{ borderBottom: '1px dashed rgba(0, 0, 0, 0.05)' }}
                    >
                      <Label>
                        <Trans>Unclaimed fees</Trans>
                      </Label>
                      {fiatValueOfFees?.greaterThan(new Fraction(1, 100)) ? (
                        <ThemedText.TextPrimary fontSize={24} fontWeight={700}>
                          <Trans>
                            <div className="active-gradient-text">
                              ${fiatValueOfFees.toFixed(2, { groupSeparator: ',' })}
                            </div>
                          </Trans>
                        </ThemedText.TextPrimary>
                      ) : (
                        <ThemedText.TextPrimary fontSize={24} fontWeight={700}>
                          <Trans>
                            <div className="active-gradient-text">$-</div>
                          </Trans>
                        </ThemedText.TextPrimary>
                      )}
                    </RowBetween>
                    {ownsNFT && (feeValue0?.greaterThan(0) || feeValue1?.greaterThan(0) || !!collectMigrationHash) ? (
                      <ResponsiveButtonConfirmed
                        data-testid="collect-fees-button"
                        disabled={collecting || !!collectMigrationHash}
                        confirmed={!!collectMigrationHash && !isCollectPending}
                        width="fit-content"
                        style={{ borderRadius: '12px' }}
                        padding="4px 8px"
                        onClick={() => setShowConfirm(true)}
                      >
                        {!!collectMigrationHash && !isCollectPending ? (
                          <ThemedText.DeprecatedMain color={theme.textPrimary}>
                            <Trans> Collected</Trans>
                          </ThemedText.DeprecatedMain>
                        ) : isCollectPending || collecting ? (
                          <ThemedText.DeprecatedMain color={theme.textPrimary}>
                            <Dots>
                              <Trans>Collecting</Trans>
                            </Dots>
                          </ThemedText.DeprecatedMain>
                        ) : (
                          <>
                            <ThemedText.DeprecatedMain color={theme.white}>
                              <Trans>Collect fees</Trans>
                            </ThemedText.DeprecatedMain>
                          </>
                        )}
                      </ResponsiveButtonConfirmed>
                    ) : null}
                    <LiquidityCard>
                      <AutoColumn gap="xmd">
                        <RowBetween>
                          <RowFixed>
                            <CurrencyLogo
                              currency={feeValueUpper?.currency}
                              size="30px"
                              style={{ marginRight: '0.5rem' }}
                            />
                            <ThemedText.TextPrimary fontSize={16} fontWeight={700}>
                              {feeValueUpper?.currency?.symbol}
                            </ThemedText.TextPrimary>
                          </RowFixed>
                          <RowFixed>
                            <ThemedText.TextSecondary fontWeight={700}>
                              {feeValueUpper ? formatCurrencyAmount(feeValueUpper, 4) : '-'}
                            </ThemedText.TextSecondary>
                          </RowFixed>
                        </RowBetween>
                        <RowBetween>
                          <RowFixed>
                            <CurrencyLogo
                              currency={feeValueLower?.currency}
                              size="30px"
                              style={{ marginRight: '0.5rem' }}
                            />
                            <ThemedText.TextPrimary fontSize={16} fontWeight={700}>
                              {feeValueLower?.currency?.symbol}
                            </ThemedText.TextPrimary>
                          </RowFixed>
                          <RowFixed>
                            <ThemedText.TextSecondary fontWeight={700}>
                              {feeValueLower ? formatCurrencyAmount(feeValueLower, 4) : '-'}
                            </ThemedText.TextSecondary>
                          </RowFixed>
                        </RowBetween>
                      </AutoColumn>
                    </LiquidityCard>
                    {showCollectAsWeth && (
                      <AutoColumn gap="md">
                        <RowBetween>
                          <Row gap="sm">
                            <ThemedText.DeprecatedMain>
                              <Trans>Collect as {nativeWrappedSymbol}</Trans>
                            </ThemedText.DeprecatedMain>
                            <Toggle
                              id="receive-as-weth"
                              isActive={receiveWETH}
                              toggle={() => setReceiveWETH((receiveWETH) => !receiveWETH)}
                            />
                          </Row>
                          <CollectButton>Collect fees</CollectButton>
                        </RowBetween>
                      </AutoColumn>
                    )}
                  </AutoColumn>
                </DarkCard>
              </RowBetween>
            </ResponsiveRow>
          </AutoColumn>
        </PageWrapper>
        <SwitchLocaleLink />
      </>
    </Trace>
  )
}
