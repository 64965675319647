import { Contract } from '@ethersproject/contracts'
import { ERC20 } from '@opensea/seaport-js/lib/typechain/ERC20'
import QuoterV2Json from '@uniswap/swap-router-contracts/artifacts/contracts/lens/QuoterV2.sol/QuoterV2.json'
import TickLensJson from '@uniswap/v3-periphery/artifacts/contracts/lens/TickLens.sol/TickLens.json'
import UniswapInterfaceMulticallJson from '@uniswap/v3-periphery/artifacts/contracts/lens/UniswapInterfaceMulticall.sol/UniswapInterfaceMulticall.json'
import NonfungiblePositionManagerJson from '@uniswap/v3-periphery/artifacts/contracts/NonfungiblePositionManager.sol/NonfungiblePositionManager.json'
import ARGENT_WALLET_DETECTOR_ABI from 'abis/argent-wallet-detector.json'
import EIP_2612 from 'abis/eip_2612.json'
import ENS_PUBLIC_RESOLVER_ABI from 'abis/ens-public-resolver.json'
import ENS_ABI from 'abis/ens-registrar.json'
import ERC20_ABI from 'abis/erc20.json'
import ERC20_BYTES32_ABI from 'abis/erc20_bytes32.json'
import ERC721_ABI from 'abis/erc721.json'
import ERC1155_ABI from 'abis/erc1155.json'
import LockABI from 'abis/lock.json'
import MasterchefV3ABI from 'abis/MasterchefV3.json'
import QUOTERABI from 'abis/quoter.json'
import RoguFactoryABI from 'abis/RoguFactory.json'
import swapMinningABI from 'abis/swapMinning.json'
import TradePoolABI from 'abis/tradePool.json'
import TradeRouterABI from 'abis/TradeRouter.json'
import {
  ArgentWalletDetector,
  EnsPublicResolver,
  EnsRegistrar,
  Erc20,
  Erc721,
  Erc1155,
  Lock,
  MasterchefV3,
  RoguFactory,
  TradePool,
  TradeRouter,
  Voter,
  Weth,
} from 'abis/types'
import voterABI from 'abis/voter.json'
import votingRewardABI from 'abis/votingReward.json'
import WETH_ABI from 'abis/weth.json'
import { useActiveChainId, useEthersSigner } from 'connection/useActiveChainId'
import {
  ARGENT_WALLET_DETECTOR_ADDRESS,
  ENS_REGISTRAR_ADDRESSES,
  LOCK_ADDRESSES,
  MasterchefV3_ADDRESSES,
  MULTICALL_ADDRESSES,
  NONFUNGIBLE_POSITION_MANAGER_ADDRESSES,
  QUOTER_ADDRESSES,
  ROUGEX_ADDRESSES,
  TICK_LENS_ADDRESSES,
  TRADE_ROUTER_ADDRESSES,
  V3_CORE_FACTORY_ADDRESSES,
  VOTER_ADDRESSES,
} from 'constants/addresses'
import { WRAPPED_NATIVE_CURRENCY } from 'constants/tokens'
import { useMemo } from 'react'
import { NonfungiblePositionManager, Quoter, TickLens, UniswapInterfaceMulticall } from 'types/v3'
import { getContract } from 'utils'

const { abi: QuoterV2ABI } = QuoterV2Json
const { abi: TickLensABI } = TickLensJson
const { abi: MulticallABI } = UniswapInterfaceMulticallJson
const { abi: NFTPositionManagerABI } = NonfungiblePositionManagerJson

// returns null on errors
export function useContract<T extends Contract = Contract>(
  addressOrAddressMap: string | { [chainId: number]: string } | undefined,
  ABI: any,
  withSignerIfPossible = true
): T | null {
  const { chainId } = useActiveChainId()

  const provider = useEthersSigner()

  return useMemo(() => {
    if (!addressOrAddressMap || !ABI || !provider || !chainId) return null
    let address: string | undefined
    if (typeof addressOrAddressMap === 'string') address = addressOrAddressMap
    else address = addressOrAddressMap[chainId]
    if (!address) return null
    try {
      return getContract(address, ABI, provider)
    } catch (error) {
      console.error('Failed to get contract', error)
      return null
    }
  }, [addressOrAddressMap, ABI, provider, chainId]) as T
}

export function useTokenContract(tokenAddress?: string, withSignerIfPossible?: boolean) {
  return useContract<Erc20>(tokenAddress, ERC20_ABI, withSignerIfPossible)
}

export function useWETHContract(withSignerIfPossible?: boolean) {
  const { chainId } = useActiveChainId()
  return useContract<Weth>(
    chainId ? WRAPPED_NATIVE_CURRENCY[chainId]?.address : undefined,
    WETH_ABI,
    withSignerIfPossible
  )
}

export function useERC721Contract(nftAddress?: string) {
  return useContract<Erc721>(nftAddress, ERC721_ABI, false)
}

export function useERC1155Contract(nftAddress?: string) {
  return useContract<Erc1155>(nftAddress, ERC1155_ABI, false)
}

export function useArgentWalletDetectorContract() {
  return useContract<ArgentWalletDetector>(ARGENT_WALLET_DETECTOR_ADDRESS, ARGENT_WALLET_DETECTOR_ABI, false)
}

export function useENSRegistrarContract(withSignerIfPossible?: boolean) {
  return useContract<EnsRegistrar>(ENS_REGISTRAR_ADDRESSES, ENS_ABI, withSignerIfPossible)
}

export function useENSResolverContract(address: string | undefined, withSignerIfPossible?: boolean) {
  return useContract<EnsPublicResolver>(address, ENS_PUBLIC_RESOLVER_ABI, withSignerIfPossible)
}

export function useBytes32TokenContract(tokenAddress?: string, withSignerIfPossible?: boolean): Contract | null {
  return useContract(tokenAddress, ERC20_BYTES32_ABI, withSignerIfPossible)
}

export function useEIP2612Contract(tokenAddress?: string): Contract | null {
  return useContract(tokenAddress, EIP_2612, false)
}

export function useInterfaceMulticall() {
  return useContract<UniswapInterfaceMulticall>(MULTICALL_ADDRESSES, MulticallABI, false) as UniswapInterfaceMulticall
}

export function useV3NFTPositionManagerContract(withSignerIfPossible?: boolean): NonfungiblePositionManager | null {
  return useContract<NonfungiblePositionManager>(
    NONFUNGIBLE_POSITION_MANAGER_ADDRESSES,
    NFTPositionManagerABI,
    withSignerIfPossible
  )
}

export function useV3Factory() {
  return useContract<RoguFactory>(V3_CORE_FACTORY_ADDRESSES, RoguFactoryABI)
}

export function useQuoter() {
  return useContract<Quoter>(QUOTER_ADDRESSES, QUOTERABI.abi)
}

export function useTickLens(): TickLens | null {
  const { chainId } = useActiveChainId()
  const address = chainId ? TICK_LENS_ADDRESSES[chainId] : undefined
  return useContract(address, TickLensABI) as TickLens | null
}

export function useLockContract() {
  return useContract<Lock>(LOCK_ADDRESSES, LockABI.abi)
}

export function useTradePool(pool?: string) {
  return useContract<TradePool>(pool, TradePoolABI.abi)
}

export function useTradeRouter() {
  return useContract<TradeRouter>(TRADE_ROUTER_ADDRESSES, TradeRouterABI)
}
export function useMasterChefV3() {
  return useContract<MasterchefV3>(MasterchefV3_ADDRESSES, MasterchefV3ABI.abi)
}

export function useVoter() {
  return useContract<Voter>(VOTER_ADDRESSES, voterABI.abi)
}

export function useSwapMinning(address?: string) {
  return useContract(address, swapMinningABI.abi)
}

export function useVotingReward(address?: string) {
  return useContract(address, votingRewardABI.abi)
}

export function useRougexToken() {
  return useContract<ERC20>(ROUGEX_ADDRESSES, ERC20_ABI)
}
