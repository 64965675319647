import { AutoColumn } from 'components/Column'
import Leverage from 'components/Leverage'
import Row, { RowBetween } from 'components/Row'
import useDebouncedChangeHandler from 'hooks/useDebouncedChangeHandler'
import { useTradeSliderActionHandlers } from 'state/trade/hooks'
import { useTradeSliderState } from 'state/trade/state'
import styled from 'styled-components/macro'
import { ThemedText } from 'theme'

const StyledInputRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80px;
  height: 36px;
  padding: 0 6px;
  margin-left: 16px;
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.swapSectionBorder};
`

export function SliderBox() {
  const { percent } = useTradeSliderState()

  const { onPercentSelect } = useTradeSliderActionHandlers()
  const [percentForSlider, onPercentSelectForSlider] = useDebouncedChangeHandler(percent, onPercentSelect)
  const min = 1.1
  const max = 50
  const InputChange = () => {}
  const InputBlur = () => {}
  return (
    <Row>
      <AutoColumn width="100%" style={{ flex: 1 }}>
        <Leverage percent={percentForSlider} onSliderChange={onPercentSelectForSlider} />
        <RowBetween mt="6px">
          <ThemedText.TextPrimary>{min}</ThemedText.TextPrimary>
          <ThemedText.TextPrimary>{max}</ThemedText.TextPrimary>
        </RowBetween>
      </AutoColumn>
      <StyledInputRow>
        <input
          type="number"
          className="Leverage-input"
          value={percentForSlider}
          onChange={InputChange}
          onBlur={InputBlur}
          min={1.1}
          max={50}
        />
        <div className="x">x</div>
      </StyledInputRow>
    </Row>
  )
}
