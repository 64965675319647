import '@rainbow-me/rainbowkit/styles.css'

import { getDefaultWallets } from '@rainbow-me/rainbowkit'
import { configureChains, createConfig } from 'wagmi'
import { arbitrumGoerli } from 'wagmi/chains'
import { infuraProvider } from 'wagmi/providers/infura'
import { publicProvider } from 'wagmi/providers/public'

const { chains, publicClient } = configureChains(
  [arbitrumGoerli],
  [infuraProvider({ apiKey: '4bf032f2d38a4ed6bb975b80d6340847' }), publicProvider()]
)

const { connectors } = getDefaultWallets({
  appName: 'ROGUEX',
  projectId: 'd9e02d8e4a4bb8fe574b63430fa1cf34',
  chains,
})

export const wagmiConfig = createConfig({
  autoConnect: true,
  connectors,
  publicClient,
})

export const chain = chains
