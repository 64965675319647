import { useActiveChainId } from 'connection/useActiveChainId'
import { useTokenBalancesWithLoadingIndicator222 } from 'lib/hooks/useCurrencyBalance'
import { useMemo } from 'react'

import { useDefaultActiveTokens } from '../../hooks/Tokens'

export {
  default as useCurrencyBalance,
  useCurrencyBalances,
  useNativeCurrencyBalances,
  useTokenBalance,
  useTokenBalances,
  useTokenBalancesWithLoadingIndicator,
} from 'lib/hooks/useCurrencyBalance'

// mimics useAllBalances
export function useAllTokenBalances(): [{ [tokenAddress: string]: any }, boolean] {
  const { account, chainId } = useActiveChainId()
  const allTokens = useDefaultActiveTokens(chainId)
  const allTokensArray = useMemo(() => Object.values(allTokens ?? {}), [allTokens])
  const [balances, balancesIsLoading] = useTokenBalancesWithLoadingIndicator222(account ?? undefined, allTokensArray)
  return [balances ?? {}, balancesIsLoading]
}
