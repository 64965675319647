import { Trans } from '@lingui/macro'
import { CurrencyAmount } from '@uniswap/sdk-core'
import votingRewardABi from 'abis/votingReward.json'
import { AddButton, ClaimButton } from 'components/Button'
import { IconLoadingBubble, LoadingBubble, MediumLoadingBubble } from 'components/Loading'
import Row from 'components/Row'
import { Cell, StyledTableRow } from 'components/Table'
import {
  LARGE_MEDIA_BREAKPOINT,
  MAX_WIDTH_MEDIA_BREAKPOINT,
  MEDIUM_MEDIA_BREAKPOINT,
  SMALL_MEDIA_BREAKPOINT,
} from 'components/Tokens/constants'
import { useEthersSigner } from 'connection/useActiveChainId'
import { useCurrency } from 'hooks/Tokens'
import { useTokenContract, useVotingReward } from 'hooks/useContract'
import { useSingleCallResult } from 'lib/hooks/multicall'
import { CSSProperties, ForwardedRef, forwardRef, ReactNode, useMemo, useState } from 'react'
import { Box } from 'rebass'
import styled, { css } from 'styled-components/macro'
import { ThemedText } from 'theme'
import { getContract } from 'utils/getContract'

import { ReactComponent as NftIcon } from '../../assets/imgs/nft.svg'
import AddBribesModal from './AddBribesModal'
import { TokenSortMethod } from './state'

const StyledVoteRow = styled(StyledTableRow)<{
  first?: boolean
  last?: boolean
  $loading?: boolean
}>`
  align-items: flex-start;
  grid-template-columns: 2fr 1.5fr 1fr 1.5fr 1.2fr 1.5fr 1.2fr 1.5fr 1.5fr;
  max-width: ${MAX_WIDTH_MEDIA_BREAKPOINT};
  min-width: 390px;
  padding: 0;
  margin: 0;
  ${({ first, last }) => css`
    /* height: ${first || last ? '72px' : '64px'}; */
  `}
  transition: ${({
    theme: {
      transition: { duration, timing },
    },
  }) => css`background-color ${duration.medium} ${timing.ease}`};
  transition-duration: ${({ theme }) => theme.transition.duration.fast};

  &:hover {
    ${({ $loading, theme }) =>
      !$loading &&
      css`
        background-color: ${theme.hoverDefault};
      `}
    ${({ last }) =>
      last &&
      css`
        border-radius: 0px 0px 8px 8px;
      `}
  }

  .bg {
    ${({ theme }) =>
      css`
        background-color: ${theme.hoverDefault};
        height: 100%;
      `}
  }

  @media only screen and (max-width: ${MAX_WIDTH_MEDIA_BREAKPOINT}) {
    grid-template-columns: 2fr 1.5fr 1fr 1.5fr 1.2fr 1.5fr 1.2fr 1.5fr 1.5fr;
  }

  @media only screen and (max-width: ${LARGE_MEDIA_BREAKPOINT}) {
    grid-template-columns: 2fr 1.5fr 1fr 1.5fr 1.2fr 1.5fr 1.2fr 1.5fr 1.5fr;
  }

  @media only screen and (max-width: ${MEDIUM_MEDIA_BREAKPOINT}) {
    grid-template-columns: 2fr 1.5fr 1fr 1.5fr 1.2fr 1.5fr 1.2fr 1.5fr 1.5fr;
  }

  @media only screen and (max-width: ${SMALL_MEDIA_BREAKPOINT}) {
    grid-template-columns: 2fr 1.5fr 1fr 1.5fr 1.2fr 1.5fr 1.2fr 1.5fr 1.5fr;
    min-width: unset;
    border-bottom: 0.5px solid ${({ theme }) => theme.backgroundModule};

    :last-of-type {
      border-bottom: none;
    }
  }
`
const Input = styled.input<{ error?: boolean }>`
  font-size: 16px;
  outline: none;
  border: none;
  flex: 1 1 auto;
  background-color: transparent;
  transition: color 300ms ${({ error }) => (error ? 'step-end' : 'step-start')};
  color: ${({ error, theme }) => (error ? theme.accentFailure : theme.textPrimary)};
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 500;
  width: 100%;
  ::placeholder {
    color: ${({ theme }) => theme.deprecated_text4};
  }
  padding: 0px;
  -webkit-appearance: textfield;

  ::-webkit-search-decoration {
    -webkit-appearance: none;
  }

  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }

  ::placeholder {
    color: ${({ theme }) => theme.deprecated_text4};
  }
`

const StyledHeaderRow = styled(StyledVoteRow)`
  justify-content: center;
  color: ${({ theme }) => theme.textSecondary};
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;

  &:hover {
    background-color: transparent;
  }

  @media only screen and (max-width: ${SMALL_MEDIA_BREAKPOINT}) {
    justify-content: space-between;
  }
`

const VoteBox = styled(Box)`
  display: flex;
  margin-top: 8px;
  grid-gap: 8px;
  padding: 8px;
  border: 1px solid rgba(0, 0, 0, 0.08);
  border-radius: 4px;
`

const PoolCell = styled(Cell)`
  justify-content: flex-start;
  padding: 16px 8px;
  min-width: 200px;
  gap: 8px;
`

const TextLeftCell = styled(Cell)`
  justify-content: flex-start;
  padding: 16px 8px;
  @media only screen and (max-width: ${SMALL_MEDIA_BREAKPOINT}) {
    display: none;
  }
`
const TextRightCell = styled(Cell)`
  padding: 16px 8px;
  @media only screen and (max-width: ${MEDIUM_MEDIA_BREAKPOINT}) {
    display: none;
  }
`
/* Token Row: skeleton row component */
function VoteRow({
  header,
  pool,
  tvl,
  vApr,
  fees,
  bribes,
  totalRewards,
  totalVotes,
  myVote,
  earn,
  ...rest
}: {
  first?: boolean
  header: boolean
  $loading?: boolean
  fees: ReactNode
  tvl?: ReactNode
  vApr: ReactNode
  pool: ReactNode
  bribes: ReactNode
  totalRewards: ReactNode
  totalVotes: ReactNode
  myVote: ReactNode
  earn: ReactNode
  last?: boolean
  style?: CSSProperties
}) {
  const rowCells = (
    <>
      <PoolCell data-testid="pool-cell">{pool}</PoolCell>
      <TextRightCell data-testid="tvl-cell">{tvl}</TextRightCell>
      <TextLeftCell data-testid="vApr-cell">{vApr}</TextLeftCell>
      <TextRightCell data-testid="fees-cell">{fees}</TextRightCell>
      <TextRightCell data-testid="bribes-cell">{bribes}</TextRightCell>
      <TextRightCell data-testid="total-rewards-cell">{totalRewards}</TextRightCell>
      <TextRightCell data-testid="total-votes-cell">{totalVotes}</TextRightCell>
      <TextRightCell data-testid="my-vote-cell" className="bg">
        {myVote}
      </TextRightCell>
      <TextRightCell data-testid="my-vote-cell" className="bg">
        {earn}
      </TextRightCell>
    </>
  )
  if (header) return <StyledHeaderRow data-testid="vote-header-row">{rowCells}</StyledHeaderRow>
  return <StyledVoteRow {...rest}>{rowCells}</StyledVoteRow>
}

/* Header Row: top header row component for table */
export function HeaderRow() {
  return (
    <VoteRow
      header={true}
      pool={<Trans>{TokenSortMethod.POOL}</Trans>}
      tvl={
        <Row justify="end">
          <Trans>{TokenSortMethod.TVL}</Trans>
        </Row>
      }
      vApr={
        <Row>
          <Trans>{TokenSortMethod.VAPR}</Trans>
        </Row>
      }
      fees={
        <Row justify="end">
          <Trans>{TokenSortMethod.FEES}</Trans>
        </Row>
      }
      bribes={
        <Row justify="end">
          <Trans>{TokenSortMethod.BRIBES}</Trans>
        </Row>
      }
      totalRewards={
        <Row justify="end">
          <Trans>{TokenSortMethod.TOTAL_REWARDS}</Trans>
        </Row>
      }
      totalVotes={
        <Row justify="end">
          <Trans>{TokenSortMethod.TOTAL_VOTES}</Trans>
        </Row>
      }
      myVote={
        <Row justify="end">
          <Trans>{TokenSortMethod.MY_VOTE}</Trans>
        </Row>
      }
      earn={
        <Row justify="end">
          <Trans>{TokenSortMethod.EARN}</Trans>
        </Row>
      }
    />
  )
}
/* Loading State: row component with loading bubbles */
export function LoadingRow(props: { first?: boolean; last?: boolean }) {
  return (
    <VoteRow
      header={false}
      $loading
      pool={
        <Row>
          <IconLoadingBubble width="48px" height="48px" />
          <MediumLoadingBubble />
        </Row>
      }
      tvl={
        <Row flexDirection="column" align="end" gap="8px">
          <MediumLoadingBubble />
          <MediumLoadingBubble />
          <MediumLoadingBubble />
        </Row>
      }
      vApr={
        <Row>
          <LoadingBubble />
        </Row>
      }
      fees={
        <Row flexDirection="column" align="end" gap="8px">
          <MediumLoadingBubble />
          <MediumLoadingBubble />
          <MediumLoadingBubble />
        </Row>
      }
      bribes={
        <Row flexDirection="column" align="end" gap="8px">
          <MediumLoadingBubble />
          <AddButton>add</AddButton>
        </Row>
      }
      totalRewards={
        <Row flexDirection="column" align="end" gap="8px">
          <MediumLoadingBubble />
          <MediumLoadingBubble />
          <MediumLoadingBubble />
        </Row>
      }
      totalVotes={
        <Row flexDirection="column" align="end" gap="8px">
          <MediumLoadingBubble />
          <MediumLoadingBubble />
        </Row>
      }
      myVote={
        <Row flexDirection="column" align="end" gap="8px">
          <MediumLoadingBubble />
          <MediumLoadingBubble />
          <MediumLoadingBubble />
        </Row>
      }
      earn={
        <Row flexDirection="column" align="end" gap="8px">
          <MediumLoadingBubble />
          <MediumLoadingBubble />
          <ClaimButton>claim</ClaimButton>
        </Row>
      }
      {...props}
    />
  )
}

interface LoadedRowProps {
  voteListIndex: number
  voteListLength: number
  vote: any
}

const TotalRewardsItem = ({ rewardAddr, poolAddr }: any) => {
  const currenty = useCurrency(rewardAddr)
  const tokenContract = useTokenContract(rewardAddr, false)
  const { result } = useSingleCallResult(tokenContract, 'balanceOf', [poolAddr])

  const tokenInfo = useMemo(() => {
    if (!result || !currenty) return
    return CurrencyAmount.fromRawAmount(currenty, result[0])
  }, [currenty, result])
  return (
    <ThemedText.TextSecondary fontSize={14}>
      {tokenInfo?.toSignificant() || 0} {currenty?.symbol}
    </ThemedText.TextSecondary>
  )
}

const TotalEarnsItem = ({ rewardAddr, poolAddr, tokenId }: any) => {
  const currenty = useCurrency(rewardAddr)
  const tokenContract = useVotingReward(poolAddr)
  const { result, error } = useSingleCallResult(tokenContract, 'earned', [rewardAddr, tokenId])
  console.log('result', tokenId, result, error)

  const tokenInfo = useMemo(() => {
    if (!result || !currenty) return
    return CurrencyAmount.fromRawAmount(currenty, result[0])
  }, [currenty, result])
  return (
    <ThemedText.TextRewards>
      {tokenInfo?.toSignificant() || 0} {currenty?.symbol}
    </ThemedText.TextRewards>
  )
}

/* Loaded State: row component with token information */
export const LoadedRow = forwardRef((props: LoadedRowProps, ref: ForwardedRef<HTMLDivElement>) => {
  const { voteListIndex, voteListLength, vote } = props
  const [value, setValue] = useState<any>('')
  const [showAddBribes, setShowAddBribes] = useState(false)
  const handleDismissAddBribes = () => {
    setShowAddBribes(false)
  }
  const singerProvider = useEthersSigner()
  const onClaim = async (data: any) => {
    const contract = getContract(data.votingRewardAddress, votingRewardABi.abi, singerProvider)
    await contract.getReward(data.tokenid, data.rewardsList)
  }
  function handleInput(val: any) {
    setValue(val)
  }
  return (
    <>
      <div ref={ref} data-testid={`vote-table-row-${vote.token}`}>
        <VoteRow
          header={false}
          pool={
            <Row>
              <NftIcon width={48} height={48} />
              <ThemedText.TextPrimary fontWeight={700} fontSize={16} ml="16px">
                #{vote.poolname}
              </ThemedText.TextPrimary>
            </Row>
          }
          tvl={
            <Row flexDirection="column" align="end">
              <ThemedText.TextPrimary fontWeight={700}>{vote.voteAmount}</ThemedText.TextPrimary>
              <ThemedText.TextSecondary fontSize={14}>10,203,403 ROX</ThemedText.TextSecondary>
              <ThemedText.TextSecondary fontSize={14}>18,283 ETH</ThemedText.TextSecondary>
            </Row>
          }
          vApr={
            <Row>
              <ThemedText.TextPrimary fontWeight={700}>145.49%</ThemedText.TextPrimary>
            </Row>
          }
          fees={
            <Row flexDirection="column" align="end">
              <ThemedText.TextPrimary fontWeight={700}>{vote.voteAmount}</ThemedText.TextPrimary>
              <ThemedText.TextSecondary fontSize={14}>10,203,403 ROX</ThemedText.TextSecondary>
              <ThemedText.TextSecondary fontSize={14}>18,283 ETH</ThemedText.TextSecondary>
            </Row>
          }
          bribes={
            <Row flexDirection="column" align="end">
              <ThemedText.TextPrimary fontWeight={700}>{vote.bribes || 0}</ThemedText.TextPrimary>
              <Row mt="8px" justify="end">
                <AddButton onClick={() => setShowAddBribes(true)}>add</AddButton>
              </Row>
            </Row>
          }
          totalRewards={
            <Row flexDirection="column" align="end">
              <ThemedText.TextPrimary fontWeight={700}>{vote.voteAmount}</ThemedText.TextPrimary>
              {vote.rewardsList.map((item: any, index: number) => {
                return (
                  <TotalRewardsItem key={'rewardsList' + index} rewardAddr={item} poolAddr={vote.votingRewardAddress} />
                )
              })}
            </Row>
          }
          totalVotes={
            <Row flexDirection="column" align="end">
              <ThemedText.TextPrimary fontWeight={700}>{vote?.poolWeightAmount.toFixed(2)}</ThemedText.TextPrimary>
              <ThemedText.TextSecondary fontSize={14}>{vote?.poolWeightRatio.toFixed(2)}%</ThemedText.TextSecondary>
            </Row>
          }
          myVote={
            <Row flexDirection="column" align="end">
              <ThemedText.TextPrimary fontWeight={700}>0</ThemedText.TextPrimary>
              <ThemedText.TextSecondary fontSize={14}>0%</ThemedText.TextSecondary>
              <VoteBox>
                <Input
                  type="number"
                  autoComplete="off"
                  autoCorrect="off"
                  autoCapitalize="off"
                  spellCheck="false"
                  placeholder=""
                  onChange={handleInput}
                  value={value}
                />
                <ThemedText.TextSecondary fontWeight={700} fontSize={14}>
                  Max
                </ThemedText.TextSecondary>
              </VoteBox>
            </Row>
          }
          earn={
            <Row flexDirection="column" align="end">
              {vote.rewardsList.map((item: any, index: number) => {
                return (
                  <TotalEarnsItem
                    key={'earn' + index}
                    rewardAddr={item}
                    tokenId={vote.tokenid}
                    poolAddr={vote.votingRewardAddress}
                  />
                )
              })}
              <Row mt="8px" justify="end">
                <ClaimButton disabled={!vote.tokenid} onClick={() => onClaim(vote)}>
                  claim
                </ClaimButton>
              </Row>
            </Row>
          }
          first={voteListIndex === 0}
          last={voteListIndex === voteListLength - 1}
        />
      </div>
      <AddBribesModal isOpen={showAddBribes} onDismiss={handleDismissAddBribes} />
    </>
  )
})

LoadedRow.displayName = 'LoadedRow'
