import { Trans } from '@lingui/macro'
import { Box } from '@mui/material'
import { ButtonConfirmed } from 'components/Button'
import Column from 'components/Column'
import SwapCurrencyInputPanel from 'components/CurrencyInputPanel/SwapCurrencyInputPanel'
import { useDynamicApprove } from 'components/DynamicApprove'
import Row, { RowBetween } from 'components/Row'
import TransactionConfirmationModal, { ConfirmationModalContent } from 'components/TransactionConfirmationModal'
import { useRoguxCurrency } from 'constants/tokens'
import dayjs from 'dayjs'
import { useLockContract } from 'hooks/useContract'
import { useInputAmount } from 'hooks/useInputAmount'
import { useCallback, useMemo, useState } from 'react'
import { useTransactionAdder } from 'state/transactions/hooks'
import { TransactionType } from 'state/transactions/types'
import styled from 'styled-components/macro'
import { ThemedText } from 'theme'

import LockPicker from './LockPicker'
import TimeCard from './TimeCard'

export const LockCard = styled(Row)`
  border: 1px solid ${({ theme }) => theme.swapSectionBorder};
  padding: 16px;
  border-radius: 16px;
  margin-top: 8px;
  flex-direction: column;

  .infoItem {
    padding: 6px 0;
    &:not(:last-child) {
      border-bottom: 1px solid ${({ theme }) => theme.swapSectionBorder};
    }
  }
`

export const AmountBox = styled(Box)`
  display: flex;
  flex-direction: column;
  grid-gap: 8px;
  width: 100%;
  margin-top: 8px;
`

export const BoxCard = styled(Box)<{ isDisabled?: boolean }>`
  background-color: ${({ theme, isDisabled }) => (isDisabled ? theme.backgroundInteractive : 'transparent')};
  border: 1px solid ${({ theme }) => theme.swapSectionBorder};
  padding: 16px 8px 8px;
  border-radius: 16px;
`

interface CreateModalProps {
  isOpen: boolean
  onDismiss: () => void
}
export default function CreateLockModal({ isOpen, onDismiss }: CreateModalProps) {
  const [attemptingTxn, setAttemptingTxn] = useState<boolean>(false)
  const [txHash, setTxHash] = useState<string>('')
  const pendingText = 'pending ...'

  const [weeks, setWeeks] = useState(2)
  const unLockDate = useMemo(() => {
    return dayjs()
      .add(weeks * 7, 'day')
      .format('YYYY/MM/DD')
  }, [weeks])

  const RoguxCurrency = useRoguxCurrency()
  const { inputAmount: amount, handleUserInput: setAmount } = useInputAmount(RoguxCurrency)
  const handleAmountChange = useCallback(
    (amount: string) => {
      setAmount(amount)
    },
    [setAmount]
  )
  const LockContract = useLockContract()
  const { DynamicApprove, isApproved } = useDynamicApprove([amount.amount], LockContract?.address)

  const addTransaction = useTransactionAdder()
  const handleLock = useCallback(async () => {
    if (!weeks || !amount.amount?.numerator.toString() || !LockContract) return

    setAttemptingTxn(true)
    try {
      const response = await LockContract.create_lock(amount.amount.numerator.toString(), 86400 * 7 * weeks)
      setAttemptingTxn(false)
      addTransaction(response, {
        type: TransactionType.CREATE_LOCK,
        token0Address: '',
        token1Address: '',
      })
      setTxHash(response.hash)
    } catch (error) {
      setAttemptingTxn(false)
      console.log('create Lock error', error)
    }
  }, [LockContract, addTransaction, amount, weeks])

  const modalContent = () => {
    return <ModalContent />
  }

  function ModalContent() {
    return (
      <Column gap="sm">
        <AmountBox>
          <ThemedText.HeadNav054>
            <Trans>Amounts</Trans>
          </ThemedText.HeadNav054>
          <BoxCard>
            <Box p=" 0 8px" pb="12px">
              <SwapCurrencyInputPanel
                label={<Trans>From</Trans>}
                value={amount.value}
                showMaxButton={false}
                currency={RoguxCurrency}
                id="LockAmount"
                onUserInput={handleAmountChange}
                // onMax={handleMaxInput}
                fiatValue={{ data: Number(amount), isLoading: false }}
                // onCurrencySelect={handleInputSelect}
                // otherCurrency={currencies[Field.OUTPUT]}
                loading={false}
                onMaxTab={handleAmountChange}
                showMaxTab={true}
              />
            </Box>
          </BoxCard>
        </AmountBox>
        <LockPicker title="Lock Until" />
        <TimeCard setWeeks={setWeeks} />
        <LockCard>
          <RowBetween gap="sm" className="infoItem">
            <ThemedText.TextSecondary fontSize={14}>
              <Trans>Voting Power</Trans>
            </ThemedText.TextSecondary>
            <ThemedText.TextPrimary fontWeight={700}>
              <Trans>555,123,100.999 ROX</Trans>
            </ThemedText.TextPrimary>
          </RowBetween>
          <RowBetween gap="sm" className="infoItem">
            <ThemedText.TextSecondary fontSize={14}>
              <Trans>Expires in</Trans>
            </ThemedText.TextSecondary>
            <ThemedText.TextPrimary>
              <Trans>{weeks * 7} Days</Trans>
            </ThemedText.TextPrimary>
          </RowBetween>
          <RowBetween gap="sm" className="infoItem">
            <ThemedText.TextSecondary fontSize={14}>
              <Trans>Lock Until</Trans>
            </ThemedText.TextSecondary>
            <ThemedText.TextPrimary>
              <Trans>{unLockDate}</Trans>
            </ThemedText.TextPrimary>
          </RowBetween>
        </LockCard>
        <Column width="100%" mt="8px" gap="sm">
          <DynamicApprove />
          <ButtonConfirmed disabled={!isApproved} onClick={handleLock}>
            Lock
          </ButtonConfirmed>
        </Column>
      </Column>
    )
  }

  return (
    <TransactionConfirmationModal
      isOpen={isOpen}
      onDismiss={onDismiss}
      attemptingTxn={attemptingTxn}
      hash={txHash}
      width="400px"
      reviewContent={() => (
        <ConfirmationModalContent
          title={<Trans>Create New Lock</Trans>}
          onDismiss={onDismiss}
          topContent={modalContent}
        />
      )}
      pendingText={pendingText}
    />
    // <Modal isOpen={isOpen} $scrollOverlay={true} onDismiss={onDismiss} maxHeight={90} width="500px">
    //   <ConfirmationModalContent
    //     title={<Trans>Create New Lock</Trans>}
    //     onDismiss={onDismiss}
    //     topContent={modalHeader}
    //     bottomContent={modalBottom}
    //   />
    // </Modal>
  )
}
