import { Trans } from '@lingui/macro'
import Row from 'components/Row'
import styled from 'styled-components/macro'
import { ThemedText } from 'theme'

import SearchBar from './SearchBar'

const LiquidityContentBox = styled.div`
  margin-top: 30px;
  border-top: 1px solid ${({ theme }) => theme.primaryBorder};
  .liquidityInfo {
    padding-top: 24px;
    padding-bottom: 50px;
  }
  .searchBox {
    padding-bottom: 24px;
    border-bottom: 1px solid ${({ theme }) => theme.primaryBorder};
  }
`

export default function ContentWrapper() {
  return (
    <LiquidityContentBox>
      <Row justify="space-between" className="liquidityInfo">
        <Row flexDirection="column" align="center">
          <ThemedText.TextSecondary>
            <Trans>TVL</Trans>
          </ThemedText.TextSecondary>
          <ThemedText.TextPrimary fontSize={24} fontWeight={700} mt="4px">
            $214,349,313.99
          </ThemedText.TextPrimary>
        </Row>
        <Row flexDirection="column" align="center">
          <ThemedText.TextSecondary>
            <Trans>Volume</Trans>
          </ThemedText.TextSecondary>
          <ThemedText.TextPrimary fontSize={24} fontWeight={700} mt="4px">
            $214,349,313.99
          </ThemedText.TextPrimary>
        </Row>
        <Row flexDirection="column" align="center">
          <ThemedText.TextSecondary>
            <Trans>Fees</Trans>
          </ThemedText.TextSecondary>
          <ThemedText.TextPrimary fontSize={24} fontWeight={700} mt="4px">
            $214,349,313.99
          </ThemedText.TextPrimary>
        </Row>
      </Row>
      <div className="searchBox">
        <SearchBar />
      </div>
    </LiquidityContentBox>
  )
}
