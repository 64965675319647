import Row from 'components/Row'
import styled from 'styled-components/macro'
import { ThemedText } from 'theme'

const TabWrapper = styled(Row)`
  grid-gap: 8px;
  height: 42px;
  .tabItem {
    flex: 1;
    cursor: pointer;
  }
`
interface TabProps {
  tabList: any
  curr: number
  setCurr: (value: number) => void
}

export default function Tab({ tabList, curr, setCurr }: TabProps) {
  return (
    <TabWrapper>
      {tabList?.map((tab: any, i: number) => (
        <Row justify="center" className="tabItem" key={i} onClick={() => setCurr(i)}>
          {curr == i ? (
            <ThemedText.TextPrimary fontWeight={700} fontSize={16}>
              {tab}
            </ThemedText.TextPrimary>
          ) : (
            <ThemedText.TextPrimary>{tab}</ThemedText.TextPrimary>
          )}
        </Row>
      ))}
    </TabWrapper>
  )
}
