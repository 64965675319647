import Row from 'components/Row'
import { PageWrapper } from 'components/swap/styleds'
import styled from 'styled-components/macro'

import TradeLeftWrapper from './TradeLeftWrapper'
import TradeRightWrapper from './TradeRightWrapper'

const TradeWrapper = styled(Row)`
  grid-gap: 16px;
  align-items: flex-start;
  .trade-l {
    flex: 1;
  }
  .trade-r {
    width: 394px;
  }
`

export default function Trade() {
  return (
    <PageWrapper data-testid="trade-page">
      <TradeWrapper>
        <div className="trade-l">
          <TradeLeftWrapper />
        </div>
        <div className="trade-r">
          <TradeRightWrapper />
        </div>
      </TradeWrapper>
    </PageWrapper>
  )
}
