import { Trans } from '@lingui/macro'
import { Box } from '@mui/material'
import SwapCurrencyInputPanel from 'components/CurrencyInputPanel/SwapCurrencyInputPanel'
import { useRoguxCurrency } from 'constants/tokens'
import styled from 'styled-components/macro'
import { ThemedText } from 'theme/components/text'

const BoxCard = styled(Box)<{ isDisabled?: boolean }>`
  background-color: ${({ theme, isDisabled }) => (isDisabled ? theme.backgroundInteractive : 'transparent')};
  border: 1px solid ${({ theme }) => theme.swapSectionBorder};
  padding: 16px 8px 8px;
  border-radius: 16px;
`

const AmountBox = styled(Box)`
  display: flex;
  flex-direction: column;
  grid-gap: 8px;
`
interface LockAmountProps {
  isDisabled?: boolean
  amount: string
  handleAmountChange: (amount: string) => void
}
export default function LockAmount({ isDisabled, amount, handleAmountChange }: LockAmountProps) {
  const rougxCurreny = useRoguxCurrency()
  return (
    <AmountBox>
      <ThemedText.HeadNav054>
        <Trans>Amounts</Trans>
      </ThemedText.HeadNav054>
      <BoxCard isDisabled={isDisabled}>
        <Box p=" 0 8px" pb="12px">
          <SwapCurrencyInputPanel
            label={<Trans>From</Trans>}
            disabled={isDisabled}
            value={amount}
            showMaxButton={false}
            currency={rougxCurreny}
            id="LockAmount"
            onUserInput={handleAmountChange}
            // onMax={handleMaxInput}
            fiatValue={{ data: Number(amount), isLoading: false }}
            // onCurrencySelect={handleInputSelect}
            // otherCurrency={currencies[Field.OUTPUT]}
            loading={false}
            onMaxTab={handleAmountChange}
            showMaxTab={true}
          />
        </Box>
      </BoxCard>
    </AmountBox>
  )
}
