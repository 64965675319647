import { Trans } from '@lingui/macro'
import Row from 'components/Row'
import styled from 'styled-components/macro'
import { ThemedText } from 'theme'
const VoteInfoBox = styled(Row)`
  justify-content: space-between;
  padding: 22px 0 30px;
  border-bottom: 1px solid ${({ theme }) => theme.primaryBorder};
`

export default function VoteInfo() {
  return (
    <VoteInfoBox>
      <Row flexDirection="column" align="center">
        <ThemedText.TextSecondary fontSize={14}>
          <Trans>Emissions / % of Vote</Trans>
        </ThemedText.TextSecondary>
        <ThemedText.TextPrimary fontSize={18} fontWeight={700} mt="12px">
          $214,349,313.99
        </ThemedText.TextPrimary>
      </Row>
      <Row flexDirection="column" align="center">
        <ThemedText.TextSecondary fontSize={14}>
          <Trans>Average Vote APR</Trans>
        </ThemedText.TextSecondary>
        <ThemedText.TextPrimary fontSize={18} fontWeight={700} mt="12px">
          29.32%
        </ThemedText.TextPrimary>
      </Row>
      <Row flexDirection="column" align="center">
        <ThemedText.TextSecondary fontSize={14}>
          <Trans>Total Bribe</Trans>
        </ThemedText.TextSecondary>
        <ThemedText.TextPrimary fontSize={18} fontWeight={700} mt="12px">
          $214,349,313.99
        </ThemedText.TextPrimary>
      </Row>
      <Row flexDirection="column" align="center">
        <ThemedText.TextSecondary fontSize={14}>
          <Trans>Epoch 28 Ends in</Trans>
        </ThemedText.TextSecondary>
        <ThemedText.TextPrimary fontSize={18} fontWeight={700} mt="12px">
          6D / 12H / 9S
        </ThemedText.TextPrimary>
      </Row>
    </VoteInfoBox>
  )
}
