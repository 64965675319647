import { Cell, StyledTableRow } from 'components/Table'
import {
  LARGE_MEDIA_BREAKPOINT,
  MAX_WIDTH_MEDIA_BREAKPOINT,
  MEDIUM_MEDIA_BREAKPOINT,
  SMALL_MEDIA_BREAKPOINT,
} from 'components/Tokens/constants'
import { Box } from 'rebass'
import styled, { css } from 'styled-components/macro'

export const StyledRow = styled(StyledTableRow)<{
  first?: boolean
  last?: boolean
  $loading?: boolean
}>`
  align-items: flex-start;
  grid-template-columns: 1.3fr 1.4fr 1.3fr 1.3fr 1.2fr 1.2fr 1.2fr 1.2fr;
  max-width: ${MAX_WIDTH_MEDIA_BREAKPOINT};
  min-width: 390px;
  padding: 0;
  margin: 0;
  ${({ last, theme }) => css`
    border-bottom: ${last ? 'none' : `1px solid ${theme.primaryBorder}`};
  `}
  transition: ${({
    theme: {
      transition: { duration, timing },
    },
  }) => css`background-color ${duration.medium} ${timing.ease}`};
  transition-duration: ${({ theme }) => theme.transition.duration.fast};

  &:hover {
    ${({ $loading, theme }) =>
      !$loading &&
      css`
        background-color: ${theme.hoverDefault};
      `}
    ${({ last }) =>
      last &&
      css`
        border-radius: 0px 0px 8px 8px;
      `}
  }

  .bg {
    ${({ theme }) =>
      css`
        background-color: ${theme.hoverDefault};
      `}
  }

  @media only screen and (max-width: ${MAX_WIDTH_MEDIA_BREAKPOINT}) {
    grid-template-columns: 2fr 1.5fr 1fr 1.5fr 1.2fr 1.5fr 1.2fr 1.5fr;
  }

  @media only screen and (max-width: ${LARGE_MEDIA_BREAKPOINT}) {
    grid-template-columns: 2fr 1.5fr 1fr 1.5fr 1.2fr 1.5fr 1.2fr 1.5fr;
  }

  @media only screen and (max-width: ${MEDIUM_MEDIA_BREAKPOINT}) {
    grid-template-columns: 2fr 1.5fr 1fr 1.5fr 1.2fr 1.5fr 1.2fr 1.5fr;
  }

  @media only screen and (max-width: ${SMALL_MEDIA_BREAKPOINT}) {
    grid-template-columns: 2fr 1.5fr 1fr 1.5fr 1.2fr 1.5fr 1.2fr 1.5fr;
    min-width: unset;
    border-bottom: 0.5px solid ${({ theme }) => theme.backgroundModule};

    :last-of-type {
      border-bottom: none;
    }
  }
`
export const StyledHeaderRow = styled(StyledRow)`
  justify-content: center;
  color: ${({ theme }) => theme.textSecondary};
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;

  &:hover {
    background-color: transparent;
  }

  @media only screen and (max-width: ${SMALL_MEDIA_BREAKPOINT}) {
    justify-content: space-between;
  }
`

export const TextPrimarySpace: any = { whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden' }

export const PoolCell = styled(Cell)`
  justify-content: flex-start;
  padding: 12px 0px;
  min-width: 100px;
  overflow: hidden;
  gap: 8px;
`

export const LongIcon = styled(Box)`
  width: 14px;
  height: 14px;
  flex-shrink: 0;
  background-color: ${({ theme }) => theme.tradeGreen};
  border-radius: 50%;
  line-height: 14px;
  text-align: center;
`

export const TextLeftCell = styled(Cell)`
  justify-content: flex-start;
  padding: 12px 0px;
  @media only screen and (max-width: ${SMALL_MEDIA_BREAKPOINT}) {
    display: none;
  }
`
export const TextRightCell = styled(Cell)`
  padding: 12px 0px;
  height: 100%;
  justify-content: flex-start;
  @media only screen and (max-width: ${MEDIUM_MEDIA_BREAKPOINT}) {
    display: none;
  }
`
