import { Trans } from '@lingui/macro'
import { Box } from '@mui/material'
import { ButtonConfirmed } from 'components/Button'
import Modal from 'components/Modal'
import Row from 'components/Row'
import { ConfirmationModalContent } from 'components/TransactionConfirmationModal'
import LockAmount from 'pages/Lock/LockModal/CreateLockModal/LockAmount'
import LockPicker from 'pages/Lock/LockModal/CreateLockModal/LockPicker'
import { useCallback, useState } from 'react'
import styled from 'styled-components/macro'
import { ThemedText } from 'theme'

const TypesBox = styled(Row)`
  background-color: ${({ theme }) => theme.backgroundInteractive};
  border: 1px solid ${({ theme }) => theme.primaryBorder};
  padding: 16px;
  border-radius: 16px;
  margin-top: 8px;
`
interface AddBribesModalProps {
  isOpen: boolean
  onDismiss: () => void
}
export default function AddBribesModal({ isOpen, onDismiss }: AddBribesModalProps) {
  const [amount, setAmount] = useState<any>('')
  const handleAmountChange = useCallback(
    (amount: string) => {
      setAmount(amount)
    },
    [setAmount]
  )
  const modalContent = () => {
    return (
      <>
        <ThemedText.TextSecondary fontWeight={700} fontSize={14}>
          <Trans>Types</Trans>
        </ThemedText.TextSecondary>
        <TypesBox>
          <ThemedText.TextSecondary fontWeight={700} fontSize={14}>
            <Trans>LP</Trans>
          </ThemedText.TextSecondary>
          <ThemedText.TextSecondary fontWeight={700} fontSize={14} ml="12px" mr="12px">
            <Trans>|</Trans>
          </ThemedText.TextSecondary>
          <ThemedText.TextSecondary fontWeight={700} fontSize={14}>
            <Trans>ETH/USDT</Trans>
          </ThemedText.TextSecondary>
        </TypesBox>
        <Box mt="16px">
          <LockAmount amount={amount} handleAmountChange={handleAmountChange} />
        </Box>
        <LockPicker title="Ends in" />
        <Box mt="8px">
          <ButtonConfirmed>Add</ButtonConfirmed>
        </Box>
      </>
    )
  }

  return (
    <Modal isOpen={isOpen} $scrollOverlay={true} onDismiss={onDismiss} maxHeight={90} width="500px">
      <ConfirmationModalContent title={<Trans>Add Bribes</Trans>} onDismiss={onDismiss} topContent={modalContent} />
    </Modal>
  )
}
