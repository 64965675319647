import { Trans } from '@lingui/macro'
import { formatCurrencyAmount, formatPrice, NumberType } from '@uniswap/conedison/format'
import { Currency, CurrencyAmount } from '@uniswap/sdk-core'
import { ButtonConfirmed } from 'components/Button'
import SwapCurrencyInputPanel from 'components/CurrencyInputPanel/SwapCurrencyInputPanel'
import { useDynamicApprove } from 'components/DynamicApprove'
import Row, { RowBetween } from 'components/Row'
import SettingsTab from 'components/Settings'
import SwapDetails from 'components/swap/SwapDetails'
import Tab from 'components/Tab'
import { useActiveChainId } from 'connection/useActiveChainId'
import { LONG, MaketTable, SHORT, SWAP } from 'constants/misc'
import { getSwapCurrencyId } from 'constants/tokens'
import { useTradePool, useTradeRouter } from 'hooks/useContract'
import { useUSDPrice } from 'hooks/useUSDPrice'
import useWrapCallback, { WrapType } from 'hooks/useWrapCallback'
import { SwapSection } from 'pages/Swap/Styled'
import { useCallback, useEffect, useMemo, useReducer, useState } from 'react'
import { usePositionSwapActionHandlers as useSwapActionHandlers, usePositionSwapInfo } from 'state/positionSwap/hooks'
import swapReducer, { initialState as initialSwapState, SwapState } from 'state/positionSwap/reducer'
import { Field } from 'state/swap/actions'
import { tryParsePlaces } from 'state/swap/hooks'
import { useTradeSliderState } from 'state/trade/state'
import { useTransactionAdder } from 'state/transactions/hooks'
import { TransactionType } from 'state/transactions/types'
import { useUserSlippageTolerance } from 'state/user/hooks'
import styled from 'styled-components/macro'
import { ThemedText } from 'theme'
import { compareAddress } from 'utils'
import { BN } from 'utils/bn'
import { maxAmountSpend } from 'utils/maxAmountSpend'

import { ReactComponent as DownIcon } from '../../assets/imgs/down.svg'
import { useMockPool } from './Hooks'
import ClosePositionModal from './Modal/ClosePositionModal'
import EditPositionModal from './Modal/EditPositionModal'
import TradeConfirmationModal from './Modal/TradeConfirmModal'

const RightWrapper = styled.div`
  display: flex;
  flex-direction: column;
  grid-gap: 8px;
  position: relative;
  background: ${({ theme }) => theme.backgroundSurface};
  border-radius: 16px;
  border: 1px solid ${({ theme }) => theme.backgroundOutline};
  padding: 14px 8px 16px 8px;
  transition: transform 250ms ease;
`

const InputSwapSection = styled(SwapSection)`
  border-radius: 12px 12px 0 0;
`
const OutputSwapSection = styled(SwapSection)`
  border-radius: 0 0 12px 12px;
`
const tabList = MaketTable
export default function TradeRightWrapper({
  disableTokenInputs = false,
  prefilledState = {},
  onCurrencyChange,
}: {
  disableTokenInputs?: boolean
  prefilledState?: Partial<SwapState>
  onCurrencyChange?: (selected: Pick<SwapState, Field.INPUT | Field.OUTPUT>) => void
}) {
  const { account } = useActiveChainId()
  const [showSearch, setShowSearch] = useState<boolean>(true)
  const [showConfirm, setShowConfirm] = useState<boolean>(false)
  const [showClose, setShowClose] = useState<boolean>(false)
  const [showEdit, setShowEdit] = useState<boolean>(false)
  const [curr, setCurr] = useState<number>(1)

  const [state, dispatch] = useReducer(swapReducer, { ...initialSwapState, ...prefilledState })
  const { onCurrencySelection, onUserInput, onChangeLong } = useSwapActionHandlers(dispatch)

  const navTableChange = useCallback(
    (value: number) => {
      if (!tabList[value]) return

      if (tabList[value] === SWAP) {
        console.log('1')
      } else if (tabList[value] === LONG) {
        onChangeLong(true)
      } else if (tabList[value] === SHORT) {
        onChangeLong(false)
      }
      setCurr(value)
    },
    [onChangeLong]
  )
  const { chainId } = useActiveChainId()
  const [userSlippageTolerance] = useUserSlippageTolerance()
  const { typedValue, independentField } = state
  const { percent: sliederLever } = useTradeSliderState()

  const dependentField: Field = independentField === Field.INPUT ? Field.OUTPUT : Field.INPUT

  const { pool, tradePool, tokenRatio, slippageTolerance } = useMockPool()

  const swapInfo = usePositionSwapInfo(state, chainId, tokenRatio)

  const {
    tradePool: { isLoading, poolAddr },
    allowedSlippage,
    autoSlippage,
    currencyBalances,
    parsedAmount,
    currencies,
    useInpuntAmount,
    inputError: swapInputError,
  } = swapInfo

  const handleTypeInput = useCallback(
    (value: string) => {
      if (value == undefined) return
      const parseAmount = tryParsePlaces(value, currencies[Field.INPUT] ?? undefined)

      if (!parseAmount) return
      console.log('parseAmount', parseAmount)

      onUserInput(Field.INPUT, value)
    },
    [currencies, onUserInput]
  )
  const handleTypeOutput = useCallback(
    (value: string) => {
      onUserInput(Field.OUTPUT, value)
    },
    [onUserInput]
  )
  const handleAllSelect = useCallback(
    (inputCurrency: Currency, outputCurrency: Currency) => {
      onCurrencySelection(Field.INPUT, inputCurrency)
      onCurrencySelection(Field.OUTPUT, outputCurrency)
      onCurrencyChange?.({
        [Field.INPUT]: {
          currencyId: getSwapCurrencyId(inputCurrency),
        },
        [Field.OUTPUT]: {
          currencyId: getSwapCurrencyId(outputCurrency),
        },
      })
    },
    [onCurrencyChange, onCurrencySelection]
  )

  const maxInputAmount: CurrencyAmount<Currency> | undefined = useMemo(
    () => maxAmountSpend(currencyBalances[Field.INPUT]),
    [currencyBalances]
  )
  const {
    wrapType,
    execute: onWrap,
    inputError: wrapInputError,
  } = useWrapCallback(currencies[Field.INPUT], currencies[Field.OUTPUT], typedValue)
  const showWrap: boolean = wrapType !== WrapType.NOT_APPLICABLE
  const parsedAmounts = useMemo(
    () =>
      showWrap
        ? {
            [Field.INPUT]: parsedAmount,
            [Field.OUTPUT]: parsedAmount,
          }
        : {
            [Field.INPUT]: independentField === Field.INPUT ? parsedAmount : useInpuntAmount?.inputAmount,
            [Field.OUTPUT]: independentField === Field.OUTPUT ? parsedAmount : useInpuntAmount?.outputAount,
          },
    [independentField, parsedAmount, showWrap, useInpuntAmount?.inputAmount, useInpuntAmount?.outputAount]
  )
  const showMaxButton = Boolean(maxInputAmount?.greaterThan(0) && !parsedAmounts[Field.INPUT]?.equalTo(maxInputAmount))

  const handleMaxInput = useCallback(() => {
    maxInputAmount && onUserInput(Field.INPUT, maxInputAmount.toExact())
  }, [maxInputAmount, onUserInput])

  //0x68cE36ee19B5Dc809A1496E0Ef4752507dF18f23

  useEffect(() => {
    if (!pool) return

    if (curr === 1) {
      handleAllSelect(pool.token0.wrapped, pool.token1.wrapped)
    } else if (curr === 2) {
      handleAllSelect(pool.token1.wrapped, pool.token0.wrapped)
    }
  }, [curr, handleAllSelect, pool])

  const formattedAmounts = useMemo(
    () => ({
      [independentField]: typedValue,
      [dependentField]: showWrap
        ? parsedAmounts[independentField]?.toExact() ?? ''
        : formatCurrencyAmount(parsedAmounts[dependentField], NumberType.SwapTradeAmount, ''),
    }),
    [dependentField, independentField, parsedAmounts, showWrap, typedValue]
  )
  const fiatValueInput = useUSDPrice(parsedAmounts[Field.INPUT])
  const fiatValueOutput = useUSDPrice(parsedAmounts[Field.OUTPUT])
  const showFiatValueInput = Boolean(parsedAmounts[Field.INPUT])
  const showFiatValueOutput = Boolean(parsedAmounts[Field.OUTPUT])

  const stablecoinPriceImpact = undefined

  const addTransaction = useTransactionAdder()
  const handleDismissClose = () => {
    setShowClose(false)
  }
  const handleDismissEdit = () => {
    setShowEdit(false)
  }
  const handleDismissSearch = () => {
    setShowSearch(false)
  }

  const tradePoolContract = useTradePool(tradePool)
  const tradeRouterContract = useTradeRouter()

  const [attemptingTxn, setAttemptingTxn] = useState<boolean>(false)
  const [txHash, setTxHash] = useState<string>('')

  const pendingText = 'tesrdwadsdsadsa'

  const handleDismissConfirmation = () => {
    setAttemptingTxn(false)
    setTxHash('')
    setShowConfirm(false)
  }

  const openPositon: any = useCallback(async () => {
    if (!tradeRouterContract || !account || !tradePool || !tradePoolContract || !pool) return
    const INPUT = parsedAmounts[Field.INPUT]?.numerator.toString()
    if (!INPUT) return
    setAttemptingTxn(true)

    try {
      const colAmount = INPUT
      const istoken0 = compareAddress(pool?.token0.address, parsedAmounts[Field.INPUT]?.currency.wrapped.address)
      let lss: any
      if (istoken0) {
        lss = await tradePoolContract.token0t1NoSpl(INPUT)
        lss = BN(lss.toString()).times(sliederLever).toFixed(0)
      } else {
        lss = await tradePoolContract.token1t0NoSpl(INPUT)
        lss = BN(lss.toString()).times(sliederLever).toFixed(0)
        //  lss.div(state.sliederLever * 100).div(100)
      }
      const response = await tradeRouterContract.increasePosition(tradePool, colAmount, lss, istoken0)
      setAttemptingTxn(false)
      addTransaction(response, {
        type: TransactionType.DEPOSIT_LIQUIDITY_STAKING,
        token0Address: pool.token0.address,
        token1Address: pool.token1.address,
      })
      setTxHash(response.hash)
    } catch (error) {
      console.log('increasePosition', error)
      setAttemptingTxn(false)
    }
  }, [account, addTransaction, parsedAmounts, pool, sliederLever, tradePool, tradePoolContract, tradeRouterContract])

  const { DynamicApprove, isApproved, approveError } = useDynamicApprove(
    [parsedAmounts[Field.INPUT]],
    tradeRouterContract?.address
  )
  const formattedPrice = useMemo(() => {
    if (!tokenRatio) return '0'
    const showInverteds = curr === 1
    try {
      return formatPrice(showInverteds ? tokenRatio : tokenRatio.invert(), NumberType.TokenTx)
    } catch {
      return '0'
    }
  }, [tokenRatio, curr])
  return (
    <>
      <RightWrapper>
        <Tab tabList={tabList} curr={curr} setCurr={navTableChange} />
        <Row mt="8px">
          <SwapDetails price={tokenRatio} showInverted={curr} />
        </Row>
        <InputSwapSection>
          <SwapCurrencyInputPanel
            label={<Trans>From</Trans>}
            disabled={disableTokenInputs}
            value={formattedAmounts[Field.INPUT]}
            showMaxButton={showMaxButton}
            currency={currencies[Field.INPUT] ?? null}
            onUserInput={handleTypeInput}
            onMaxTab={handleTypeInput}
            onMax={handleMaxInput}
            fiatValue={showFiatValueInput ? fiatValueInput : undefined}
            // onCurrencySelect={handleInputSelect}
            otherCurrency={currencies[Field.OUTPUT]}
            showCommonBases
            id="trade-input"
            loading={false}
            showMaxTab={true}
            showSlider={true}
          />
        </InputSwapSection>
        <OutputSwapSection>
          <SwapCurrencyInputPanel
            value={formattedAmounts[Field.OUTPUT]}
            disabled={true}
            onUserInput={handleTypeOutput}
            label={independentField === Field.INPUT && !showWrap ? <Trans>To (at least)</Trans> : <Trans>To</Trans>}
            showMaxButton={false}
            hideBalance={false}
            fiatValue={showFiatValueOutput ? fiatValueOutput : undefined}
            priceImpact={stablecoinPriceImpact}
            currency={currencies[Field.OUTPUT] ?? null}
            // onCurrencySelect={handleOutputSelect}
            otherCurrency={currencies[Field.INPUT]}
            showCommonBases
            id="trade-output"
            loading={false}
          />
        </OutputSwapSection>
        <RowBetween mt="8px">
          <ThemedText.TextSecondary fontSize={14}>Profits In</ThemedText.TextSecondary>
          <Row width="auto" align="center" className="pointer">
            <ThemedText.TextPrimary fontWeight={700} mr="8px">
              ETH
            </ThemedText.TextPrimary>
            <DownIcon />
          </Row>
        </RowBetween>
        <RowBetween mt="8px">
          <ThemedText.TextSecondary fontSize={14}>Leverage</ThemedText.TextSecondary>
          <ThemedText.TextPrimary fontWeight={700}>{sliederLever}x</ThemedText.TextPrimary>
        </RowBetween>
        <RowBetween mt="8px">
          <ThemedText.TextSecondary fontSize={14}>Entry Price</ThemedText.TextSecondary>
          <ThemedText.TextPrimary fontWeight={700}>{formattedPrice}</ThemedText.TextPrimary>
        </RowBetween>
        <RowBetween mt="8px">
          <ThemedText.TextSecondary fontSize={14}>Liq. Price</ThemedText.TextSecondary>
          <ThemedText.TextPrimary fontWeight={700}>{0}</ThemedText.TextPrimary>
        </RowBetween>
        <RowBetween mt="8px">
          <ThemedText.TextSecondary fontSize={14}>Fees</ThemedText.TextSecondary>
          <ThemedText.TextPrimary fontWeight={700}>$0</ThemedText.TextPrimary>
        </RowBetween>
        <RowBetween mt="8px">
          <ThemedText.TextSecondary fontSize={14}>Slippage</ThemedText.TextSecondary>
          <Row width="auto" align="center" className="pointer">
            <ThemedText.TextPrimary fontWeight={700} mr="8px">
              {slippageTolerance}%
            </ThemedText.TextPrimary>
            <SettingsTab autoSlippage={autoSlippage} chainId={chainId} trade={undefined} />
          </Row>
        </RowBetween>

        <Row>
          <DynamicApprove />
        </Row>
        <Row mt="8px ">
          <ButtonConfirmed disabled={!isApproved} onClick={() => setShowConfirm(true)}>
            LONG
          </ButtonConfirmed>
        </Row>
      </RightWrapper>
      <TradeConfirmationModal
        inputCurrency={parsedAmounts[Field.INPUT]}
        outputCurrency={parsedAmounts[Field.OUTPUT]}
        isOpen={showConfirm}
        entryPrice={formattedPrice}
        onDismiss={handleDismissConfirmation}
        onConfirm={openPositon}
        attemptingTxn={attemptingTxn}
        txHash={txHash}
        pendingText={pendingText}
      />
      <ClosePositionModal isOpen={showClose} onDismiss={handleDismissClose} />
      <EditPositionModal isOpen={showEdit} onDismiss={handleDismissEdit} />
      {/* <CurrencySearchModal isOpen={showSearch} onDismiss={handleDismissSearch} /> */}
    </>
  )
}
