import { Trans } from '@lingui/macro'
import { Currency, CurrencyAmount } from '@uniswap/sdk-core'
import { ButtonConfirmed } from 'components/Button'
import Column, { AutoColumn } from 'components/Column'
import Expand from 'components/Expand'
import Row, { RowBetween } from 'components/Row'
import TransactionConfirmationModal from 'components/TransactionConfirmationModal'
import { ReactNode, useState } from 'react'
import styled from 'styled-components/macro'
import { ThemedText } from 'theme'

import { ReactComponent as CloseIcon } from '../../../assets/imgs/close.svg'
import { useMockPool } from '../Hooks'
import SwapSectionPreview from '../SwapSectionPreview'

interface ConfirmModalProps {
  isOpen: boolean
  inputCurrency?: CurrencyAmount<Currency>
  outputCurrency?: CurrencyAmount<Currency>
  entryPrice?: string
  onDismiss: () => void
  onConfirm: () => void
  txHash?: string
  pendingText?: string
  attemptingTxn: boolean
}

const Wrapper = styled.div`
  background-color: ${({ theme }) => theme.backgroundSurface};
  border-radius: 20px;
  outline: 1px solid ${({ theme }) => theme.backgroundOutline};
  width: 100%;
  padding: 16px 8px;
`

const FeesExpandBox = styled(Column)`
  width: 100%;
  padding: 0 12px;
  background-color: ${({ theme }) => theme.backgroundInteractive};
`

function ConfirmModalContent({
  title,
  onDismiss,
  modalContent,
}: {
  title: ReactNode
  onDismiss: () => void
  modalContent: () => ReactNode
}) {
  return (
    <Wrapper>
      <AutoColumn gap="sm">
        <Row>
          <Row justify="center" marginLeft="24px">
            <ThemedText.SubHeader>{title}</ThemedText.SubHeader>
          </Row>
          <CloseIcon onClick={onDismiss} data-testid="confirmation-close-icon" style={{ marginRight: '8px' }} />
        </Row>
      </AutoColumn>
      {modalContent()}
    </Wrapper>
  )
}
type ModalContentProps = {
  inputCurrency?: CurrencyAmount<Currency>
  outputCurrency?: CurrencyAmount<Currency>
  entryPrice?: string
  confirm?: () => void
}
function ModalContent({ inputCurrency, outputCurrency, entryPrice, confirm }: ModalContentProps) {
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const { slippageTolerance } = useMockPool()
  return (
    <Column gap="sm">
      <SwapSectionPreview inputCurrency={inputCurrency} outputCurrency={outputCurrency} />
      <RowBetween>
        <ThemedText.TextSecondary fontSize={14}>Profits In</ThemedText.TextSecondary>
        <ThemedText.TextPrimary fontWeight={500}>$0.13</ThemedText.TextPrimary>
      </RowBetween>
      <RowBetween>
        <ThemedText.TextSecondary fontSize={14}>Liq. Price</ThemedText.TextSecondary>
        <ThemedText.TextPrimary fontWeight={500}>-0.005%</ThemedText.TextPrimary>
      </RowBetween>
      <RowBetween>
        <ThemedText.TextSecondary fontSize={14}>Entry Price</ThemedText.TextSecondary>
        <ThemedText.TextPrimary fontWeight={500}>{entryPrice} </ThemedText.TextPrimary>
      </RowBetween>
      <Expand
        isOpen={isOpen}
        onToggle={() => setIsOpen(!isOpen)}
        testId="transaction-deadline-settings"
        header={<ThemedText.TextSecondary fontSize={14}>Fees</ThemedText.TextSecondary>}
        button={
          <ThemedText.TextPrimary fontWeight={500} mr="8px">
            4.00%
          </ThemedText.TextPrimary>
        }
      >
        <FeesExpandBox width="100%">
          <RowBetween height={32}>
            <ThemedText.TextSecondary fontSize={14}>Borrow Fee</ThemedText.TextSecondary>
            <ThemedText.TextSecondary fontSize={14} fontWeight={500}>
              0.17% / 1h
            </ThemedText.TextSecondary>
          </RowBetween>
          <RowBetween height={32}>
            <ThemedText.TextSecondary fontSize={14}>Execution Fee</ThemedText.TextSecondary>
            <ThemedText.TextSecondary fontSize={14} fontWeight={500}>
              0.17%
            </ThemedText.TextSecondary>
          </RowBetween>
        </FeesExpandBox>
      </Expand>
      <RowBetween>
        <ThemedText.TextSecondary fontSize={14}>Spread</ThemedText.TextSecondary>
        <ThemedText.TextPrimary fontWeight={500}>0.17%</ThemedText.TextPrimary>
      </RowBetween>
      <RowBetween>
        <ThemedText.TextSecondary fontSize={14}>Allowed Slippage</ThemedText.TextSecondary>
        <ThemedText.TextPrimary fontWeight={500}>{slippageTolerance}%</ThemedText.TextPrimary>
      </RowBetween>
      <ButtonConfirmed data-testid="confirm-trade-button" id="confirm-trade-button" mt="8px" onClick={confirm}>
        <ThemedText.HeadlineSmall color="accentTextLightPrimary">
          <Trans>Confirm</Trans>
        </ThemedText.HeadlineSmall>
      </ButtonConfirmed>
    </Column>
  )
}

export default function TradeConfirmModal({
  isOpen,
  onDismiss,
  inputCurrency,
  outputCurrency,
  entryPrice,
  onConfirm,
  pendingText,
  txHash,
  attemptingTxn,
}: ConfirmModalProps) {
  const modalContent = () => {
    return (
      <ModalContent
        inputCurrency={inputCurrency}
        outputCurrency={outputCurrency}
        entryPrice={entryPrice}
        confirm={onConfirm}
      />
    )
  }
  return (
    <TransactionConfirmationModal
      isOpen={isOpen}
      onDismiss={onDismiss}
      attemptingTxn={attemptingTxn}
      hash={txHash}
      width="400px"
      reviewContent={() => (
        <ConfirmModalContent
          title={<Trans>Open Position Preview</Trans>}
          onDismiss={onDismiss}
          modalContent={modalContent}
        />
      )}
      pendingText={pendingText}
    />
    // <Modal isOpen={isOpen} $scrollOverlay={true} onDismiss={onDismiss} maxHeight={90}>
    //   <ConfirmModalContent
    //     title={<Trans>Open Position Preview</Trans>}
    //     onDismiss={onModalDismiss}
    //     modalContent={modalContent}
    //   />
    // </Modal>
  )
}
