import { Interface } from '@ethersproject/abi'
import SWAPMINGABI from 'abis/swapMinning.json'
import VOTER from 'abis/voter.json'
import votingRewardABI from 'abis/votingReward.json'
import { useActiveChainId } from 'connection/useActiveChainId'
import { useVoter } from 'hooks/useContract'
import { AllPools, PoolStruct } from 'lib/AllPools'
import { NEVER_RELOAD, useMultipleContractSingleData, useSingleCallResult } from 'lib/hooks/multicall'
import { useMultipleContractMultipleData } from 'lib/hooks/useMultipleContractMultipleData'
import { useGetLockTokenIds } from 'pages/Lock/hooks'
import { useMemo } from 'react'
import { BN, toFromBN } from 'utils/bn'
import { CurrRound } from 'utils/CurrRound'

const VOTINGREWARD_INTERFACE = new Interface(votingRewardABI.abi)
const VOTER_INTERFACE = new Interface(VOTER.abi)
const SWAPMING_INTERFACE = new Interface(SWAPMINGABI.abi)
export function useFetchVoter() {
  const votingRewardAddressList = AllPools.filter((pool) => {
    return pool?.votingRewardAddress
  }).map((item) => {
    return item.votingRewardAddress
  })

  const voteLpAddr = AllPools.map((item) => [[item.poolAddress]])

  const voteContract = useVoter()

  const rewardsLists = useMultipleContractSingleData(
    votingRewardAddressList,
    VOTINGREWARD_INTERFACE,
    'getrewardsList',
    undefined,
    NEVER_RELOAD
  )

  const tokenIds = useGetLockTokenIds()

  const PoolAddr = AllPools.map((x) => x.poolAddress)

  const { poolAddr, pamsr } = useMemo(() => {
    if (!tokenIds || tokenIds.length == 0 || !voteContract) return { poolAddr: [], pamsr: [] }
    return PoolAddr.reduce(
      (pre: any, curr) => {
        tokenIds.map((x) => {
          pre.poolAddr.push(voteContract?.address)
          pre.pamsr.push([[x, curr]])
        })

        return pre
      },
      { poolAddr: [], pamsr: [] }
    )
  }, [PoolAddr, tokenIds, voteContract])

  const votes = useMultipleContractMultipleData(poolAddr, VOTER_INTERFACE, 'votes', pamsr)

  const stakeTokenIdPostion = useMemo(() => {
    return pamsr.reduce((pre: any, curr: any, index: any) => {
      const data = curr[0]

      const vote = votes[index]

      if (!vote) return pre

      const { result } = vote[0]
      if (!result) return pre
      if (result[0].gt(0)) {
        pre[data[1]] = {
          tokenid: data[0],
        }
      }
      return pre
    }, {})
  }, [pamsr, votes])

  const { result: totalWeights } = useSingleCallResult(voteContract, 'totalWeight')

  const voteAddr = useMemo(() => {
    if (!voteContract) return []
    return new Array(votingRewardAddressList.length).fill(voteContract.address)
  }, [votingRewardAddressList, voteContract])

  // weights
  const poolWeights = useMultipleContractMultipleData(voteAddr, VOTER_INTERFACE, 'weights', voteLpAddr)

  // voteContract?.votes

  const queryAll = useMemo(() => {
    return AllPools.reduce<{ voteList: PoolStruct[]; voteMAP: { [key: string]: PoolStruct } }>(
      (pre: any, cur, index) => {
        const { result: rewardsList } = rewardsLists[index]
        const poolWeightarr = poolWeights[index]
        if (!rewardsList || !poolWeightarr || !totalWeights) return pre
        const { result: poolWeight } = poolWeightarr[0]

        if (!poolWeight) return pre

        const tokenid = stakeTokenIdPostion[cur.poolAddress]

        const totalWeight = BN(totalWeights[0].toString())
        const isZero = totalWeight.lte(0)
        const poolWeightRatio = isZero ? BN(0) : BN(poolWeight.toString()).div(totalWeight).times(100)
        const arrList = {
          rewardsList: rewardsList[0],
          poolWeightAmount: toFromBN(poolWeight[0]),
          poolWeightRatio,
          tokenid: tokenid?.tokenid.toString(),
        }
        pre.voteList.push({ ...arrList, ...cur })
        pre.voteMAP[cur.poolAddress] = {
          ...cur,
          ...arrList,
        }
        return pre
      },
      { voteList: [], voteMAP: {} }
    )
  }, [rewardsLists, poolWeights, totalWeights, stakeTokenIdPostion])

  return queryAll
}

export function useFetchSwap() {
  const { account } = useActiveChainId()

  const swapMinningAddr = AllPools.map((x) => x.swapMinningAddress)

  const currnet = CurrRound()

  const totalVolumes = useMultipleContractSingleData(swapMinningAddr, SWAPMING_INTERFACE, 'totalSupply', [currnet])

  const myVolumes = useMultipleContractSingleData(swapMinningAddr, SWAPMING_INTERFACE, 'balanceOf', [account, currnet])

  const rewardsLists = useMultipleContractSingleData(
    swapMinningAddr,
    SWAPMING_INTERFACE,
    'getRewardList',
    undefined,
    NEVER_RELOAD
  )

  const totalVolumeLoading = totalVolumes.some(({ loading }) => loading)
  const MyVolumeLoading = myVolumes.some(({ loading }) => loading)

  const queryAll = useMemo(() => {
    if (totalVolumeLoading || MyVolumeLoading) return
    return AllPools.reduce<PoolStruct[]>((pre: any, curr, index) => {
      const { result: totalVolume } = totalVolumes[index]
      const { result: myVolume } = myVolumes[index]
      const { result: rewardsList } = rewardsLists[index]
      if (!totalVolume || !myVolume || !rewardsList) return pre
      const arr = {
        totalVolume,
        myVolume,
        rewardsList: rewardsList[0],
      }
      pre.push({ ...arr, ...curr })
      return pre
    }, [])
  }, [MyVolumeLoading, myVolumes, rewardsLists, totalVolumeLoading, totalVolumes])

  return queryAll
}
