import { Trans } from '@lingui/macro'
import { TableDataContainer } from 'components/Table'
import { MAX_WIDTH_MEDIA_BREAKPOINT } from 'components/Tokens/constants'
import { PAGE_SIZE } from 'graphql/data/TopTokens'
import { validateUrlChainParam } from 'graphql/data/util'
import { ReactNode } from 'react'
import { AlertTriangle } from 'react-feather'
import { useParams } from 'react-router-dom'
import styled from 'styled-components/macro'

import { HeaderRow, LoadedRow, LoadingRow } from './TableRow'

const GridContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: ${MAX_WIDTH_MEDIA_BREAKPOINT};
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  align-items: center;
`

const NoTokenDisplay = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  height: 60px;
  color: ${({ theme }) => theme.textSecondary};
  font-size: 16px;
  font-weight: 500;
  align-items: center;
  padding: 0px 28px;
  gap: 8px;
`

function NoTokensState({ message }: { message: ReactNode }) {
  return (
    <GridContainer>
      <HeaderRow />
      <NoTokenDisplay>{message}</NoTokenDisplay>
    </GridContainer>
  )
}

const LoadingRows = ({ rowCount }: { rowCount: number }) => (
  <>
    {Array(rowCount)
      .fill(null)
      .map((_, index) => {
        return <LoadingRow key={index} first={index === 0} last={index === rowCount - 1} />
      })}
  </>
)

function LoadingTokenTable({ rowCount = PAGE_SIZE }: { rowCount?: number }) {
  return (
    <GridContainer>
      <HeaderRow />
      <TableDataContainer>
        <LoadingRows rowCount={rowCount} />
      </TableDataContainer>
    </GridContainer>
  )
}

export default function PositionTable({ positions, closePosition }: any) {
  const chainName = validateUrlChainParam(useParams<{ chainName?: string }>().chainName)

  const loadingVotes = true
  const voteList = positions

  // const voteList = [
  //   { token: 'ROX-ETH', voteAmount: 20177.06, votePower: 20177.06, bribes: '20177.06' },
  //   { token: 'ROX-ETH', voteAmount: 20177.06, votePower: 20177.06, bribes: '20177.06' },
  //   { token: 'ROX-ETH', voteAmount: 20177.06, votePower: 20177.06, bribes: '20177.06' },
  // ]
  /* loading and error state */
  if (loadingVotes && !voteList) {
    return <LoadingTokenTable rowCount={4} />
  } else if (!voteList) {
    return (
      <NoTokensState
        message={
          <>
            <AlertTriangle size={4} />
            <Trans>An error occurred loading voteList. Please try again.</Trans>
          </>
        }
      />
    )
  } else if (voteList?.length === 0) {
    return <NoTokensState message={<Trans>No voteList found</Trans>} />
  } else {
    return (
      <>
        <HeaderRow />
        <TableDataContainer>
          {voteList?.map((vote: any, index: any) => (
            <LoadedRow
              closePosition={closePosition}
              key={index}
              voteListIndex={index}
              voteListLength={voteList.length}
              vote={vote}
            />
          ))}
        </TableDataContainer>
      </>
    )
  }
}
