import { ChainId, SUPPORTED_CHAINS, SupportedChainsType } from './chains'

type AddressMap = { [chainId: number]: string }

type ChainAddresses = {
  factoryAddress: string
  multicallAddress: string
  quoterAddress: string
  v3MigratorAddress?: string
  nonfungiblePositionManagerAddress?: string
  tickLensAddress?: string
  swapRouterAddress?: string
  v1MixedRouteQuoterAddress?: string
  lockAddress?: string
  tradeRouter?: string
  masterChefV3Address?: string
  voterAddress?: string
  rougexTokenAddress?: string
}

const DEFAULT_NETWORKS = [ChainId.ARBITRUM_ONE]

function constructSameAddressMap(address: string, additionalNetworks: ChainId[] = []): AddressMap {
  return DEFAULT_NETWORKS.concat(additionalNetworks).reduce<AddressMap>((memo, chainId) => {
    memo[chainId] = address
    return memo
  }, {})
}

const ARBITRUM_ONE_ADDRESSES: ChainAddresses = {
  factoryAddress: '0xb09DCE6198121dA7FD387ef04c97e7851B04A952',
  tradeRouter: '0x2738436c3286F49D288B6dE3E6311aeAd5e0f437',
  nonfungiblePositionManagerAddress: '0xC3Fe626BdCD8dc52e4b57C04Ef66539DDCDb0483',
  swapRouterAddress: '0x9b088dBA49f2cfd197F524A54a8AD7786082D948',
  quoterAddress: '0xe331fb0f80Cd6af0d5E749d0b044A9F55f6636d5',
  tickLensAddress: '0x2030f789eC8be53B84F86C55B586be60D454dD18',

  v3MigratorAddress: '0xA815919D2584Ac3F76ea9CB62E6Fd40a43BCe0C3',
  lockAddress: '0x07002A05EefcF320E5EbA40a075306BC5c02Afd4',
  multicallAddress: '0x8260CB40247290317a4c062F3542622367F206Ee',
}

// eslint-disable-next-line multiline-comment-style
/*
rougeX:  0xc769Ae33D471220df9bb037D6c9814B170FD7a75
veArtProxy:  0x8a34E5B50Beb80139A1CcA47d8119f4A16790330
votingEscrow:  0xB07F5049c233D55a145F86faecfaA2D237D20166
rewardsDistributor:  0x3170a703A0d30A7Da564f2FE326e74205dD72FC8

factoryAddress : 0xf6489122f587c096EFFC13a996cE0C7801f2aBd2
tradeRouter: 0x3B45DBb5cCEA788e1a63ea8cF8D0938D03AD170d
nonfungiblePositionManagerAddress : 0x919667526EFEE917561E14471Ca83C0F70eCe936
swapRouterAddress : 0x083532e09056Df6bd4c50189e49e76298E670c7F
quoterAddress : 0x7444d82980E8de6FA6E798FCCC6747bE27B8B140
tickLensAddress : 0xE4829C40C641A78c0470AF663d927cFc6351Fe3a
*/
// arbitrum goerli v3 addresses
const ARBITRUM_GOERLI_ADDRESSES: ChainAddresses = {
  factoryAddress: '0xf6489122f587c096EFFC13a996cE0C7801f2aBd2',
  tradeRouter: '0x3B45DBb5cCEA788e1a63ea8cF8D0938D03AD170d',
  nonfungiblePositionManagerAddress: '0x919667526EFEE917561E14471Ca83C0F70eCe936',
  swapRouterAddress: '0x083532e09056Df6bd4c50189e49e76298E670c7F',
  quoterAddress: '0x7444d82980E8de6FA6E798FCCC6747bE27B8B140',
  tickLensAddress: '0xE4829C40C641A78c0470AF663d927cFc6351Fe3a',

  v3MigratorAddress: '0x15858700D4Ca698d7d10a75cbcc15ab2d4E0a6Aa',
  lockAddress: '0xB07F5049c233D55a145F86faecfaA2D237D20166',
  multicallAddress: '0x8260CB40247290317a4c062F3542622367F206Ee',
  masterChefV3Address: '0xF1262bD948E12FE563e3091dafA046B1346e1A1f',
  voterAddress: '0x4e8681dF8DEBeF7B6A6Ea5812BDf01ec8B048813',
  rougexTokenAddress: '0xc769Ae33D471220df9bb037D6c9814B170FD7a75',
}

const CHAIN_TO_ADDRESSES_MAP: Record<SupportedChainsType, ChainAddresses> = {
  [ChainId.ARBITRUM_ONE]: ARBITRUM_ONE_ADDRESSES,
  [ChainId.ARBITRUM_GOERLI]: ARBITRUM_GOERLI_ADDRESSES,
}

/* V3 Contract Addresses */
export const V3_CORE_FACTORY_ADDRESSES: AddressMap = {
  ...SUPPORTED_CHAINS.reduce<AddressMap>((memo, chainId) => {
    memo[chainId] = CHAIN_TO_ADDRESSES_MAP[chainId].factoryAddress
    return memo
  }, {}),
}

export const MULTICALL_ADDRESSES: AddressMap = {
  ...SUPPORTED_CHAINS.reduce<AddressMap>((memo, chainId) => {
    memo[chainId] = CHAIN_TO_ADDRESSES_MAP[chainId].multicallAddress
    return memo
  }, {}),
}

export const ARGENT_WALLET_DETECTOR_ADDRESS: AddressMap = {}

export const QUOTER_ADDRESSES: AddressMap = {
  ...SUPPORTED_CHAINS.reduce<AddressMap>((memo, chainId) => {
    memo[chainId] = CHAIN_TO_ADDRESSES_MAP[chainId].quoterAddress
    return memo
  }, {}),
}

export const NONFUNGIBLE_POSITION_MANAGER_ADDRESSES: AddressMap = {
  ...SUPPORTED_CHAINS.reduce<AddressMap>((memo, chainId) => {
    const nonfungiblePositionManagerAddress = CHAIN_TO_ADDRESSES_MAP[chainId].nonfungiblePositionManagerAddress
    if (nonfungiblePositionManagerAddress) {
      memo[chainId] = nonfungiblePositionManagerAddress
    }
    return memo
  }, {}),
}

export const ENS_REGISTRAR_ADDRESSES: AddressMap = {
  ...constructSameAddressMap('0x00000000000C2E074eC69A0dFb2997BA6C7d2e1e'),
}

export const TICK_LENS_ADDRESSES: AddressMap = {
  ...SUPPORTED_CHAINS.reduce<AddressMap>((memo, chainId) => {
    const tickLensAddress = CHAIN_TO_ADDRESSES_MAP[chainId].tickLensAddress
    if (tickLensAddress) {
      memo[chainId] = tickLensAddress
    }
    return memo
  }, {}),
}
export const SWAP_ROUTER_ADDRESSES: AddressMap = {
  ...SUPPORTED_CHAINS.reduce<AddressMap>((memo, chainId) => {
    const swapRouter02Address = CHAIN_TO_ADDRESSES_MAP[chainId].swapRouterAddress
    if (swapRouter02Address) {
      memo[chainId] = swapRouter02Address
    }
    return memo
  }, {}),
}
export const TRADE_ROUTER_ADDRESSES: AddressMap = {
  ...SUPPORTED_CHAINS.reduce<AddressMap>((memo, chainId) => {
    const tradeRouter = CHAIN_TO_ADDRESSES_MAP[chainId].tradeRouter
    if (tradeRouter) {
      memo[chainId] = tradeRouter
    }
    return memo
  }, {}),
}

export const LOCK_ADDRESSES: AddressMap = {
  ...SUPPORTED_CHAINS.reduce<AddressMap>((memo, chainId) => {
    const lockAddress = CHAIN_TO_ADDRESSES_MAP[chainId].lockAddress
    if (lockAddress) {
      memo[chainId] = lockAddress
    }
    return memo
  }, {}),
}

export const VOTER_ADDRESSES: AddressMap = {
  ...SUPPORTED_CHAINS.reduce<AddressMap>((memo, chainId) => {
    const voterAddress = CHAIN_TO_ADDRESSES_MAP[chainId].voterAddress
    if (voterAddress) {
      memo[chainId] = voterAddress
    }
    return memo
  }, {}),
}

export const MasterchefV3_ADDRESSES: AddressMap = {
  ...SUPPORTED_CHAINS.reduce<AddressMap>((memo, chainId) => {
    const masterChefV3Address = CHAIN_TO_ADDRESSES_MAP[chainId].masterChefV3Address
    if (masterChefV3Address) {
      memo[chainId] = masterChefV3Address
    }
    return memo
  }, {}),
}

export const ROUGEX_ADDRESSES: AddressMap = {
  ...SUPPORTED_CHAINS.reduce<AddressMap>((memo, chainId) => {
    const rougexTokenAddress = CHAIN_TO_ADDRESSES_MAP[chainId].rougexTokenAddress
    if (rougexTokenAddress) {
      memo[chainId] = rougexTokenAddress
    }
    return memo
  }, {}),
}
