import { Trans } from '@lingui/macro'
import { AddButton, ClaimButton } from 'components/Button'
import { IconLoadingBubble, LoadingBubble, MediumLoadingBubble } from 'components/Loading'
import Row from 'components/Row'
import { Cell, StyledTableRow } from 'components/Table'
import {
  LARGE_MEDIA_BREAKPOINT,
  MAX_WIDTH_MEDIA_BREAKPOINT,
  MEDIUM_MEDIA_BREAKPOINT,
  SMALL_MEDIA_BREAKPOINT,
} from 'components/Tokens/constants'
import Tooltip from 'components/Tooltip'
import { PoolStruct } from 'lib/AllPools'
import { CSSProperties, ForwardedRef, forwardRef, ReactNode, useState } from 'react'
import { Box } from 'rebass'
import styled, { css } from 'styled-components/macro'
import { ThemedText } from 'theme'

import { ReactComponent as NftIcon } from '../../assets/imgs/nft.svg'
import FarmPoolModal from './FarmPoolModal'
import { TokenSortMethod } from './state'

const StyledLiquidityRow = styled(StyledTableRow)<{
  first?: boolean
  last?: boolean
  $loading?: boolean
}>`
  align-items: stretch;
  grid-template-columns: 1.8fr 1.5fr 1fr 1.5fr 2.5fr 1.5fr;
  max-width: ${MAX_WIDTH_MEDIA_BREAKPOINT};
  min-width: 390px;
  padding: 0;
  margin: 0;
  ${({ first, last }) => css`
    /* height: ${first || last ? '72px' : '64px'}; */
  `}
  transition: ${({
    theme: {
      transition: { duration, timing },
    },
  }) => css`background-color ${duration.medium} ${timing.ease}`};
  transition-duration: ${({ theme }) => theme.transition.duration.fast};

  &:hover {
    ${({ $loading, theme }) =>
      !$loading &&
      css`
        background-color: ${theme.hoverDefault};
      `}
    ${({ last }) =>
      last &&
      css`
        border-radius: 0px 0px 8px 8px;
      `}
  }

  .bg {
    ${({ theme }) =>
      css`
        background-color: ${theme.hoverDefault};
      `}
  }

  @media only screen and (max-width: ${MAX_WIDTH_MEDIA_BREAKPOINT}) {
    grid-template-columns: 1.8fr 1.5fr 1fr 1.5fr 2.5fr 1.5fr;
  }

  @media only screen and (max-width: ${LARGE_MEDIA_BREAKPOINT}) {
    grid-template-columns: 1.8fr 1.5fr 1fr 1.5fr 2.5fr 1.5fr;
  }

  @media only screen and (max-width: ${MEDIUM_MEDIA_BREAKPOINT}) {
    grid-template-columns: 1.8fr 1.5fr 1fr 1.5fr 2.5fr 1.5fr;
  }

  @media only screen and (max-width: ${SMALL_MEDIA_BREAKPOINT}) {
    grid-template-columns: 1.8fr 1.5fr 1fr 1.5fr 2.5fr 1.5fr;
    min-width: unset;
    border-bottom: 0.5px solid ${({ theme }) => theme.backgroundModule};

    :last-of-type {
      border-bottom: none;
    }
  }
`

const StyledHeaderRow = styled(StyledLiquidityRow)`
  justify-content: center;
  color: ${({ theme }) => theme.textSecondary};
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;

  &:hover {
    background-color: transparent;
  }

  @media only screen and (max-width: ${SMALL_MEDIA_BREAKPOINT}) {
    justify-content: space-between;
  }
`

const PoolCell = styled(Cell)`
  justify-content: flex-start;
  padding: 16px 8px 32px;
  min-width: 240px;
`

const TextLeftCell = styled(Cell)`
  justify-content: flex-start;
  padding: 16px 8px 32px;
  @media only screen and (max-width: ${SMALL_MEDIA_BREAKPOINT}) {
    display: none;
  }
`
const TextRightCell = styled(Cell)`
  padding: 16px 8px 32px;
  @media only screen and (max-width: ${MEDIUM_MEDIA_BREAKPOINT}) {
    display: none;
  }
`

const BalanceRow = styled(Row)`
  cursor: pointer;
  border-bottom: 1px dashed ${({ theme }) => theme.textSecondary};
`

const TooltipBox = styled(Box)`
  .tooltipItem {
    padding: 8px 0;
    align-items: flex-start;
    &:not(:last-child) {
      border-bottom: 1px solid ${({ theme }) => theme.swapSectionBorder};
    }

    .text {
      word-break: initial;
    }

    .balance {
      align-items: flex-end;
    }
  }
`

/* Token Row: skeleton row component */
function LiquidityRow({
  header,
  pools,
  tvl,
  apr,
  rewards,
  wallet_staked,
  earn,
  ...rest
}: {
  first?: boolean
  header: boolean
  $loading?: boolean
  rewards: ReactNode
  tvl: ReactNode
  apr: ReactNode
  pools: ReactNode
  wallet_staked: ReactNode
  earn: ReactNode
  last?: boolean
  style?: CSSProperties
}) {
  const rowCells = (
    <>
      <PoolCell data-testid="pools-cell">{pools}</PoolCell>
      <TextRightCell data-testid="tvl-cell">{tvl}</TextRightCell>
      <TextLeftCell data-testid="apr-cell">{apr}</TextLeftCell>
      <TextRightCell data-testid="rewards-cell">{rewards}</TextRightCell>
      <TextRightCell data-testid="wallet_staked-cell" className="bg">
        {wallet_staked}
      </TextRightCell>
      <TextRightCell data-testid="my-liquidity-cell" className="bg">
        {earn}
      </TextRightCell>
    </>
  )
  if (header) return <StyledHeaderRow data-testid="liquidity-header-row">{rowCells}</StyledHeaderRow>
  return <StyledLiquidityRow {...rest}>{rowCells}</StyledLiquidityRow>
}

/* Header Row: top header row component for table */
export function HeaderRow() {
  return (
    <LiquidityRow
      header={true}
      pools={<Trans>{TokenSortMethod.POOLS}</Trans>}
      tvl={
        <Row justify="end">
          <Trans>{TokenSortMethod.TVL}</Trans>
        </Row>
      }
      apr={
        <Row>
          <Trans>{TokenSortMethod.APR}</Trans>
        </Row>
      }
      rewards={
        <Row justify="end">
          <Trans>{TokenSortMethod.REWARDS}</Trans>
        </Row>
      }
      wallet_staked={
        <Row justify="end">
          <Trans>{TokenSortMethod.WALLET_STAKED}</Trans>
        </Row>
      }
      earn={
        <Row justify="end">
          <Trans>{TokenSortMethod.EARN}</Trans>
        </Row>
      }
    />
  )
}
/* Loading State: row component with loading bubbles */
export function LoadingRow(props: { first?: boolean; last?: boolean }) {
  return (
    <LiquidityRow
      header={false}
      $loading
      pools={
        <Row>
          <IconLoadingBubble width="48px" height="48px" />
          <MediumLoadingBubble />
        </Row>
      }
      tvl={
        <Row flexDirection="column" align="end" gap="8px">
          <MediumLoadingBubble />
          <MediumLoadingBubble />
          <MediumLoadingBubble />
        </Row>
      }
      apr={
        <Row>
          <LoadingBubble />
        </Row>
      }
      rewards={
        <Row flexDirection="column" align="end" gap="8px">
          <MediumLoadingBubble />
          <MediumLoadingBubble />
          <MediumLoadingBubble />
        </Row>
      }
      wallet_staked={
        <Row flexDirection="column" align="end" gap="8px">
          <MediumLoadingBubble />
          <AddButton>+/—</AddButton>
        </Row>
      }
      earn={
        <Row flexDirection="column" align="end" gap="8px">
          <MediumLoadingBubble />
          <MediumLoadingBubble />
          <ClaimButton>claim</ClaimButton>
        </Row>
      }
      {...props}
    />
  )
}

type LoadedRowProps = {
  liquidityListIndex: number
  liquidityListLength: number
  liquidity: PoolStruct
}

/* Loaded State: row component with token information */
export const LoadedRow = forwardRef((props: LoadedRowProps, ref: ForwardedRef<HTMLDivElement>) => {
  const { liquidityListIndex, liquidityListLength, liquidity } = props
  const [showHover, setShowHover] = useState(false)

  const [showFarmPool, setShowFarmPool] = useState(false)
  const handleDismissCreate = () => {
    setShowFarmPool(false)
  }

  return (
    <>
      <div ref={ref} data-testid={`liquidity-table-row-${liquidity.poolname}`}>
        <LiquidityRow
          header={false}
          pools={
            <Row>
              <NftIcon width={48} height={48} />
              <ThemedText.TextPrimary fontWeight={700} fontSize={16} ml="16px">
                #{liquidity.poolname}
              </ThemedText.TextPrimary>
            </Row>
          }
          tvl={
            <Row flexDirection="column" align="end">
              <ThemedText.TextPrimary fontWeight={700}>{123}</ThemedText.TextPrimary>
              <ThemedText.TextSecondary fontSize={14}>10,203,403 ROX</ThemedText.TextSecondary>
              <ThemedText.TextSecondary fontSize={14}>18,283 ETH</ThemedText.TextSecondary>
            </Row>
          }
          apr={
            <Row>
              <ThemedText.TextPrimary fontWeight={700}>145.49%</ThemedText.TextPrimary>
            </Row>
          }
          rewards={
            <Row flexDirection="column" align="end">
              <ThemedText.TextPrimary fontWeight={700}>321</ThemedText.TextPrimary>
              <ThemedText.TextSecondary fontSize={14}>10,203,403 ROX</ThemedText.TextSecondary>
              <ThemedText.TextSecondary fontSize={14}>18,283 ETH</ThemedText.TextSecondary>
            </Row>
          }
          wallet_staked={
            <Row flexDirection="column" align="end">
              <Tooltip
                show={showHover}
                text={
                  <TooltipBox>
                    <Row justifyContent="space-between" alignItems="flex-start" className="tooltipItem">
                      <ThemedText.TextPrimary fontWeight={700} className="text">
                        <Trans>Wallet</Trans>
                      </ThemedText.TextPrimary>
                      <Row flexDirection="column" gap="8px" className="balance">
                        <ThemedText.TextSecondary fontSize={14}>10,203,403 ROX</ThemedText.TextSecondary>
                        <ThemedText.TextSecondary fontSize={14}>18,283 ETH</ThemedText.TextSecondary>
                      </Row>
                    </Row>
                    <Row justifyContent="space-between" alignItems="flex-start" className="tooltipItem">
                      <ThemedText.TextPrimary fontWeight={700} className="text">
                        <Trans>Staked</Trans>
                      </ThemedText.TextPrimary>
                      <Row flexDirection="column" gap="8px" className="balance">
                        <ThemedText.TextSecondary fontSize={14}>10,203,403 ROX</ThemedText.TextSecondary>
                        <ThemedText.TextSecondary fontSize={14}>18,283 ETH</ThemedText.TextSecondary>
                      </Row>
                    </Row>
                  </TooltipBox>
                }
              >
                <BalanceRow
                  onMouseEnter={() => setShowHover(true)}
                  onMouseLeave={() => setShowHover(false)}
                  className="balance_val"
                >
                  <ThemedText.BodySmall fontWeight={700} color="textTertiary">
                    0/
                  </ThemedText.BodySmall>
                  <ThemedText.TextPrimary fontWeight={700}>0</ThemedText.TextPrimary>
                </BalanceRow>
              </Tooltip>
              <Row mt="8px" justify="end">
                <AddButton onClick={() => setShowFarmPool(true)}>+/—</AddButton>
              </Row>
            </Row>
          }
          earn={
            <Row flexDirection="column" align="end">
              <ThemedText.TextRewards>10,203,403 ROX</ThemedText.TextRewards>
              <ThemedText.TextRewards>18,283 ETH</ThemedText.TextRewards>
              <Row mt="8px" justify="end">
                <ClaimButton>claim</ClaimButton>
              </Row>
            </Row>
          }
          first={liquidityListIndex === 0}
          last={liquidityListIndex === liquidityListLength - 1}
        />
      </div>
      <FarmPoolModal
        poolAddress={liquidity?.poolAddress}
        token0={liquidity.token0Address}
        token1={liquidity.token1Address}
        fee={liquidity.fee}
        isOpen={showFarmPool}
        onDismiss={handleDismissCreate}
      />
    </>
  )
})

LoadedRow.displayName = 'LoadedRow'
