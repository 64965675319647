import { AutoColumn } from 'components/Column'

import ChartInfo from './ChartInfo'
import { NavTabList } from './NavTabList'

export default function TradeLeftWrapper() {
  return (
    <AutoColumn gap="xmd" width="100%">
      <ChartInfo />
      <NavTabList />
    </AutoColumn>
  )
}
