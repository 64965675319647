import { Trans } from '@lingui/macro'
import { ManagerButton } from 'components/Button'
import { IconLoadingBubble, LoadingBubble, MediumLoadingBubble } from 'components/Loading'
import Row from 'components/Row'
import { Cell, StyledTableRow } from 'components/Table'
import {
  LARGE_MEDIA_BREAKPOINT,
  MAX_WIDTH_MEDIA_BREAKPOINT,
  MEDIUM_MEDIA_BREAKPOINT,
  SMALL_MEDIA_BREAKPOINT,
} from 'components/Tokens/constants'
import dayjs from 'dayjs'
import { CSSProperties, ForwardedRef, forwardRef, ReactNode, useState } from 'react'
import styled, { css } from 'styled-components/macro'
import { ThemedText } from 'theme'
import { formatCurrencyAmount } from 'utils/formatCurrencyAmount'

import { ReactComponent as NftIcon } from '../../assets/imgs/nft.svg'
import ManageModal from './LockModal/ManageModal'
import { TokenSortMethod } from './state'
import { LockStakeListProps } from './types'

const StyledLockRow = styled(StyledTableRow)<{
  first?: boolean
  last?: boolean
  $loading?: boolean
}>`
  grid-template-columns: 2fr 3fr 3fr 3fr 2fr;
  max-width: ${MAX_WIDTH_MEDIA_BREAKPOINT};
  min-width: 390px;
  ${({ first, last }) => css`
    /* height: ${first || last ? '72px' : '64px'}; */
  `}
  transition: ${({
    theme: {
      transition: { duration, timing },
    },
  }) => css`background-color ${duration.medium} ${timing.ease}`};
  transition-duration: ${({ theme }) => theme.transition.duration.fast};

  &:hover {
    ${({ $loading, theme }) =>
      !$loading &&
      css`
        background-color: ${theme.hoverDefault};
      `}
    ${({ last }) =>
      last &&
      css`
        border-radius: 0px 0px 8px 8px;
      `}
  }

  @media only screen and (max-width: ${MAX_WIDTH_MEDIA_BREAKPOINT}) {
    grid-template-columns: 2fr 3fr 3fr 3fr 2fr;
  }

  @media only screen and (max-width: ${LARGE_MEDIA_BREAKPOINT}) {
    grid-template-columns: 2fr 3fr 3fr 3fr 2fr;
  }

  @media only screen and (max-width: ${MEDIUM_MEDIA_BREAKPOINT}) {
    grid-template-columns: 2fr 3fr 3fr 3fr 2fr;
  }

  @media only screen and (max-width: ${SMALL_MEDIA_BREAKPOINT}) {
    grid-template-columns: 2fr 3fr 3fr 3fr 2fr;
    min-width: unset;
    border-bottom: 0.5px solid ${({ theme }) => theme.backgroundModule};

    :last-of-type {
      border-bottom: none;
    }
  }
`
const StyledHeaderRow = styled(StyledLockRow)`
  justify-content: center;
  color: ${({ theme }) => theme.textSecondary};
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  padding: 12px 0;

  &:hover {
    background-color: transparent;
  }

  @media only screen and (max-width: ${SMALL_MEDIA_BREAKPOINT}) {
    justify-content: space-between;
  }
`
const DataCell = styled(Cell)<{ sortable?: boolean }>`
  justify-content: flex-end;
  min-width: 80px;
  user-select: ${({ sortable }) => (sortable ? 'none' : 'unset')};
  transition: ${({
    theme: {
      transition: { duration, timing },
    },
  }) => css`background-color ${duration.medium} ${timing.ease}`};
`
const PoolCell = styled(Cell)`
  justify-content: flex-start;
  padding: 0px 8px;
  min-width: 240px;
  gap: 8px;
`
const AmountCell = styled(DataCell)`
  justify-content: flex-start;
  padding-right: 8px;
`
const VotePowerCell = styled(Cell)`
  justify-content: flex-start;
  padding-right: 8px;
  @media only screen and (max-width: ${SMALL_MEDIA_BREAKPOINT}) {
    display: none;
  }
`
const ExpiresCell = styled(Cell)`
  padding-right: 8px;
  @media only screen and (max-width: ${MEDIUM_MEDIA_BREAKPOINT}) {
    display: none;
  }
`
const EarnCell = styled(Cell)`
  padding-right: 8px;
  @media only screen and (max-width: ${LARGE_MEDIA_BREAKPOINT}) {
    display: none;
  }
`

/* Token Row: skeleton row component */
function LockRow({
  header,
  poolInfo,
  amount,
  votePower,
  expires,
  earn,
  ...rest
}: {
  first?: boolean
  header: boolean
  $loading?: boolean
  expires: ReactNode
  amount: ReactNode
  votePower: ReactNode
  poolInfo: ReactNode
  earn: ReactNode
  last?: boolean
  style?: CSSProperties
}) {
  const rowCells = (
    <>
      <PoolCell data-testid="pool-cell">{poolInfo}</PoolCell>
      <AmountCell data-testid="amount-cell">{amount}</AmountCell>
      <VotePowerCell data-testid="vote-power-cell">{votePower}</VotePowerCell>
      <ExpiresCell data-testid="expires-cell">{expires}</ExpiresCell>
      <EarnCell data-testid="earn-cell">{earn}</EarnCell>
    </>
  )
  if (header) return <StyledHeaderRow data-testid="lock-header-row">{rowCells}</StyledHeaderRow>
  return <StyledLockRow {...rest}>{rowCells}</StyledLockRow>
}

/* Header Row: top header row component for table */
export function HeaderRow() {
  return (
    <LockRow
      header={true}
      poolInfo={<Trans>{TokenSortMethod.NFT_ID}</Trans>}
      amount={<Trans>{TokenSortMethod.LOCK_AMOUNT}</Trans>}
      votePower={<Trans>{TokenSortMethod.VOTE_POWER}</Trans>}
      expires={
        <Row justify="end">
          <Trans>{TokenSortMethod.EXPIRES}</Trans>
        </Row>
      }
      earn={
        <Row justify="end">
          <Trans>{TokenSortMethod.EARN}</Trans>
        </Row>
      }
    />
  )
}
/* Loading State: row component with loading bubbles */
export function LoadingRow(props: { first?: boolean; last?: boolean }) {
  return (
    <LockRow
      header={false}
      $loading
      poolInfo={
        <Row>
          <IconLoadingBubble width="48px" height="48px" />
          <MediumLoadingBubble />
        </Row>
      }
      amount={<MediumLoadingBubble />}
      votePower={<LoadingBubble />}
      expires={
        <Row justify="end">
          <LoadingBubble />
        </Row>
      }
      earn={
        <Row justify="end">
          <ManagerButton>Manager</ManagerButton>
        </Row>
      }
      {...props}
    />
  )
}

interface LoadedRowProps {
  lockListIndex: number
  lockListLength: number
  lock: LockStakeListProps
}

/* Loaded State: row component with token information */
export const LoadedRow = forwardRef((props: LoadedRowProps, ref: ForwardedRef<HTMLDivElement>) => {
  const { lock, lockListIndex, lockListLength } = props
  const [tokenId, setTokenId] = useState<any>('')
  const [endTime, setEndTime] = useState<any>('')
  const [veAmount, setVeAmount] = useState<any>('')
  const [showManage, setShowManage] = useState<boolean>(false)
  const handleDismissManage = () => {
    setShowManage(false)
  }
  const handlerManage = (lock: any) => {
    setTokenId(lock.tokenId)
    setEndTime(lock.endTime)
    setVeAmount(lock.voteAmount)
    setShowManage(true)
  }

  return (
    <>
      <div ref={ref} data-testid={`lock-table-row-${lock?.tokenId}`}>
        <LockRow
          header={false}
          poolInfo={
            <Row>
              <NftIcon width={48} height={48} />
              <ThemedText.TextPrimary fontWeight={700} fontSize={16} ml="16px">
                #{lock.tokenId}
              </ThemedText.TextPrimary>
            </Row>
          }
          amount={
            <ThemedText.TextPrimary fontWeight={500} fontSize={16}>
              {formatCurrencyAmount(lock.lockAmount, 4)} {lock.lockAmount?.currency.symbol}
            </ThemedText.TextPrimary>
          }
          votePower={
            <ThemedText.TextPrimary fontWeight={500} fontSize={16}>
              {formatCurrencyAmount(lock.voteAmount, 4)} {lock.voteAmount?.currency.symbol}
            </ThemedText.TextPrimary>
          }
          expires={
            <Row flexDirection="column" align="end">
              <ThemedText.TextPrimary fontWeight={500} fontSize={16}>
                {dayjs(Number(lock.endTime) * 1000).format('D MMM YYYY')}
              </ThemedText.TextPrimary>
              <ThemedText.TextSecondary fontSize={14}>
                <Trans>213</Trans>
              </ThemedText.TextSecondary>
            </Row>
          }
          earn={
            <Row justify="end">
              <ManagerButton onClick={() => handlerManage(lock)}>Manager</ManagerButton>
            </Row>
          }
          first={lockListIndex === 0}
          last={lockListIndex === lockListLength - 1}
        />
      </div>
      <ManageModal
        isOpen={showManage}
        onDismiss={handleDismissManage}
        tokenId={tokenId}
        endTime={endTime}
        veAmount={veAmount}
      />
    </>
  )
})

LoadedRow.displayName = 'LoadedRow'
