import { Trans } from '@lingui/macro'
import LearnMore from 'components/LearnMore'
import Row from 'components/Row'
import { PageWrapper } from 'components/swap/styleds'
import { TitleDesc, TitleRow } from 'nft/components/profile/list/shared'
import { ThemedText } from 'theme'

import ContentWrapper from './ContentWrapper'
import Table from './Table'

export default function Lock() {
  return (
    <PageWrapper data-testid="lock-page">
      <TitleRow>
        <ThemedText.LargeHeader>
          <Trans>Lock</Trans>
        </ThemedText.LargeHeader>
      </TitleRow>
      <TitleDesc>
        <ThemedText.HeaderDesc lineHeight="16px">
          <Trans>
            Lock ROX into veROX to earn fees and lucrative bribes. Lock more tokens for longer to increase voting power.
            More votes - more bribes. veROX can always be transferred, merged or split. Maintain voting power by adding
            new veROX and relocking.
          </Trans>
        </ThemedText.HeaderDesc>
      </TitleDesc>
      <Row mt="16px">
        <LearnMore />
      </Row>
      <ContentWrapper />
      <Table />
    </PageWrapper>
  )
}
