import { Trans } from '@lingui/macro'
import Column from 'components/Column'
import { RowBetween } from 'components/Row'
import { useTheme } from 'styled-components/macro'
import { ThemedText } from 'theme'

export default function AdvancedFeeDetails() {
  const theme = useTheme()

  return (
    <Column gap="md">
      <RowBetween>
        <ThemedText.BodySmall color="textSecondary">
          <Trans>Price Impact</Trans>
        </ThemedText.BodySmall>
        <ThemedText.BodySmall color="black">-0.005%</ThemedText.BodySmall>
      </RowBetween>
      <RowBetween>
        <ThemedText.BodySmall color="textSecondary">
          <Trans>Min. received</Trans>
        </ThemedText.BodySmall>
        <ThemedText.BodySmall color="black">-0.005%</ThemedText.BodySmall>
      </RowBetween>
      <RowBetween>
        <ThemedText.BodySmall color="textSecondary">
          <Trans>Max slippage</Trans>
        </ThemedText.BodySmall>
        <ThemedText.BodySmall color="black">4.00%</ThemedText.BodySmall>
      </RowBetween>
    </Column>
  )
}
