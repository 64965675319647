import { CurrencyAmount, Price } from '@uniswap/sdk-core'
import { useActiveChainId } from 'connection/useActiveChainId'
import { useCurrency } from 'hooks/Tokens'
import useAutoSlippageTolerance from 'hooks/useAutoSlippageTolerance'
import { useQuoter, useTradeRouter } from 'hooks/useContract'
import { usePool } from 'hooks/usePools'
import { useTradePositionPool } from 'hooks/useTradePool'
import { useSingleCallResult } from 'lib/hooks/multicall'
// import { useSingleCallResult } from 'lib/hooks/multicall'
import { useMemo } from 'react'
import { useUserSlippageTolerance } from 'state/user/hooks'
import { SlippageTolerance } from 'state/user/types'
import { compareAddress } from 'utils'
import { toWei } from 'utils/bn'
import { zeroAddress } from 'viem'

export const useGetPositions = (tradePool?: string) => {
  const { account } = useActiveChainId()
  const tradeRouteContract = useTradeRouter()

  const { result: Positions, error } = useSingleCallResult(tradeRouteContract, 'getPositions', [account], {
    gasRequired: 400000,
  })

  console.log('[error]:', error)

  return useMemo(() => Positions?.[0].filter((x: any) => !compareAddress(x.account, zeroAddress)), [Positions])
}

export const useMockPool = () => {
  const tokena = useCurrency('0x0d9aDFeB471D81C83037647950ca87C73507166D')
  const tokenb = useCurrency('0xc2dae5b1DC28b7fDF20FF0A799d5E982DACf6e15')
  const [, pool] = usePool(tokena ?? undefined, tokenb ?? undefined, 3000)
  const tradePool = useTradePositionPool(pool?.token0, pool?.token1, pool?.fee)
  const QuoterContract = useQuoter()

  const { result } = useSingleCallResult(QuoterContract, 'quoteExactInputSingle', [
    pool?.token0.address,
    pool?.token1.address,
    pool?.fee,
    toWei(1, tokena?.decimals).toFixed(0),
    0,
  ])
  const tokenRatio = useMemo(() => {
    if (!tokena || !tokenb || !pool || !result) return
    const cc = result.amountOut
    const tokenA = CurrencyAmount.fromRawAmount(pool.token0.wrapped, toWei(1, tokena?.decimals).toFixed(0))
    const tokenB = CurrencyAmount.fromRawAmount(pool.token1.wrapped, cc.toString())

    const price = new Price({ baseAmount: tokenA, quoteAmount: tokenB })

    return price
  }, [pool, result, tokena, tokenb])

  const [userSlippageTolerance] = useUserSlippageTolerance()
  const autoSlippage = useAutoSlippageTolerance(undefined)
  const slippageTolerance = useMemo(() => {
    return userSlippageTolerance === SlippageTolerance.Auto ? autoSlippage.toFixed(2) : userSlippageTolerance.toFixed(2)
  }, [autoSlippage, userSlippageTolerance])
  return { pool, tradePool, tokenRatio, slippageTolerance }
}
