export enum TokenSortMethod {
  POOL = 'POOL',
  TVL = 'TVL',
  VAPR = 'vAPR',
  FEES = 'Fees',
  BRIBES = 'Bribes',
  TOTAL_REWARDS = 'Total Rewards',
  TOTAL_VOTES = 'Total Votes',
  MY_VOTE = 'My Vote',
  EARN = 'Earn',
}
