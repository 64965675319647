import { Trans } from '@lingui/macro'
import { Box } from '@mui/material'
import { ButtonGray } from 'components/Button'
import Column from 'components/Column'
import { NavDropdown } from 'components/NavBar/NavDropdown'
import Row, { RowBetween } from 'components/Row'
import { useLockStakeList } from 'pages/Lock/hooks'
import { useState } from 'react'
import styled from 'styled-components/macro'
import { ThemedText } from 'theme'

import { ReactComponent as DropDownIcon } from '../../assets/imgs/down.svg'
import SearchBar from './SearchBar'
import Table from './Table'

export const VeroxSelect = styled(ButtonGray)<{
  disabled?: boolean
}>`
  align-items: center;
  background-color: transparent;
  border: 1px solid ${({ theme }) => theme.sectionBorder};
  opacity: ${({ disabled }) => (!disabled ? 1 : 0.4)};
  color: ${({ theme }) => theme.textPrimary};
  cursor: pointer;
  width: auto;
  min-width: 200px;
  height: unset;
  padding: 8px 16px;
  border-radius: 8px;
  outline: none;
  user-select: none;
  font-size: 14px;
  font-weight: 400;
  gap: 8px;
  justify-content: space-between;

  &:hover,
  &:active {
    background-color: ${({ theme }) => theme.backgroundInteractive};
  }

  &:before {
    background-size: 100%;
    border-radius: inherit;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    content: '';
  }

  &:hover:before {
    background-color: ${({ theme }) => theme.stateOverlayHover};
  }

  &:active:before {
    background-color: ${({ theme }) => theme.stateOverlayPressed};
  }
`
const VotedInfo = styled(Row)`
  width: auto;
  margin-left: 8px;
  padding: 8px 16px;
  border: 1px solid ${({ theme }) => theme.swapSectionBorder};
  border-radius: 8px;
  gap: 8px;
`

const VotedList = styled(Column)`
  padding: 0 8px;
  min-width: 200px;
  .voteItem {
    padding: 8px 0;
    cursor: pointer;
    &:not(:last-child) {
      border-bottom: 1px solid ${({ theme }) => theme.swapSectionBorder};
    }
  }
`

export default function HeaderWrapper() {
  const [nftId, setNFTId] = useState('')
  const [isOpen, setIsOpen] = useState(false)
  const handleChange = (id: any) => {
    setNFTId(id)
  }
  const { tokenIdDetails: lockList } = useLockStakeList()
  const toggleOpen = () => {
    setIsOpen(!isOpen)
  }
  return (
    <Box>
      <RowBetween m="24px 0">
        <SearchBar />
        <Row width="auto">
          <div style={{ position: 'relative' }}>
            <VeroxSelect onClick={() => setIsOpen(true)}>
              <ThemedText.TextSecondary fontSize={14}>
                <Trans>{nftId ? nftId : 'Select Your veROX'}</Trans>
              </ThemedText.TextSecondary>
              <DropDownIcon />
            </VeroxSelect>
            {isOpen && (
              <NavDropdown top={{ sm: 'unset', lg: '56' }} bottom={{ sm: '50', lg: 'unset' }} right="0">
                <VotedList>
                  {lockList?.map((item) => (
                    <Row
                      className="voteItem"
                      key={item.tokenId}
                      onClick={() => {
                        handleChange(item.tokenId), toggleOpen()
                      }}
                    >
                      <ThemedText.TextSecondary># {item.tokenId}</ThemedText.TextSecondary>
                    </Row>
                  ))}
                </VotedList>
              </NavDropdown>
            )}
          </div>
          <VotedInfo>
            <ThemedText.TextSecondary fontSize={14}>
              <Trans>Voted:</Trans>
            </ThemedText.TextSecondary>
            <ThemedText.TextPrimary fontWeight={700}>
              <Trans>10,233</Trans>
            </ThemedText.TextPrimary>
            <ThemedText.BodySmall color="textTitle">
              <Trans>｜</Trans>
            </ThemedText.BodySmall>
            <ThemedText.TextSecondary fontSize={14}>
              <Trans>Balance:</Trans>
            </ThemedText.TextSecondary>
            <ThemedText.TextPrimary fontWeight={700}>
              <Trans>214,349,313.99</Trans>
            </ThemedText.TextPrimary>
          </VotedInfo>
        </Row>
      </RowBetween>
      <Table NFTID={nftId} />
    </Box>
  )
}
