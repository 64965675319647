import { Trans } from '@lingui/macro'
import Row from 'components/Row'
import { TableDataContainer } from 'components/Table'
import { MAX_WIDTH_MEDIA_BREAKPOINT } from 'components/Tokens/constants'
import { PAGE_SIZE } from 'graphql/data/TopTokens'
import { useVoter } from 'hooks/useContract'
import { ReactNode, useCallback, useState } from 'react'
import { AlertTriangle } from 'react-feather'
import styled from 'styled-components/macro'
import { ThemedText } from 'theme'

import { useFetchVoter } from './hooks'
import { HeaderRow, LoadedRow, LoadingRow } from './VoteRow'

const GridContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: ${MAX_WIDTH_MEDIA_BREAKPOINT};
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  align-items: center;
`

const NoTokenDisplay = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  height: 60px;
  color: ${({ theme }) => theme.textSecondary};
  font-size: 16px;
  font-weight: 500;
  align-items: center;
  padding: 0px 28px;
  gap: 8px;
`

const OptionsCard = styled(Row)`
  height: 70px;
  width: 310px;
  padding: 8px;
  margin: 16px;
  background-color: ${({ theme }) => theme.connectBtnBackground};
  border-radius: 32px;
  box-shadow: 0px 1px 16px 0px rgba(0, 0, 0, 0.25);
  gap: 16px;

  .tabItem {
    flex: 1;
    height: 100%;
    border-radius: 18px;
    cursor: pointer;
  }
  .tabItemActive {
    background: ${({ theme }) => theme.rewardBtnBackground};
    border-radius: 36px;
  }
`

function NoTokensState({ message }: { message: ReactNode }) {
  return (
    <GridContainer>
      <HeaderRow />
      <NoTokenDisplay>{message}</NoTokenDisplay>
    </GridContainer>
  )
}

const LoadingRows = ({ rowCount }: { rowCount: number }) => (
  <>
    {Array(rowCount)
      .fill(null)
      .map((_, index) => {
        return <LoadingRow key={index} first={index === 0} last={index === rowCount - 1} />
      })}
  </>
)

function LoadingTokenTable({ rowCount = PAGE_SIZE }: { rowCount?: number }) {
  return (
    <GridContainer>
      <HeaderRow />
      <TableDataContainer>
        <LoadingRows rowCount={rowCount} />
      </TableDataContainer>
    </GridContainer>
  )
}

export default function Table({ NFTID }: any) {
  // const chainName = validateUrlChainParam(useParams<{ chainName?: string }>().chainName)

  const [curr, setCurr] = useState<any>(0)

  const { voteList } = useFetchVoter()
  const loadingVotes = true

  const voteParams = voteList.map((item) => {
    return item?.poolAddress
  })

  const voterContract = useVoter()

  const handleVote = useCallback(async () => {
    if (!NFTID || !voteParams || !voterContract) return
    await voterContract?.vote(NFTID, voteParams, new Array(voteParams.length).fill(100 / voteParams.length))
  }, [NFTID, voteParams, voterContract])

  const handleReset = useCallback(async () => {
    if (!NFTID || !voterContract) return
    await voterContract?.reset(NFTID)
  }, [NFTID, voterContract])

  /* loading and error state */
  if (loadingVotes && !voteList) {
    return <LoadingTokenTable rowCount={PAGE_SIZE} />
  } else if (!voteList) {
    return (
      <NoTokensState
        message={
          <>
            <AlertTriangle size={16} />
            <Trans>An error occurred loading voteList. Please try again.</Trans>
          </>
        }
      />
    )
  } else if (voteList?.length === 0) {
    return <NoTokensState message={<Trans>No voteList found</Trans>} />
  } else {
    return (
      <>
        <HeaderRow />
        <TableDataContainer>
          {voteList?.map(
            (vote, index) =>
              vote?.poolname && (
                <LoadedRow key={index} voteListIndex={index} voteListLength={voteList.length} vote={vote} />
              )
          )}
        </TableDataContainer>

        <Row justify="center">
          <OptionsCard>
            <Row
              justify="center"
              className={`tabItem ${curr == 0 && 'tabItemActive'}`}
              onClick={() => {
                setCurr(0), handleReset()
              }}
            >
              {curr == 0 ? (
                <ThemedText.BodySmall fontSize={16} fontWeight={700} color="textTitle" className="">
                  Reset
                </ThemedText.BodySmall>
              ) : (
                <ThemedText.BodySmall fontWeight={700} color="textAddButton">
                  Reset
                </ThemedText.BodySmall>
              )}
            </Row>
            <Row
              justify="center"
              className={`tabItem ${curr == 1 && 'tabItemActive'}`}
              onClick={() => {
                setCurr(1), handleVote()
              }}
            >
              {curr == 1 ? (
                <ThemedText.BodySmall fontSize={16} fontWeight={700} color="textTitle" className="">
                  Cast Votes
                </ThemedText.BodySmall>
              ) : (
                <ThemedText.BodySmall fontWeight={700} color="textAddButton">
                  Cast Votes
                </ThemedText.BodySmall>
              )}
            </Row>
          </OptionsCard>
        </Row>
      </>
    )
  }
}
