import { Trans } from '@lingui/macro'
import { DisabledClaimButton } from 'components/Button'
import Column from 'components/Column'
import Row from 'components/Row'
import { PageWrapper } from 'components/swap/styleds'
import SearchBar from 'pages/Liquidity/SearchBar'
import { VeroxSelect } from 'pages/Vote/HeaderWrapper'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components/macro'
import { ThemedText } from 'theme'

import { ReactComponent as Back } from '../../../assets/imgs/back.svg'
import { ReactComponent as DropDownIcon } from '../../../assets/imgs/down.svg'
import { ReactComponent as Logo } from '../../../assets/imgs/logo.svg'
import Table from './Table'

const StyledHeaderRow = styled(Row)`
  justify-content: space-between;
  border-bottom: 1px solid ${({ theme }) => theme.primaryBorder};
  padding-bottom: 50px;
`

const StyledInfoRow = styled(Row)`
  justify-content: space-between;
  align-items: stretch;
  padding: 20px 0 16px;
  .volumeInfo {
    min-width: 500px;
    padding: 16px 16px 22px;
    border-radius: 16px;
    border: 1px solid ${({ theme }) => theme.primaryBorder};
    .myVolume {
      padding-left: 16px;
      padding-bottom: 16px;
      border-bottom: 1px solid ${({ theme }) => theme.primaryBorder};
    }
    .earned {
      padding-left: 16px;
    }
  }
`

export default function LeaderboardDetail() {
  const navigate = useNavigate()
  const handlerBack = () => {
    navigate(-1)
  }

  return (
    <PageWrapper data-testid="leaderboardDetail-page">
      <StyledHeaderRow>
        <Row align="center">
          <Back style={{ cursor: 'pointer' }} onClick={handlerBack} />
          <Row ml={16} width="auto">
            <Logo />
          </Row>
          <ThemedText.LargeHeader ml={16}>
            <Trans>ROX/ETH</Trans>
          </ThemedText.LargeHeader>
        </Row>
        <Column align="flex-end" className="">
          <VeroxSelect>
            <ThemedText.TextSecondary fontSize={14}>
              <Trans>July 24th</Trans>
            </ThemedText.TextSecondary>
            <DropDownIcon />
          </VeroxSelect>
          <Row width="auto" mt={16}>
            <ThemedText.TextSecondary fontWeight={700} fontSize={15}>
              Countdown:
            </ThemedText.TextSecondary>
            <ThemedText.TextPrimary fontWeight={700} fontSize={15}>
              6d
            </ThemedText.TextPrimary>
            <ThemedText.TextSecondary ml="4px" mr="4px" fontSize={15}>
              /
            </ThemedText.TextSecondary>
            <ThemedText.TextPrimary fontWeight={700} fontSize={15}>
              6h
            </ThemedText.TextPrimary>
            <ThemedText.TextSecondary ml="4px" mr="4px" fontSize={15}>
              /
            </ThemedText.TextSecondary>
            <ThemedText.TextPrimary fontWeight={700} fontSize={15}>
              6s
            </ThemedText.TextPrimary>
          </Row>
        </Column>
      </StyledHeaderRow>
      <StyledInfoRow>
        <Column justify="space-between" align="flex-start">
          <div>
            <ThemedText.TextSecondary>Total Rewards</ThemedText.TextSecondary>
            <ThemedText.TextPrimary fontWeight={700} fontSize={24}>
              1,313.99 ROX
            </ThemedText.TextPrimary>
            <ThemedText.TextSecondary fontSize={14}>$1,313.99</ThemedText.TextSecondary>
          </div>
          <Row width="auto" gap="64px">
            <Column>
              <ThemedText.TextSecondary>Trading Volume</ThemedText.TextSecondary>
              <ThemedText.TextPrimary fontWeight={700} fontSize={24}>
                $1,313.99
              </ThemedText.TextPrimary>
            </Column>
            <Column>
              <ThemedText.TextSecondary>Traders</ThemedText.TextSecondary>
              <ThemedText.TextPrimary fontWeight={700} fontSize={24}>
                1,313
              </ThemedText.TextPrimary>
            </Column>
          </Row>
        </Column>
        <div className="volumeInfo">
          <Column align="flex-start" className="myVolume">
            <ThemedText.TextSecondary>My Volume</ThemedText.TextSecondary>
            <ThemedText.TextPrimary fontWeight={700} fontSize={24}>
              $214,349,313.99
            </ThemedText.TextPrimary>
          </Column>
          <Row justify="space-between" align="center" mt={12} className="earned">
            <Column align="flex-start">
              <ThemedText.TextSecondary>Earned</ThemedText.TextSecondary>
              <ThemedText.TextPrimary fontWeight={700} fontSize={24}>
                9,313.99 ROX
              </ThemedText.TextPrimary>
            </Column>
            <DisabledClaimButton>Claim</DisabledClaimButton>
          </Row>
        </div>
      </StyledInfoRow>
      <div className="searchBox">
        <SearchBar />
      </div>
      <Table />
    </PageWrapper>
  )
}
