import Slider from 'rc-slider'
import styled, { useTheme } from 'styled-components/macro'
const StyledSlider = styled(Slider)`
  cursor: pointer;
`
export default function Leverage({ percent, onSliderChange }: any) {
  const theme = useTheme()
  return (
    <StyledSlider
      defaultValue={[1.1, 50]}
      min={1.1}
      max={50}
      // range
      step={0.1}
      value={percent}
      trackStyle={{
        height: '6px',
        backgroundColor: `${theme.accentAction}`,
      }}
      handleStyle={{
        width: '8px',
        height: '20px',
        top: '3px',
        backgroundColor: `${theme.sliderThumb}`,
        borderRadius: '4px',
        border: 'none',
        boxShadow: 'none',
      }}
      railStyle={{
        height: '6px',
        backgroundColor: `${theme.sliderTrack}`,
      }}
      onChange={onSliderChange}
    />
  )
}
