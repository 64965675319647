// Based mostly on https://github.com/Uniswap/interface/blob/main/src/theme/index.tsx
import { opacify } from './utils'

export const colors = {
  // rogue color start
  white: '#FFFFFF',
  black: '#000000',
  black87: 'rgba(0, 0, 0, 0.87)',
  black54: 'rgba(0, 0, 0, 0.54)',
  black38: 'rgba(0, 0, 0, 0.38)',
  black5: 'rgba(0, 0, 0, 0.5)',
  black16: 'rgba(0, 0, 0, 0.16)',
  black08: 'rgba(0, 0, 0, 0.08)',
  black05: 'rgba(0, 0, 0, 0.05)',
  hoverBG: 'rgba(0, 178, 255, 0.2)',
  textBlue: '#73D5FF',
  connectBg: '#113A51',
  disabledColor: '#BDEBFF',
  rewardedColor: '#0093D1',
  color84: '#848484',
  color06: ' #060606',
  colorE9: '#E9E9E9',
  colorF0: '#F0F0F0',
  colorD9: '#D9D9D9',
  color32: '#323232',
  pink400: '#00B2FF',
  pink500: '#C41969',
  tradeGreen: 'rgba(0, 209, 209, 1)',
  tradeRed: 'rgba(255, 64, 22, 1)',
  tabBgActive: 'linear-gradient(180deg, #50C8FF 0%, #01CED2 100%)',
  moreColor: '#01CED2 ',
  rewardBtnBg: 'linear-gradient(180deg, #1EA7E4 0%, #01BBBE 100%)',
  activeBg: '#1EA7E4',
  previewBg: '#EEEEEE',
  badgeGradient: 'linear-gradient(180deg, rgba(30, 167, 228, 0.2) 0%, rgba(1, 187, 190, 0.2) 100%);',
  removeBg: '#CA8A0D',
  // rogue color end

  gray50: '#F5F6FC',
  gray100: '#E8ECFB',
  gray200: '#B8C0DC',
  gray250: '#A6AFCA',
  gray300: '#98A1C0',
  gray350: '#888FAB',
  gray400: '#7780A0',
  gray450: '#6B7594',
  gray500: '#5D6785',
  gray550: '#505A78',
  gray600: '#404A67',
  gray650: '#333D59',
  gray700: '#293249',
  gray750: '#1B2236',
  gray800: '#131A2A',
  gray850: '#0E1524',
  gray900: '#0D111C',
  gray950: '#080B11',
  pink50: '#F9ECF1',
  pink100: '#FFD9E4',
  pink200: '#FBA4C0',
  pink300: '#FF6FA3',
  pink600: '#8C0F49',
  pink700: '#55072A',
  pink800: '#350318',
  pink900: '#2B000B',
  pinkVibrant: '#F51A70',
  red50: '#FAECEA',
  red100: '#FED5CF',
  red200: '#FEA79B',
  red300: '#FD766B',
  red400: '#FA2B39',
  red500: '#C4292F',
  red600: '#891E20',
  red700: '#530F0F',
  red800: '#380A03',
  red900: '#240800',
  redVibrant: '#F14544',
  yellow50: '#F6F2D5',
  yellow100: '#DBBC19',
  yellow200: '#DBBC19',
  yellow300: '#BB9F13',
  yellow400: '#A08116',
  yellow500: '#866311',
  yellow600: '#5D4204',
  yellow700: '#3E2B04',
  yellow800: '#231902',
  yellow900: '#180F02',
  yellowVibrant: '#FAF40A',
  // TODO: add gold 50-900
  gold200: '#EEB317',
  gold400: '#B17900',
  goldVibrant: '#FEB239',
  green50: '#E3F3E6',
  green100: '#BFEECA',
  green200: '#76D191',
  green300: '#40B66B',
  green400: '#209853',
  green500: '#0B783E',
  green600: '#0C522A',
  green700: '#053117',
  green800: '#091F10',
  green900: '#09130B',
  greenVibrant: '#5CFE9D',
  blue50: '#EDEFF8',
  blue100: '#DEE1FF',
  blue200: '#ADBCFF',
  blue300: '#869EFF',
  blue400: '#4C82FB',
  blue500: '#1267D6',
  blue600: '#1D4294',
  blue700: '#09265E',
  blue800: '#0B193F',
  blue900: '#040E34',
  blueVibrant: '#587BFF',
  // TODO: add magenta 50-900
  magenta300: '#FD82FF',
  magentaVibrant: '#FC72FF',
  purple300: '#8440F2',
  purple900: '#1C0337',
  purpleVibrant: '#6100FF',
  // TODO: add all other vibrant variations
  networkEthereum: '#627EEA',
  networkOptimism: '#FF0420',
  networkOptimismSoft: 'rgba(255, 4, 32, 0.16)',
  networkPolygon: '#A457FF',
  networkArbitrum: '#28A0F0',
  networkBsc: '#F0B90B',
  networkPolygonSoft: 'rgba(164, 87, 255, 0.16)',
  networkEthereumSoft: 'rgba(98, 126, 234, 0.16)',
}

type Theme = typeof darkTheme

const commonTheme = {
  white: colors.white,
  black: colors.black,

  tradeGreen: colors.tradeGreen,
  tradeRed: colors.tradeRed,

  chain_1: colors.networkEthereum,
  chain_3: colors.yellow400,
  chain_4: colors.pink400,
  chain_5: colors.green400,
  chain_10: colors.networkOptimism,
  chain_137: colors.networkPolygon,
  chain_42: colors.networkArbitrum,
  chain_56: colors.networkBsc,
  chain_420: colors.networkOptimism,
  chain_42161: colors.networkArbitrum,
  chain_421613: colors.networkArbitrum,
  chain_80001: colors.networkPolygon,
  chain_43114: colors.networkOptimism,
  chain_137_background: colors.purple900,
  chain_10_background: colors.red900,
  chain_43114_background: colors.red900,
  chain_42161_background: colors.blue900,
  chain_56_background: colors.networkBsc,
  promotional: colors.magenta300,

  brandedGradient: 'linear-gradient(139.57deg, #FF79C9 4.35%, #FFB8E2 96.44%);',
  promotionalGradient: 'radial-gradient(101.8% 4091.31% at 0% 0%, #4673FA 0%, #9646FA 100%);',

  hoverState: opacify(24, colors.blue200),
  hoverDefault: colors.black05,
}

export const darkTheme = {
  ...commonTheme,

  userThemeColor: colors.magentaVibrant,

  background: colors.gray800,
  backgroundBackdrop: colors.gray950,
  backgroundSurface: colors.gray900,
  backgroundModule: colors.gray800,
  backgroundInter: colors.black,
  backgroundOutside: colors.white,
  backgroundInteractive: colors.gray700,
  backgroundFloating: opacify(12, colors.black),
  backgroundOutline: colors.black08,
  backgroundScrim: opacify(72, colors.gray900),
  backgroundScrolledSurface: opacify(72, colors.gray900),

  textTitle: colors.connectBg,
  textPrimary: colors.white,
  textSecondary: colors.black5,
  textTertiary: colors.black38,
  textButtonHover: colors.pink500,
  textTab: colors.color84,
  textTabActive: colors.white,
  textMore: colors.tabBgActive,
  textAddButton: colors.colorF0,
  textReward: colors.rewardedColor,

  accentAction: colors.blue400,
  accentActive: colors.blue400,
  accentSuccess: colors.green200,
  accentWarning: colors.gold200,
  accentFailure: colors.red300,
  accentCritical: colors.red300,

  accentActionSoft: opacify(24, colors.blue400),
  accentActiveSoft: opacify(24, colors.blue400),
  accentSuccessSoft: opacify(24, colors.green400),
  accentWarningSoft: opacify(24, colors.gold200),
  accentFailureSoft: opacify(12, colors.red300),

  accentTextDarkPrimary: opacify(80, colors.gray900),
  accentTextDarkSecondary: opacify(60, colors.gray900),
  accentTextDarkTertiary: opacify(24, colors.gray900),

  accentTextLightPrimary: colors.gray50,
  accentTextLightSecondary: opacify(72, colors.gray50),
  accentTextLightTertiary: opacify(12, colors.gray50),

  deepShadow: '12px 16px 24px rgba(0, 0, 0, 0.24), 12px 8px 12px rgba(0, 0, 0, 0.24), 4px 4px 8px rgba(0, 0, 0, 0.32);',
  shallowShadow: '4px 4px 10px rgba(0, 0, 0, 0.24), 2px 2px 4px rgba(0, 0, 0, 0.12), 1px 2px 2px rgba(0, 0, 0, 0.12);',

  networkDefaultShadow: `0px 40px 120px ${opacify(16, colors.blue400)}`,

  stateOverlayHover: opacify(8, colors.gray300),
  stateOverlayPressed: opacify(24, colors.gray200),

  searchBackground: `rgba(255,255,255,0.07)`,
  searchOutline: `rgba(255,255,255,0.07)`,

  swapSectionBorder: colors.black08,
  sectionBorder: colors.black16,

  backgroundHover: colors.hoverBG,
  buttonPrimaryBackground: colors.pink400,
  buttonDisabledBackground: colors.disabledColor,
  buttonDisabledText: colors.textBlue,
  connectBtnBackground: colors.connectBg,
  rewardBtnBackground: colors.rewardBtnBg,
  backgroundTab: colors.colorE9,
  backgroundTabActive: colors.tabBgActive,
  backgroundPreview: colors.previewBg,
  badgeBackground: colors.badgeGradient,
  removeBackground: colors.removeBg,
  activeBorder: colors.activeBg,

  navActiveText: colors.black87,
  navText: colors.black54,

  moreBorder: colors.moreColor,
  primaryBorder: colors.black08,

  sliderThumb: colors.colorD9,
  sliderTrack: colors.color32,
}

export const lightTheme: Theme = {
  ...commonTheme,

  //  rogeue
  backgroundHover: colors.hoverBG,

  userThemeColor: colors.magentaVibrant,

  background: '#faf9fa', //INTENTIONALLY OFF THEME TO GIVE WHITE BG A SOFTER VISUAL
  backgroundBackdrop: colors.white,
  backgroundSurface: colors.white,
  backgroundModule: colors.gray50,
  backgroundInter: colors.black,
  backgroundOutside: colors.white,
  backgroundInteractive: colors.black05,
  backgroundFloating: opacify(8, colors.gray700),
  backgroundOutline: colors.black08,
  backgroundScrim: opacify(60, colors.gray900),
  backgroundScrolledSurface: opacify(72, colors.white),

  textTitle: colors.connectBg,
  textPrimary: colors.black87,
  textSecondary: colors.black54,
  textTertiary: colors.black38,
  textButtonHover: colors.pink500,
  textTab: colors.color84,
  textTabActive: colors.white,
  textMore: colors.tabBgActive,
  textAddButton: colors.colorF0,
  textReward: colors.rewardedColor,

  accentAction: colors.pink400,
  accentActive: colors.blue400,
  accentSuccess: colors.green300,
  accentWarning: colors.gold400,
  accentFailure: colors.red400,
  accentCritical: colors.red400,

  accentActionSoft: opacify(12, colors.pink400),
  accentActiveSoft: opacify(24, colors.blue400),
  accentSuccessSoft: opacify(24, colors.green300),
  accentWarningSoft: opacify(24, colors.gold400),
  accentFailureSoft: opacify(12, colors.red400),

  accentTextDarkPrimary: opacify(80, colors.gray900),
  accentTextDarkSecondary: opacify(60, colors.gray900),
  accentTextDarkTertiary: opacify(24, colors.gray900),

  accentTextLightPrimary: colors.white,
  accentTextLightSecondary: opacify(72, colors.gray50),
  accentTextLightTertiary: opacify(12, colors.gray50),

  deepShadow:
    '8px 12px 20px rgba(51, 53, 72, 0.04), 4px 6px 12px rgba(51, 53, 72, 0.02), 4px 4px 8px rgba(51, 53, 72, 0.04);',
  shallowShadow:
    '6px 6px 10px rgba(51, 53, 72, 0.01), 2px 2px 6px rgba(51, 53, 72, 0.02), 1px 2px 2px rgba(51, 53, 72, 0.02);',

  networkDefaultShadow: `0px 40px 120px ${opacify(12, colors.pink400)}`,

  stateOverlayHover: opacify(8, colors.gray300),
  stateOverlayPressed: opacify(24, colors.gray200),

  searchBackground: opacify(4, colors.white),
  searchOutline: opacify(1, colors.black),

  swapSectionBorder: colors.black08,
  sectionBorder: colors.black16,

  buttonPrimaryBackground: colors.pink400,
  buttonDisabledBackground: colors.disabledColor,
  buttonDisabledText: colors.textBlue,
  connectBtnBackground: colors.connectBg,
  rewardBtnBackground: colors.rewardBtnBg,
  backgroundTab: colors.colorE9,
  backgroundTabActive: colors.tabBgActive,
  backgroundPreview: colors.previewBg,
  badgeBackground: colors.badgeGradient,
  removeBackground: colors.removeBg,
  activeBorder: colors.activeBg,

  navActiveText: colors.black87,
  navText: colors.black54,
  moreBorder: colors.moreColor,
  primaryBorder: colors.black08,
  sliderThumb: colors.colorD9,
  sliderTrack: colors.color32,
}
