import { Trans } from '@lingui/macro'
import { CurrencyAmount, Percent } from '@uniswap/sdk-core'
import SWAPMINGABI from 'abis/swapMinning.json'
import { ClaimButton } from 'components/Button'
import Column from 'components/Column'
import { IconLoadingBubble, LoadingBubble, MediumLoadingBubble } from 'components/Loading'
import Row from 'components/Row'
import { Cell, StyledTableRow } from 'components/Table'
import {
  LARGE_MEDIA_BREAKPOINT,
  MAX_WIDTH_MEDIA_BREAKPOINT,
  MEDIUM_MEDIA_BREAKPOINT,
  SMALL_MEDIA_BREAKPOINT,
} from 'components/Tokens/constants'
import { useActiveChainId, useEthersSigner } from 'connection/useActiveChainId'
import { useCurrency } from 'hooks/Tokens'
import { useSwapMinning, useTokenContract } from 'hooks/useContract'
import { PoolStruct } from 'lib/AllPools'
import { useSingleCallResult } from 'lib/hooks/multicall'
import { CSSProperties, ForwardedRef, forwardRef, ReactNode, useMemo } from 'react'
import { NavLink } from 'react-router-dom'
import styled, { css } from 'styled-components/macro'
import { ThemedText } from 'theme'
import { getContract } from 'utils'
import { CurrRound } from 'utils/CurrRound'

import { ReactComponent as NftIcon } from '../../assets/imgs/nft.svg'
import { TokenSortMethod } from './state'
const StyledLeaderboardRow = styled(StyledTableRow)<{
  first?: boolean
  last?: boolean
  $loading?: boolean
}>`
  align-items: stretch;
  grid-template-columns: 1.5fr 1fr 1fr 1fr 1fr 1fr;
  max-width: ${MAX_WIDTH_MEDIA_BREAKPOINT};
  min-width: 390px;
  padding: 0;
  margin: 0;
  ${({ first, last }) => css`
    /* height: ${first || last ? '72px' : '64px'}; */
  `}
  transition: ${({
    theme: {
      transition: { duration, timing },
    },
  }) => css`background-color ${duration.medium} ${timing.ease}`};
  transition-duration: ${({ theme }) => theme.transition.duration.fast};

  &:hover {
    ${({ $loading, theme }) =>
      !$loading &&
      css`
        background-color: ${theme.hoverDefault};
      `}
    ${({ last }) =>
      last &&
      css`
        border-radius: 0px 0px 8px 8px;
      `}
  }

  .bg {
    ${({ theme }) =>
      css`
        background-color: ${theme.hoverDefault};
      `}
  }

  @media only screen and (max-width: ${MAX_WIDTH_MEDIA_BREAKPOINT}) {
    grid-template-columns: 1.5fr 1fr 1fr 1fr 1fr 1fr;
  }

  @media only screen and (max-width: ${LARGE_MEDIA_BREAKPOINT}) {
    grid-template-columns: 1.5fr 1fr 1fr 1fr 1fr 1fr;
  }

  @media only screen and (max-width: ${MEDIUM_MEDIA_BREAKPOINT}) {
    grid-template-columns: 1.5fr 1fr 1fr 1fr 1fr 1fr;
  }

  @media only screen and (max-width: ${SMALL_MEDIA_BREAKPOINT}) {
    grid-template-columns: 1.5fr 1fr 1fr 1fr 1fr 1fr;
    min-width: unset;
    border-bottom: 0.5px solid ${({ theme }) => theme.backgroundModule};

    :last-of-type {
      border-bottom: none;
    }
  }
`

const StyledHeaderRow = styled(StyledLeaderboardRow)`
  justify-content: center;
  color: ${({ theme }) => theme.textSecondary};
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;

  &:hover {
    background-color: transparent;
  }

  @media only screen and (max-width: ${SMALL_MEDIA_BREAKPOINT}) {
    justify-content: space-between;
  }
`

const PoolCell = styled(Cell)`
  justify-content: flex-start;
  align-items: flex-start;
  padding: 16px 8px 16px;
  min-width: 240px;
`

const TextRightCell = styled(Cell)`
  padding: 16px 8px 16px;
  align-items: flex-start;
  @media only screen and (max-width: ${MEDIUM_MEDIA_BREAKPOINT}) {
    display: none;
  }
`

/* Token Row: skeleton row component */
function LeaderboardRow({
  header,
  pools,
  tvl,
  volume,
  totalRewards,
  myVolume,
  earn,
  ...rest
}: {
  first?: boolean
  header: boolean
  $loading?: boolean
  totalRewards: ReactNode
  tvl: ReactNode
  volume: ReactNode
  pools: ReactNode
  myVolume: ReactNode
  earn: ReactNode
  last?: boolean
  style?: CSSProperties
}) {
  const rowCells = (
    <>
      <PoolCell data-testid="pools-cell">{pools}</PoolCell>
      <TextRightCell data-testid="tvl-cell">{tvl}</TextRightCell>
      <TextRightCell data-testid="volume-cell">{volume}</TextRightCell>
      <TextRightCell data-testid="total-rewards-cell">{totalRewards}</TextRightCell>
      <TextRightCell data-testid="my-volume-cell" className="bg">
        {myVolume}
      </TextRightCell>
      <TextRightCell data-testid="my-leaderboard-cell" className="bg">
        {earn}
      </TextRightCell>
    </>
  )
  if (header) return <StyledHeaderRow data-testid="leaderboard-header-row">{rowCells}</StyledHeaderRow>
  return <StyledLeaderboardRow {...rest}>{rowCells}</StyledLeaderboardRow>
}

/* Header Row: top header row component for table */
export function HeaderRow() {
  return (
    <LeaderboardRow
      header={true}
      pools={<Trans>{TokenSortMethod.POOLS}</Trans>}
      tvl={
        <Row justify="end">
          <Trans>{TokenSortMethod.TVL}</Trans>
        </Row>
      }
      volume={
        <Row justify="end">
          <Trans>{TokenSortMethod.VOLUME}</Trans>
        </Row>
      }
      totalRewards={
        <Row justify="end">
          <Trans>{TokenSortMethod.TOTAL_REWARDS}</Trans>
        </Row>
      }
      myVolume={
        <Row justify="end">
          <Trans>{TokenSortMethod.MY_VOLUME}</Trans>
        </Row>
      }
      earn={
        <Row justify="end">
          <Trans>{TokenSortMethod.EARN}</Trans>
        </Row>
      }
    />
  )
}
/* Loading State: row component with loading bubbles */
export function LoadingRow(props: { first?: boolean; last?: boolean }) {
  return (
    <LeaderboardRow
      header={false}
      $loading
      pools={
        <Row>
          <IconLoadingBubble width="48px" height="48px" />
          <MediumLoadingBubble />
        </Row>
      }
      tvl={
        <Row flexDirection="column" align="end" gap="8px">
          <MediumLoadingBubble />
          <MediumLoadingBubble />
          <MediumLoadingBubble />
        </Row>
      }
      volume={
        <Row justify="end">
          <LoadingBubble />
        </Row>
      }
      totalRewards={
        <Row flexDirection="column" align="end" gap="8px">
          <MediumLoadingBubble />
          <MediumLoadingBubble />
        </Row>
      }
      myVolume={
        <Row flexDirection="column" align="end" gap="8px">
          <MediumLoadingBubble />
          <MediumLoadingBubble />
        </Row>
      }
      earn={
        <Row flexDirection="column" align="end" gap="8px">
          <MediumLoadingBubble />
          <MediumLoadingBubble />
          <ClaimButton>claim</ClaimButton>
        </Row>
      }
      {...props}
    />
  )
}

interface LoadedRowProps {
  leaderboardListIndex: number
  leaderboardListLength: number
  leaderboard: PoolStruct
}

const TotalRewardsItem = ({ rewardAddr, poolAddr }: any) => {
  const currenty = useCurrency(rewardAddr)
  const tokenContract = useTokenContract(rewardAddr, false)
  const { result } = useSingleCallResult(tokenContract, 'balanceOf', [poolAddr])

  const tokenInfo = useMemo(() => {
    if (!result || !currenty) return
    return CurrencyAmount.fromRawAmount(currenty, result[0])
  }, [currenty, result])
  return (
    <ThemedText.TextSecondary fontSize={14}>
      {tokenInfo?.toSignificant() || 0} {currenty?.symbol}
    </ThemedText.TextSecondary>
  )
}

const TotalEarnsItem = ({ rewardAddr, swapMing }: any) => {
  const currenty = useCurrency(rewardAddr)
  const tokenContract = useSwapMinning(swapMing)
  const { account } = useActiveChainId()
  const currRound = CurrRound()
  const { result, error } = useSingleCallResult(tokenContract, 'earned', [rewardAddr, account, currRound])

  const tokenInfo = useMemo(() => {
    if (!result || !currenty) return
    return CurrencyAmount.fromRawAmount(currenty, result[0])
  }, [currenty, result])
  return (
    <ThemedText.TextRewards>
      {tokenInfo?.toSignificant(2) || 0} {currenty?.symbol}
    </ThemedText.TextRewards>
  )
}

/* Loaded State: row component with token information */
export const LoadedRow = forwardRef((props: LoadedRowProps, ref: ForwardedRef<HTMLDivElement>) => {
  const { leaderboardListIndex, leaderboardListLength, leaderboard } = props

  const token0 = useCurrency(leaderboard.token0Address)
  const provder = useEthersSigner()
  const { account } = useActiveChainId()
  const currRound = CurrRound()

  const totlaVolume = useMemo(() => {
    if (!token0 || !leaderboard.totalVolume) return
    return CurrencyAmount.fromRawAmount(token0, leaderboard.totalVolume.toString())
  }, [leaderboard.totalVolume, token0])

  const myVolume = useMemo(() => {
    if (!token0 || !leaderboard.myVolume) return
    return CurrencyAmount.fromRawAmount(token0, leaderboard.myVolume.toString())
  }, [leaderboard.myVolume, token0])

  const onClaim = async (e: any, data: any) => {
    e.preventDefault()

    const swapMIng = getContract(data.swapMinningAddress, SWAPMINGABI.abi, provder)
    if (!swapMIng || !account) return
    await swapMIng.getReward(account, currRound)
  }

  return (
    <NavLink to="/LeaderboardDetail" style={{ textDecoration: 'none' }}>
      <div ref={ref} data-testid={`leaderboard-table-row-${leaderboard.poolname}`}>
        <LeaderboardRow
          header={false}
          pools={
            <Row>
              <NftIcon width={48} height={48} />
              <Column align="flex-start">
                <ThemedText.TextPrimary fontWeight={700} fontSize={16} ml="16px">
                  #{leaderboard.poolname}
                </ThemedText.TextPrimary>
                <ThemedText.TextSecondary fontSize={14}>
                  {new Percent(leaderboard.fee, 1_000_000).toSignificant()}%
                </ThemedText.TextSecondary>
              </Column>
            </Row>
          }
          tvl={
            <Row flexDirection="column" align="end">
              <ThemedText.TextPrimary fontWeight={700}>$100</ThemedText.TextPrimary>
              <ThemedText.TextSecondary fontSize={14}>0 ROX</ThemedText.TextSecondary>
              <ThemedText.TextSecondary fontSize={14}>18,283 ETH</ThemedText.TextSecondary>
            </Row>
          }
          volume={
            <Row flexDirection="column" align="end">
              <ThemedText.TextPrimary fontWeight={700}>$0</ThemedText.TextPrimary>
              <ThemedText.TextSecondary fontSize={14}>
                {totlaVolume?.toSignificant()} {totlaVolume?.currency.symbol}
              </ThemedText.TextSecondary>
            </Row>
          }
          totalRewards={
            <Row flexDirection="column" align="end">
              <ThemedText.TextPrimary fontWeight={700}>{0}</ThemedText.TextPrimary>
              <ThemedText.TextSecondary fontSize={14}>0</ThemedText.TextSecondary>
            </Row>
          }
          myVolume={
            <Row flexDirection="column" align="end">
              <ThemedText.TextPrimary fontWeight={700}>$0</ThemedText.TextPrimary>
              <ThemedText.TextSecondary fontSize={14}>
                {' '}
                {myVolume?.toSignificant()} {myVolume?.currency.symbol}
              </ThemedText.TextSecondary>
            </Row>
          }
          earn={
            <Row flexDirection="column" align="end">
              {leaderboard?.rewardsList?.map((item: any, index: number) => {
                return (
                  <TotalEarnsItem key={'earn' + index} rewardAddr={item} swapMing={leaderboard.swapMinningAddress} />
                )
              })}
              <Row mt="8px" justify="end">
                <ClaimButton onClick={(e) => onClaim(e, leaderboard)}>claim</ClaimButton>
              </Row>
            </Row>
          }
          first={leaderboardListIndex === 0}
          last={leaderboardListIndex === leaderboardListLength - 1}
        />
      </div>
    </NavLink>
  )
})

LoadedRow.displayName = 'LoadedRow'
