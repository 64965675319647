import { Trans } from '@lingui/macro'
import { Percent } from '@uniswap/sdk-core'
import { BaseButton } from 'components/Button'
import Column from 'components/Column'
import Modal from 'components/Modal'
import Row, { RowBetween } from 'components/Row'
import { ConfirmationModalContent } from 'components/TransactionConfirmationModal'
import { useActiveChainId } from 'connection/useActiveChainId'
import { utils } from 'ethers'
import { useCurrency } from 'hooks/Tokens'
import { useMasterChefV3, useV3NFTPositionManagerContract } from 'hooks/useContract'
import { isKeyEqual, useV3Positions, useV3StakedPositions } from 'hooks/useV3Positions'
import { useCallback } from 'react'
import styled from 'styled-components/macro'
import { ThemedText } from 'theme'
import { PositionDetails } from 'types/position'

import { ReactComponent as AddIcon } from '../../../assets/imgs/liquidity/add.svg'
import { ReactComponent as NftIcon } from '../../../assets/imgs/nft.svg'

const HeaderRow = styled(RowBetween)`
  padding: 8px 0;
  border-bottom: 1px solid ${({ theme }) => theme.primaryBorder};
`

const AddLiquidity = styled(BaseButton)`
  background-color: transparent;
  border-radius: 8px;
  color: ${({ theme }) => theme.textTitle};
  font-size: 14px;
  font-weight: 700;
  width: auto;
  height: 40px;
  padding: 10px 16px;
`

const StakeBtn = styled(BaseButton)`
  background-color: ${({ theme }) => theme.backgroundInteractive};
  border-radius: 8px;
  color: ${({ theme }) => theme.textPrimary};
  font-size: 14px;
  font-weight: 700;
  width: auto;
  height: 36px;
  padding: 4px 16px;
`

const ManageBtn = styled(BaseButton)`
  background-color: transparent;
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.primaryBorder};
  color: ${({ theme }) => theme.textPrimary};
  font-size: 14px;
  font-weight: 700;
  width: auto;
  height: 36px;
  padding: 4px 16px;
`

const StyledLiquidityRow = styled(Row)`
  display: grid;
  align-items: flex-start;
  grid-template-columns: 1fr 1fr 100px 1fr 1fr;
`
const StyledLiquidityRowTh = styled(StyledLiquidityRow)`
  padding: 12px 0;
  border-bottom: 1px solid ${({ theme }) => theme.primaryBorder};
`
const StyledLiquidityRowTr = styled(StyledLiquidityRow)`
  padding: 16px 0;
  &:not(:last-child) {
    border-bottom: 1px solid ${({ theme }) => theme.primaryBorder};
  }
`
enum STKESTATE {
  STAKE = 'Stake',
  UNSTAKE = 'UnStake',
}

const LiquidityRowTh = () => {
  return (
    <StyledLiquidityRowTh>
      <ThemedText.TextSecondary fontSize={12}>
        <Trans>LP</Trans>
      </ThemedText.TextSecondary>
      <Row justify="flex-end" mr="8px">
        <ThemedText.TextSecondary fontSize={12}>
          <Trans>Liquidity</Trans>
        </ThemedText.TextSecondary>
      </Row>
      <ThemedText.TextSecondary fontSize={12} ml="8px">
        <Trans>APR</Trans>
      </ThemedText.TextSecondary>
      <ThemedText.TextSecondary fontSize={12} ml="8px">
        <Trans>Unclaimed Fees</Trans>
      </ThemedText.TextSecondary>
      <Row ml="8px"></Row>
    </StyledLiquidityRowTh>
  )
}

const LiquidityRowTr = ({
  position,
  onStake,
  type,
}: {
  position: PositionDetails
  onStake: (position: PositionDetails) => void
  type: STKESTATE
}) => {
  const currenty = useCurrency(position.token0)
  const currenty1 = useCurrency(position.token1)
  return (
    <StyledLiquidityRowTr>
      <div>
        <Row>
          <NftIcon width={30} height={30} />
          <Column ml="8px" align="flex-start">
            <ThemedText.TextPrimary fontWeight={700}>
              {currenty?.symbol}-{currenty1?.symbol}
            </ThemedText.TextPrimary>
            <ThemedText.TextSecondary fontSize={14} mt="4px">
              {new Percent(position.fee, 1_000_000).toSignificant()}%
            </ThemedText.TextSecondary>
          </Column>
        </Row>
      </div>
      <Column align="flex-end" mr="8px">
        <ThemedText.TextPrimary fontWeight={700}>$214,349,313.99</ThemedText.TextPrimary>
        <ThemedText.TextSecondary fontSize={14} mt="4px">
          10,203,403 ROX
        </ThemedText.TextSecondary>
        <ThemedText.TextSecondary fontSize={14} mt="4px">
          18,283 ETH
        </ThemedText.TextSecondary>
      </Column>
      <ThemedText.TextPrimary fontWeight={700} ml="8px">
        313.99%
      </ThemedText.TextPrimary>
      <Column ml="8px" align="flex-start">
        <ThemedText.TextPrimary fontWeight={700}>313.99 ROX</ThemedText.TextPrimary>
        <ThemedText.TextPrimary fontWeight={700} mt="4px">
          102.4104 ETH
        </ThemedText.TextPrimary>
      </Column>
      <Row ml="8px" gap="sm">
        <StakeBtn onClick={() => onStake(position)}>{type}</StakeBtn>
        <ManageBtn>Manage</ManageBtn>
      </Row>
    </StyledLiquidityRowTr>
  )
}

interface FarmPoolProps {
  poolAddress?: string
  isOpen: boolean
  onDismiss: () => void
  token0: string
  token1: string
  fee: string
}

export default function FarmPoolModal({ isOpen, poolAddress, onDismiss, token0, token1, fee }: FarmPoolProps) {
  const { account } = useActiveChainId()
  const { positions } = useV3Positions(account) //用户NFT

  const nft = useV3NFTPositionManagerContract(true)
  const materChef = useMasterChefV3()

  const { positions: stakePositions } = useV3StakedPositions()

  const positionsfilter = positions?.filter((x) => {
    return isKeyEqual({ token0, token1, fee }, x)
  })

  const stakePositionsfilter = stakePositions?.filter((x) => isKeyEqual({ token0, token1, fee }, x))

  const onStake = useCallback(
    async (data: PositionDetails) => {
      if (!nft || !materChef || !account) return
      const base = utils.defaultAbiCoder.encode([`address`], [poolAddress])
      await nft['safeTransferFrom(address,address,uint256,bytes)'](account, materChef.address, data.tokenId, base)
    },
    [account, materChef, nft, poolAddress]
  )

  const onUnStake = useCallback(
    async (data: PositionDetails) => {
      if (!nft || !materChef || !account || !poolAddress) return
      await materChef.unstakeToken(poolAddress, data.tokenId)
    },
    [account, materChef, nft, poolAddress]
  )

  const modalHeader = useCallback(() => {
    return (
      <>
        <HeaderRow>
          <ThemedText.TextSecondary fontWeight={700} fontSize={14}>
            <Trans>Available(1)</Trans>
          </ThemedText.TextSecondary>
          <AddLiquidity>
            <AddIcon />
            <ThemedText.BodySmall ml="10px" fontWeight={700} fontSize={14} color="textTitle">
              <Trans>Add Liquidity</Trans>
            </ThemedText.BodySmall>
          </AddLiquidity>
        </HeaderRow>
        <LiquidityRowTh />
        {positionsfilter?.map((position, index) => (
          <LiquidityRowTr
            key={'positionsfilter' + index}
            type={STKESTATE.STAKE}
            onStake={onStake}
            position={position}
          />
        ))}
      </>
    )
  }, [onStake, positionsfilter])

  const modalBottom = useCallback(() => {
    return (
      <>
        <Row mt="16px">
          <HeaderRow>
            <ThemedText.TextSecondary fontWeight={700} fontSize={14}>
              <Trans>Staked(2)</Trans>
            </ThemedText.TextSecondary>
          </HeaderRow>
        </Row>
        <LiquidityRowTh />
        {stakePositionsfilter?.map((position, index) => {
          return (
            <LiquidityRowTr
              type={STKESTATE.UNSTAKE}
              key={'stakePOSITNS' + index}
              onStake={onUnStake}
              position={position}
            />
          )
        })}
      </>
    )
  }, [onUnStake, stakePositionsfilter])

  return (
    <Modal isOpen={isOpen} $scrollOverlay={true} onDismiss={onDismiss} maxHeight={90} width="800px" maxWidth={800}>
      <ConfirmationModalContent
        title={<Trans>ETH/USDT Farm Pool</Trans>}
        onDismiss={onDismiss}
        topContent={modalHeader}
        bottomContent={modalBottom}
        gap="0"
      />
    </Modal>
  )
}
