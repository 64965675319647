import { CurrencyAmount } from '@uniswap/sdk-core'
import { useActiveChainId } from 'connection/useActiveChainId'
import { BigNumber } from 'ethers/lib/ethers'
import { useLockStakeToken, useLockVeToken } from 'hooks/TokensCommon'
import { useLockContract } from 'hooks/useContract'
import { CallStateResult, useSingleCallResult, useSingleContractMultipleData } from 'lib/hooks/multicall'
import { useCallback, useMemo } from 'react'

import { LockStakeListProps } from './types'

export function useLockStakeList() {
  const tokenIds = useGetLockTokenIds()
  return useLockDetailTokenIds(tokenIds)
}

export function useGetLockTokenIds() {
  const { account } = useActiveChainId()
  const lockContract = useLockContract()
  const { result: NftbalanceOf } = useSingleCallResult(lockContract, 'balanceOf', [account])
  const listPams = useMemo(() => {
    if (!NftbalanceOf) return [[undefined]]
    return Array.from({ length: NftbalanceOf[0].toString() }, (item, index) => [account, index])
  }, [NftbalanceOf, account])

  const tokenIdResults = useSingleContractMultipleData(lockContract, 'tokenOfOwnerByIndex', listPams)
  const tokenIds = useMemo(() => {
    return tokenIdResults
      .map(({ result }) => result)
      .filter((result): result is CallStateResult => !!result)
      .map((result) => BigNumber.from(result[0]))
  }, [tokenIdResults])

  return tokenIds
}

function useLockDetailTokenIds(tokenIds: BigNumber[] | undefined): {
  loading: boolean
  tokenIdDetails?: LockStakeListProps[]
} {
  const lockContract = useLockContract()
  const velock = useLockVeToken()
  const stakeLockToken = useLockStakeToken()

  const inputs = useMemo(() => (tokenIds ? tokenIds.map((tokenId) => [BigNumber.from(tokenId)]) : []), [tokenIds])
  const lockeds = useSingleContractMultipleData(lockContract, 'locked', inputs)
  const balanceOfNFTs = useSingleContractMultipleData(lockContract, 'balanceOfNFT', inputs)

  const lockedsloading = useMemo(() => lockeds.some(({ loading }) => loading), [lockeds])
  const balanceOfNFTsloading = useMemo(() => balanceOfNFTs.some(({ loading }) => loading), [balanceOfNFTs])

  return useMemo(() => {
    if (lockedsloading || balanceOfNFTsloading) return { loading: true, tokenIdDetails: undefined }

    const data: LockStakeListProps[] = inputs.reduce((pre: any, curr, index) => {
      const { result: locked } = lockeds[index]
      const { result: balanceOfNFT } = balanceOfNFTs[index]
      if (!locked || !balanceOfNFT || !velock || !stakeLockToken) return pre
      const voteAmount = CurrencyAmount.fromRawAmount(velock, balanceOfNFT[0])
      const lockAmount = CurrencyAmount.fromRawAmount(stakeLockToken, locked.amount)
      pre.push({
        tokenId: curr.toString(),
        lockAmount,
        voteAmount,
        endTime: locked.end.toString(),
      })
      return pre
    }, [])

    return {
      loading: false,
      tokenIdDetails: data,
    }
  }, [balanceOfNFTs, balanceOfNFTsloading, inputs, lockeds, lockedsloading, stakeLockToken, velock])
}

export default function useLock() {
  const LockContract = useLockContract()
  const createLock = useCallback(
    async (amount: any) => {
      await LockContract?.create_lock(amount, 86400 * 7 * 52)
    },
    [LockContract]
  )
  return { createLock }
}
