import { Trans } from '@lingui/macro'
import { ButtonConnect } from 'components/Button'
import Row from 'components/Row'
import { PageWrapper } from 'components/swap/styleds'
import { TitleDesc, TitleRow } from 'nft/components/profile/list/shared'
import { ThemedText } from 'theme'

import HeaderWrapper from './HeaderWrapper'
import VoteInfo from './VoteInfo'

export default function Vote() {
  return (
    <PageWrapper data-testid="vote-page">
      <TitleRow>
        <ThemedText.LargeHeader>
          <Trans>Vote</Trans>
        </ThemedText.LargeHeader>
      </TitleRow>
      <TitleDesc>
        <ThemedText.HeaderDesc lineHeight="16px">
          <Trans>
            Earn bribes and trading fees by voting. Select your veROX and allocate 100% of your votes to one or more
            pools.
          </Trans>
        </ThemedText.HeaderDesc>
      </TitleDesc>
      <Row mt="20px">
        <ButtonConnect>Add Liquidity</ButtonConnect>
      </Row>
      <VoteInfo />
      <HeaderWrapper />
    </PageWrapper>
  )
}
