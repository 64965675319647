import { Trans } from '@lingui/macro'
import { TableDataContainer } from 'components/Table'
import { MAX_WIDTH_MEDIA_BREAKPOINT } from 'components/Tokens/constants'
import { useActiveChainId } from 'connection/useActiveChainId'
import { PAGE_SIZE } from 'graphql/data/TopTokens'
import { useV3Positions } from 'hooks/useV3Positions'
import { useFetchVoter } from 'pages/Vote/hooks'
import { ReactNode } from 'react'
import { AlertTriangle } from 'react-feather'
import styled from 'styled-components/macro'

import { HeaderRow, LoadedRow, LoadingRow } from './LiquidityRow'

const GridContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: ${MAX_WIDTH_MEDIA_BREAKPOINT};
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  align-items: center;
`

const NoTokenDisplay = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  height: 60px;
  color: ${({ theme }) => theme.textSecondary};
  font-size: 16px;
  font-weight: 500;
  align-items: center;
  padding: 0px 28px;
  gap: 8px;
`

function NoTokensState({ message }: { message: ReactNode }) {
  return (
    <GridContainer>
      <HeaderRow />
      <NoTokenDisplay>{message}</NoTokenDisplay>
    </GridContainer>
  )
}

const LoadingRows = ({ rowCount }: { rowCount: number }) => (
  <>
    {Array(rowCount)
      .fill(null)
      .map((_, index) => {
        return <LoadingRow key={index} first={index === 0} last={index === rowCount - 1} />
      })}
  </>
)

function LoadingTokenTable({ rowCount = PAGE_SIZE }: { rowCount?: number }) {
  return (
    <GridContainer>
      <HeaderRow />
      <TableDataContainer>
        <LoadingRows rowCount={rowCount} />
      </TableDataContainer>
    </GridContainer>
  )
}

export default function Table() {
  const { account } = useActiveChainId()
  // const chainName = validateUrlChainParam(useParams<{ chainName?: string }>().chainName)

  const { positions, loading: positionsLoading } = useV3Positions(account)

  const { voteList: liquidityStakeList } = useFetchVoter()

  const loadingLiquiditys = positionsLoading
  console.log({ positions, liquidityStakeList })

  const liquidityList = liquidityStakeList

  console.log('liquidityList', liquidityList)

  // [
  //   { token: 'ROX-ETH', liquidityAmount: 214349313.99, liquidityPower: 214349313.99, wallet: 2, staked: 1 },
  //   { token: 'ROX-ETH', liquidityAmount: 214349313.99, liquidityPower: 214349313.99, wallet: 2, staked: 1 },
  //   { token: 'ROX-ETH', liquidityAmount: 214349313.99, liquidityPower: 214349313.99, wallet: 2, staked: 1 },
  // ]
  /* loading and error state */
  if (loadingLiquiditys && !liquidityList) {
    return <LoadingTokenTable rowCount={PAGE_SIZE} />
  } else if (!liquidityList) {
    return (
      <NoTokensState
        message={
          <>
            <AlertTriangle size={16} />
            <Trans>An error occurred loading liquidityList. Please try again.</Trans>
          </>
        }
      />
    )
  } else if (liquidityList?.length === 0) {
    return <NoTokensState message={<Trans>No liquidityList found</Trans>} />
  } else {
    return (
      <>
        <HeaderRow />
        <TableDataContainer>
          {liquidityList.map(
            (liquidity, index) =>
              liquidity?.poolname && (
                <LoadedRow
                  key={index}
                  liquidityListIndex={index}
                  liquidityListLength={liquidityList.length}
                  liquidity={liquidity}
                />
              )
          )}
        </TableDataContainer>
      </>
    )
  }
}
