import BigNumberFlat from 'bignumber.js'
import { BigNumber } from 'ethers'
export interface PoolStruct {
  poolname: string
  poolAddress: string
  token0Symble: string
  token0Address: string
  token0Decimal: string
  token1Symble: string
  token1Address: string
  token1Decimal: string
  fee: string
  votingRewardAddress?: string
  swapMinningAddress?: string
  rewardsList?: string[]
  poolWeightAmount?: BigNumberFlat
  poolWeightRatio?: BigNumberFlat
  tokenid?: string
  totalVolume?: BigNumber
  myVolume?: BigNumber
}

export const AllPools: PoolStruct[] = [
  {
    poolname: 'XXX-ZZZ-LP',
    poolAddress: '0x523714d690Fc00A50D5bdf5970f08c92184c9886',
    token0Symble: 'XXX',
    token0Address: '0x52c1DA18f18aDD668C2f348405771341384C2329',
    token0Decimal: '18',
    token1Symble: 'ZZZ',
    token1Address: '0x6C710780131dc5a17Bc359af1458406899389670',
    token1Decimal: '18',
    fee: '10000',
    votingRewardAddress: '0x892E415170D14BA00cd13CB8b74034b1B6d3780a',
    swapMinningAddress: '0x649C5aE53202E06abCa0cefa4B7F0C3932272416',
  },
  // {
  //   poolname: 'YangGe-CHAOGE-LP',
  //   poolAddress: '0xbd38a33dc4221b7558d45de4594fd2e1e8cd21fe',
  //   token0Symble: 'YangGe',
  //   token0Address: '0x164D5aE54A0A6f9B5C008eFF1a85AD48306B6847',
  //   token0Decimal: '18',
  //   token1Symble: 'CHAOGE',
  //   token1Address: '0xfa0335c776ff4355536435c5588b7fe7ebec5509',
  //   token1Decimal: '18',
  //   fee: '10000',
  //   votingRewardAddress: '0xD641e6557042F6921de269fD98B0992c3e2DF226',
  //   swapMinningAddress: '0xFae4598704dcB8ea00Cb679284AD237c0399958e',
  // },
]
