import { Trans } from '@lingui/macro'
import searchIcon from 'assets/imgs/search.svg'
import xIcon from 'assets/svg/x.svg'
import { useState } from 'react'
import styled from 'styled-components/macro'

import { MEDIUM_MEDIA_BREAKPOINT } from '../../components/Tokens/constants'
const ICON_SIZE = '20px'

const SearchInput = styled.input`
  background: no-repeat scroll 8px 8px;
  background-image: url(${searchIcon});
  background-size: 16px 16px;
  background-position: 8px center;
  background-color: transparent;
  border-radius: 16px;
  border: 1px solid ${({ theme }) => theme.connectBtnBackground};
  height: 36px;
  width: 100%;
  font-size: 14px;
  padding-left: 32px;
  color: ${({ theme }) => theme.textPrimary};
  transition-duration: ${({ theme }) => theme.transition.duration.fast};

  :hover {
    border-color: ${({ theme }) => theme.textPrimary};
  }

  :focus {
    outline: none;
    border-color: ${({ theme }) => theme.textPrimary};
  }

  ::placeholder {
    color: ${({ theme }) => theme.textTertiary};
  }

  ::-webkit-search-cancel-button {
    -webkit-appearance: none;
    appearance: none;
    height: ${ICON_SIZE};
    width: ${ICON_SIZE};
    background-image: url(${xIcon});
    margin-right: 10px;
    background-size: ${ICON_SIZE} ${ICON_SIZE};
    cursor: pointer;
  }

  @media only screen and (max-width: ${MEDIUM_MEDIA_BREAKPOINT}) {
    width: 100%;
  }
`

export default function SearchBar() {
  const [searchQuery, setSearchQuery] = useState<string>('')

  return (
    <Trans
      render={({ translation }) => (
        <SearchInput
          data-cy="liquidity-search-input"
          type="search"
          placeholder={`${translation}`}
          id="search-liquidity"
          autoComplete="off"
          value={searchQuery}
          onChange={({ target: { value } }) => setSearchQuery(value)}
        />
      )}
    >
      Search
    </Trans>
  )
}
