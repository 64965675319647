import { Trans } from '@lingui/macro'
import { Box } from '@mui/material'
import styled from 'styled-components/macro'
import { ThemedText } from 'theme'

import BasicDatePicker from './Picker'

const LockPickerBox = styled(Box)`
  display: flex;
  flex-direction: column;
  grid-gap: 8px;
  width: 100%;
  margin-top: 8px;
`

interface PickerProps {
  title: string
}
export default function LockPicker({ title }: PickerProps) {
  return (
    <LockPickerBox>
      <ThemedText.HeadNav054>
        <Trans>{title}</Trans>
      </ThemedText.HeadNav054>
      <BasicDatePicker />
    </LockPickerBox>
  )
}
