import { Trans } from '@lingui/macro'
import { ReactComponent as ChartIcon } from 'assets/svg/trade/chartIcon.svg'
import Column from 'components/Column'
import Row from 'components/Row'
import { Box } from 'rebass'
import styled from 'styled-components/macro'
import { ThemedText } from 'theme/components/text'

import { useMockPool } from './Hooks'
export const ChartInfoCard = styled(Box)`
  background: ${({ theme }) => theme.backgroundOutside};
  border-radius: 16px;
  fill: #fff;
  filter: drop-shadow(0px 0px 32px rgba(0, 0, 0, 0.25));
`
const ColumnBox = styled(Column)`
  gap: 8px;
  align-items: flex-start;
`
const OpenBox = styled(Row)`
  width: 100%;
  height: 18px;
  gap: 4px;
  > div {
    height: 6px;
  }
  .long {
    width: 30%;
    background: ${({ theme }) => theme.tradeGreen};
  }
  .short {
    flex: 1;
    background: ${({ theme }) => theme.tradeRed};
  }
`

const ChartBox = styled(Box)`
  padding: 0px 8px 8px;
  filter: drop-shadow(0px 0px 32px rgba(0, 0, 0, 0.25));

  .charts {
    height: 350px;
    border-radius: 16px;
    background: rgba(24, 24, 24, 1);
  }
`
function ChartInfo() {
  const { pool } = useMockPool()
  return (
    <ChartInfoCard>
      <Box p="21px 24px 14px 16px">
        <Row>
          <Row gap="30px">
            <Row width="auto" mr="28px">
              <Column>
                <Row>
                  <ThemedText.TextPrimary fontWeight={700}>{pool?.token0.symbol}</ThemedText.TextPrimary>
                  <ThemedText.HeadNav054>/{pool?.token1.symbol}</ThemedText.HeadNav054>
                </Row>
                <ThemedText.TextGreen>29,364.42</ThemedText.TextGreen>
              </Column>
            </Row>
            <ColumnBox>
              <ThemedText.BodySecondary fontSize="12px">24h high</ThemedText.BodySecondary>
              <ThemedText.TextPrimary fontWeight={700}>29,364.42</ThemedText.TextPrimary>
            </ColumnBox>
            <ColumnBox gap="sm">
              <ThemedText.BodySecondary fontSize="12px">
                <Trans>24h low</Trans>
              </ThemedText.BodySecondary>
              <ThemedText.TextPrimary fontWeight={700}>29,364.42</ThemedText.TextPrimary>
            </ColumnBox>
            <ColumnBox>
              <ThemedText.BodySecondary fontSize="12px">
                <Trans>Open Interest</Trans>
              </ThemedText.BodySecondary>
              <OpenBox>
                <div className="long"></div>
                <div className="short"></div>
              </OpenBox>
            </ColumnBox>
            <ColumnBox>
              <ThemedText.BodySecondary fontSize="12px">
                <Trans>Funding Rate</Trans>
              </ThemedText.BodySecondary>
              <ThemedText.TextPrimary fontWeight={700}>29,364.42</ThemedText.TextPrimary>
            </ColumnBox>
            <ColumnBox>
              <ThemedText.BodySecondary fontSize="12px">
                <Trans>Premium</Trans>
              </ThemedText.BodySecondary>
              <ThemedText.TextPrimary fontWeight={700}>29,364.42</ThemedText.TextPrimary>
            </ColumnBox>
          </Row>

          <ChartIcon style={{ cursor: 'pointer' }} />
        </Row>
      </Box>
      <ChartBox>
        <Box className="charts"></Box>
      </ChartBox>
    </ChartInfoCard>
  )
}

export default ChartInfo
