import { Trans } from '@lingui/macro'
import { ReactComponent as GagueIcon } from 'assets/imgs/gague.svg'
import LearnMore from 'components/LearnMore'
import Row from 'components/Row'
import { PageWrapper } from 'components/swap/styleds'
import { TitleDesc, TitleRow } from 'nft/components/profile/list/shared'
import styled from 'styled-components/macro'
import { ThemedText } from 'theme'

const GagueBox = styled(Row)`
  margin-top: 46px;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 16px;
  .gague-item {
    padding: 60px 16px 54px 16px;
    border-radius: 34px;
    border: 1px solid ${({ theme }) => theme.primaryBorder};
  }
`

export default function Gague() {
  return (
    <PageWrapper data-testid="gague-page">
      <TitleRow>
        <ThemedText.LargeHeader>
          <Trans>Gauges</Trans>
        </ThemedText.LargeHeader>
      </TitleRow>
      <TitleDesc>
        <ThemedText.HeaderDesc lineHeight="16px">
          <Trans>Create a new Gauge or add a bribe on existing gauge.</Trans>
        </ThemedText.HeaderDesc>
      </TitleDesc>
      <Row mt="16px">
        <LearnMore />
      </Row>
      <GagueBox>
        <Row flexDirection="column" justifyContent="center" className="gague-item">
          <GagueIcon />
          <ThemedText.LargeHeader fontSize={24} mt="50px">
            <Trans>Add Gauges</Trans>
          </ThemedText.LargeHeader>
          <ThemedText.BodySmall color="textTitle" mt="50px">
            <Trans>Create a new gauge which can be used for staking and voting.</Trans>
          </ThemedText.BodySmall>
        </Row>
        <Row flexDirection="column" justifyContent="center" className="gague-item">
          <GagueIcon />
          <ThemedText.LargeHeader fontSize={24} mt="50px">
            <Trans>Add Bribes</Trans>
          </ThemedText.LargeHeader>
          <ThemedText.BodySmall color="textTitle" mt="50px">
            <Trans>Add a bribe reward for an existing gauge to incentivize votes on it.</Trans>
          </ThemedText.BodySmall>
        </Row>
      </GagueBox>
    </PageWrapper>
  )
}
