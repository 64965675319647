import { Trans } from '@lingui/macro'
import { ChainId, Currency, CurrencyAmount, Percent, Price } from '@uniswap/sdk-core'
import { Pool } from '@uniswap/v3-sdk'
import { useActiveChainId } from 'connection/useActiveChainId'
import useAutoSlippageTolerance from 'hooks/useAutoSlippageTolerance'
import { usePositionTrade } from 'hooks/usePositionsTrade'
import tryParseCurrencyAmount from 'lib/utils/tryParseCurrencyAmount'
import { ParsedQs } from 'qs'
import { ReactNode, useCallback, useEffect, useMemo } from 'react'
import { AnyAction } from 'redux'
import { useAppDispatch } from 'state/hooks'
import { useTradeSliderState } from 'state/trade/state'
import { useUserSlippageToleranceWithDefault } from 'state/user/hooks'
import { BN, toWei } from 'utils/bn'

import { TOKEN_SHORTHANDS } from '../../constants/tokens'
import { useCurrency } from '../../hooks/Tokens'
import useParsedQueryString from '../../hooks/useParsedQueryString'
import { compareAddress, isAddress } from '../../utils'
import { useCurrencyBalances } from '../connection/hooks'
import {
  Field,
  positionSelectCurrency,
  positionSwitchCurrencies,
  positionTypeInput,
  replacePositionState,
  setLong,
  setSliederLever,
} from './actions'
import { SwapState } from './reducer'

export function usePositionSwapActionHandlers(dispatch: React.Dispatch<AnyAction>): {
  onCurrencySelection: (field: Field, currency: Currency) => void
  onSwitchTokens: () => void
  onUserInput: (field: Field, typedValue: string) => void
  onChangeLong: (isLong: boolean) => void
  onSliederLever: (sliederLever: number) => void
} {
  const onCurrencySelection = useCallback(
    (field: Field, currency: Currency) => {
      dispatch(
        positionSelectCurrency({
          field,
          currencyId: currency.isToken ? currency.address : currency.isNative ? 'ETH' : '',
        })
      )
    },
    [dispatch]
  )

  const onSwitchTokens = useCallback(() => {
    dispatch(positionSwitchCurrencies())
  }, [dispatch])

  const onUserInput = useCallback(
    (field: Field, typedValue: string) => {
      dispatch(positionTypeInput({ field, typedValue }))
    },
    [dispatch]
  )

  const onChangeLong = useCallback(
    (isLong: boolean) => {
      dispatch(setLong({ isLong }))
    },
    [dispatch]
  )

  const onSliederLever = useCallback(
    (sliederLever: number) => {
      dispatch(setSliederLever({ sliederLever }))
    },
    [dispatch]
  )

  return {
    onSwitchTokens,
    onCurrencySelection,
    onUserInput,
    onChangeLong,
    onSliederLever,
  }
}

const BAD_RECIPIENT_ADDRESSES: { [address: string]: true } = {
  '0x5C69bEe701ef814a2B6a3EDD4B1652CB9cc5aA6f': true, // v2 factory
  '0xf164fC0Ec4E93095b804a4795bBe1e041497b92a': true, // v2 router 01
  '0x7a250d5630B4cF539739dF2C5dAcb4c659F2488D': true, // v2 router 02
}

type SwapInfo = {
  currencies: { [field in Field]?: Currency | null }
  currencyBalances: { [field in Field]?: CurrencyAmount<Currency> }
  parsedAmount?: CurrencyAmount<Currency>
  inputError?: ReactNode
  tradePool: {
    isLoading: boolean
    pool?: Pool
    poolAddr?: string
  }
  useInpuntAmount: {
    inputAmount?: CurrencyAmount<Currency>
    outputAount?: CurrencyAmount<Currency>
  }
  allowedSlippage: Percent
  autoSlippage: Percent
}

// from the current swap inputs, compute the best trade and return it.
export function usePositionSwapInfo(
  state: SwapState,
  chainId: ChainId | undefined,
  prcie?: Price<Currency, Currency>
): SwapInfo {
  const { account } = useActiveChainId()
  const { percent: sliederLever } = useTradeSliderState()

  const {
    independentField,
    typedValue,
    [Field.INPUT]: { currencyId: inputCurrencyId },
    [Field.OUTPUT]: { currencyId: outputCurrencyId },
  } = state

  const inputCurrency = useCurrency(inputCurrencyId, chainId)
  const outputCurrency = useCurrency(outputCurrencyId, chainId)
  const to: string | null = account ?? null

  const relevantTokenBalances = useCurrencyBalances(
    account ?? undefined,
    useMemo(() => [inputCurrency ?? undefined, outputCurrency ?? undefined], [inputCurrency, outputCurrency])
  )

  const isExactIn: boolean = independentField === Field.INPUT
  const parsedAmount = useMemo(
    () => tryParseCurrencyAmount(typedValue, (isExactIn ? inputCurrency : outputCurrency) ?? undefined),
    [inputCurrency, isExactIn, outputCurrency, typedValue]
  )

  const tradePool = usePositionTrade(inputCurrency ?? undefined, outputCurrency ?? undefined)

  const useInpuntAmount: {
    inputAmount?: CurrencyAmount<Currency>
    outputAount?: CurrencyAmount<Currency>
  } = useMemo(() => {
    if (!tradePool.pool || !inputCurrency || !outputCurrency || !parsedAmount || !prcie)
      return {
        inputAmount: parsedAmount,
        outputAount: undefined,
      }
    let inputAmount: any
    let outputAount: any

    console.log('parsedAmount', parsedAmount)

    if (compareAddress(outputCurrency.wrapped.address, tradePool.pool.token1.address)) {
      inputAmount = parsedAmount
      const outAmount = BN(typedValue).times(sliederLever).times(BN(prcie.toFixed())).toFixed()
      const pasAmount = toWei(outAmount, outputCurrency.decimals).toFixed()
      try {
        outputAount = CurrencyAmount.fromRawAmount(outputCurrency, pasAmount)
      } catch (error) {
        console.log('error', error)
      }
    } else {
      inputAmount = parsedAmount
      const outAmount = BN(sliederLever).times(typedValue).times(BN(prcie.invert().toFixed())).toFixed()
      const pasAmount = toWei(outAmount, outputCurrency.decimals).toFixed()
      try {
        outputAount = CurrencyAmount.fromRawAmount(outputCurrency, pasAmount)
      } catch (error) {
        console.log('error', error)
      }
    }

    return {
      inputAmount,
      outputAount,
    }
  }, [inputCurrency, outputCurrency, parsedAmount, prcie, sliederLever, tradePool.pool, typedValue])

  const currencyBalances = useMemo(
    () => ({
      [Field.INPUT]: relevantTokenBalances[0],
      [Field.OUTPUT]: relevantTokenBalances[1],
    }),
    [relevantTokenBalances]
  )

  const currencies: { [field in Field]?: Currency | null } = useMemo(
    () => ({
      [Field.INPUT]: inputCurrency,
      [Field.OUTPUT]: outputCurrency,
    }),
    [inputCurrency, outputCurrency]
  )

  // allowed slippage for classic trades is either auto slippage, or custom user defined slippage if auto slippage disabled
  const classicAutoSlippage = useAutoSlippageTolerance(undefined)

  // slippage for uniswapx trades is defined by the quote response
  const uniswapXAutoSlippage = undefined

  // Roguex Interface recommended slippage amount
  const autoSlippage = uniswapXAutoSlippage ?? classicAutoSlippage
  const classicAllowedSlippage = useUserSlippageToleranceWithDefault(autoSlippage)

  // slippage amount used to submit the trade
  const allowedSlippage = uniswapXAutoSlippage ?? classicAllowedSlippage

  const inputError = useMemo(() => {
    let inputError: ReactNode | undefined

    if (!account) {
      inputError = <Trans>Connect Wallet</Trans>
    }

    if (!currencies[Field.INPUT] || !currencies[Field.OUTPUT]) {
      inputError = inputError ?? <Trans>Select a token</Trans>
    }

    if (!parsedAmount) {
      inputError = inputError ?? <Trans>Enter an amount</Trans>
    }

    const formattedTo = isAddress(to)
    if (!to || !formattedTo) {
      inputError = inputError ?? <Trans>Enter a recipient</Trans>
    } else {
      if (BAD_RECIPIENT_ADDRESSES[formattedTo]) {
        inputError = inputError ?? <Trans>Invalid recipient</Trans>
      }
    }

    // compare input balance to max input based on version
    // const [balanceIn, maxAmountIn] = [currencyBalances[Field.INPUT], trade?.trade?.maximumAmountIn(allowedSlippage)]

    // if (balanceIn && maxAmountIn && balanceIn.lessThan(maxAmountIn)) {
    //   inputError = <Trans>Insufficient {balanceIn.currency.symbol} balance</Trans>
    // }

    return inputError
  }, [account, currencies, parsedAmount, to])

  return useMemo(
    () => ({
      currencies,
      currencyBalances,
      parsedAmount,
      inputError,
      tradePool,
      autoSlippage,
      allowedSlippage,
      useInpuntAmount,
    }),
    [allowedSlippage, autoSlippage, currencies, currencyBalances, inputError, parsedAmount, tradePool, useInpuntAmount]
  )
}

function parseCurrencyFromURLParameter(urlParam: ParsedQs[string]): string {
  if (typeof urlParam === 'string') {
    const valid = isAddress(urlParam)
    if (valid) return valid
    const upper = urlParam.toUpperCase()
    if (upper === 'ETH') return 'ETH'
    if (upper in TOKEN_SHORTHANDS) return upper
  }
  return ''
}

function parseTokenAmountURLParameter(urlParam: any): string {
  return typeof urlParam === 'string' && !isNaN(parseFloat(urlParam)) ? urlParam : ''
}

function parseIndependentFieldURLParameter(urlParam: any): Field {
  return typeof urlParam === 'string' && urlParam.toLowerCase() === 'output' ? Field.OUTPUT : Field.INPUT
}

const ENS_NAME_REGEX = /^[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&/=]*)?$/
const ADDRESS_REGEX = /^0x[a-fA-F0-9]{40}$/
function validatedRecipient(recipient: any): string | null {
  if (typeof recipient !== 'string') return null
  const address = isAddress(recipient)
  if (address) return address
  if (ENS_NAME_REGEX.test(recipient)) return recipient
  if (ADDRESS_REGEX.test(recipient)) return recipient
  return null
}

export function queryParametersToSwapState(parsedQs: ParsedQs): SwapState {
  let inputCurrency = parseCurrencyFromURLParameter(parsedQs.inputCurrency)
  let outputCurrency = parseCurrencyFromURLParameter(parsedQs.outputCurrency)
  const typedValue = parseTokenAmountURLParameter(parsedQs.exactAmount)
  const independentField = parseIndependentFieldURLParameter(parsedQs.exactField)

  if (inputCurrency === '' && outputCurrency === '' && typedValue === '' && independentField === Field.INPUT) {
    // Defaults to having the native currency selected
    inputCurrency = 'ETH'
  } else if (inputCurrency === outputCurrency) {
    // clear output if identical
    outputCurrency = ''
  }

  // const recipient = validatedRecipient(parsedQs.recipient)

  return {
    [Field.INPUT]: {
      currencyId: inputCurrency === '' ? null : inputCurrency ?? null,
    },
    [Field.OUTPUT]: {
      currencyId: outputCurrency === '' ? null : outputCurrency ?? null,
    },
    typedValue,
    independentField,
    isLong: true,
    sliederLever: 1.1,
  }
}

// updates the swap state to use the defaults for a given network
function useDefaultsFromURLSearch(): SwapState {
  const { chainId } = useActiveChainId()
  const dispatch = useAppDispatch()
  const parsedQs = useParsedQueryString()

  const parsedSwapState = useMemo(() => {
    return queryParametersToSwapState(parsedQs)
  }, [parsedQs])

  useEffect(() => {
    if (!chainId) return
    const inputCurrencyId = parsedSwapState[Field.INPUT].currencyId ?? undefined
    const outputCurrencyId = parsedSwapState[Field.OUTPUT].currencyId ?? undefined

    dispatch(
      replacePositionState({
        typedValue: parsedSwapState.typedValue,
        field: parsedSwapState.independentField,
        inputCurrencyId,
        outputCurrencyId,
        isLong: parsedSwapState.isLong,
        sliederLever: parsedSwapState.sliederLever,
      })
    )
  }, [dispatch, chainId, parsedSwapState])

  return parsedSwapState
}
