import { useActiveChainId } from 'connection/useActiveChainId'
import { useTradePool } from 'hooks/useContract'
import { useCallback, useEffect, useState } from 'react'
import { Box } from 'rebass'
import styled from 'styled-components/macro'

import { ChartInfoCard } from '../ChartInfo'
import { useGetPositions, useMockPool } from '../Hooks'
import NavTab from './NavTab'
import PositionTable from './PositionTable/PositionTable'
const NavTabBox = styled(Box)`
  border-bottom: 1px solid ${({ theme }) => theme.swapSectionBorder};
`
function NavTabList() {
  const [curr, setCurr] = useState<number>(0)
  const { account } = useActiveChainId()

  const { tradePool } = useMockPool()
  const positions = useGetPositions(tradePool)
  const tradePoolContract = useTradePool(tradePool)

  useEffect(() => {
    console.log('positions', positions)
  }, [positions])

  const dataLable = [`Position(${positions?.length || 0})`, 'Order(0)', 'History']

  const closePosition = useCallback(
    async (data: any) => {
      if (!tradePoolContract || !account) return
      console.log('data', data)
      await tradePoolContract.decreasePosition(account, data.long0, data.collateral, data.size, account)
    },
    [account, tradePoolContract]
  )

  return (
    <ChartInfoCard>
      <NavTabBox pl="18px">
        <NavTab Tabtype={1} tabList={dataLable} curr={curr} setCurr={setCurr} />
      </NavTabBox>
      <PositionTable positions={positions} closePosition={closePosition} />
    </ChartInfoCard>
  )
}

export default NavTabList
