import { Trans } from '@lingui/macro'
import { AutoColumn } from 'components/Column'
import { IconLoadingBubble, LoadingBubble, MediumLoadingBubble } from 'components/Loading'
import Row from 'components/Row'
import { useMockPool } from 'pages/Trade/Hooks'
import { CSSProperties, ForwardedRef, forwardRef, ReactNode, useMemo, useState } from 'react'
import { Box } from 'rebass'
import { ThemedText } from 'theme'
import { BN, fromSqrt96Wei, fromWei, toFromBN } from 'utils/bn'

import {
  LongIcon,
  PoolCell,
  StyledHeaderRow,
  StyledRow,
  TextLeftCell,
  TextPrimarySpace,
  TextRightCell,
} from '../styles'
import { TokenSortMethod } from './state'

/* Token Row: skeleton row component */
function PositionRow({
  header,
  Position,
  Size,
  NetValue,
  Collateral,
  MarkPrice,
  EntryPrice,
  LiqPrice,
  Acitons,
  ...rest
}: {
  first?: boolean
  header: boolean
  $loading?: boolean
  Position: ReactNode
  Size: ReactNode
  NetValue: ReactNode
  Collateral: ReactNode
  MarkPrice: ReactNode
  EntryPrice: ReactNode
  LiqPrice: ReactNode
  Acitons: ReactNode
  last?: boolean
  style?: CSSProperties
}) {
  const rowCells = (
    <>
      <PoolCell data-testid="pool-cell">{Position}</PoolCell>
      <TextRightCell data-testid="tvl-cell">{Size}</TextRightCell>
      <TextLeftCell data-testid="vApr-cell">{NetValue}</TextLeftCell>
      <TextRightCell data-testid="fees-cell">{Collateral}</TextRightCell>
      <TextRightCell data-testid="bribes-cell">{MarkPrice}</TextRightCell>
      <TextRightCell data-testid="total-rewards-cell">{EntryPrice}</TextRightCell>
      <TextRightCell data-testid="total-votes-cell">{LiqPrice}</TextRightCell>
      <TextRightCell data-testid="total-votes-cell">{Acitons}</TextRightCell>
    </>
  )
  if (header) return <StyledHeaderRow data-testid="vote-header-row">{rowCells}</StyledHeaderRow>
  return <StyledRow {...rest}>{rowCells}</StyledRow>
}

/* Header Row: top header row component for table */
export function HeaderRow() {
  return (
    <PositionRow
      header={true}
      Position={
        <Box pl="16px">
          <Trans>{TokenSortMethod.Position}</Trans>
        </Box>
      }
      Size={
        <Row>
          <Trans>{TokenSortMethod.Size}</Trans>
        </Row>
      }
      NetValue={
        <Row>
          <Trans>{TokenSortMethod.NetValue}</Trans>
        </Row>
      }
      Collateral={
        <Row>
          <Trans>{TokenSortMethod.Collateral}</Trans>
        </Row>
      }
      MarkPrice={
        <Row>
          <Trans>{TokenSortMethod.MarkPrice}</Trans>
        </Row>
      }
      EntryPrice={
        <Row>
          <Trans>{TokenSortMethod.EntryPrice}</Trans>
        </Row>
      }
      LiqPrice={
        <Row>
          <Trans>{TokenSortMethod.LiqPrice}</Trans>
        </Row>
      }
      Acitons={
        <Row>
          <Trans>{TokenSortMethod.operation}</Trans>
        </Row>
      }
    />
  )
}
/* Loading State: row component with loading bubbles */
export function LoadingRow(props: { first?: boolean; last?: boolean }) {
  return (
    <PositionRow
      header={false}
      $loading
      Position={
        <Row>
          <IconLoadingBubble width="48px" height="48px" />
          <MediumLoadingBubble />
        </Row>
      }
      Size={
        <Row flexDirection="column" gap="8px">
          <MediumLoadingBubble />
        </Row>
      }
      NetValue={
        <Row>
          <LoadingBubble />
        </Row>
      }
      Collateral={
        <Row flexDirection="column" gap="8px">
          <MediumLoadingBubble />
        </Row>
      }
      MarkPrice={
        <Row flexDirection="column" gap="8px">
          <MediumLoadingBubble />
        </Row>
      }
      EntryPrice={
        <Row flexDirection="column" gap="8px">
          <MediumLoadingBubble />
        </Row>
      }
      LiqPrice={
        <Row flexDirection="column" gap="8px">
          <MediumLoadingBubble />
        </Row>
      }
      Acitons={
        <Row flexDirection="column" gap="8px">
          <MediumLoadingBubble />
        </Row>
      }
      {...props}
    />
  )
}

interface LoadedRowProps {
  voteListIndex: number
  voteListLength: number
  vote: any
  closePosition: (data: any) => void
}

/* Loaded State: row component with token information */
export const LoadedRow = forwardRef((props: LoadedRowProps, ref: ForwardedRef<HTMLDivElement>) => {
  const { voteListIndex, voteListLength, vote, closePosition } = props
  const [value, setValue] = useState<any>('')
  function handleInput(val: any) {
    setValue(val)
  }
  const { pool } = useMockPool()

  const netVlue = useMemo(() => {
    if (vote.hasProfit) {
      return BN(vote.collateral.toString()).plus(vote.delta.toString()).toFixed()
    } else {
      return BN(vote.collateral.toString()).minus(vote.delta.toString()).toFixed()
    }
  }, [vote.collateral, vote.delta, vote.hasProfit])

  const netVlueRatio = useMemo(() => {
    const str = BN(vote.delta.toString()).div(vote.collateral.toString()).times(100).toFixed(2)
    return vote.hasProfit ? `+${str}` : `-${str}`
  }, [vote.collateral, vote.delta, vote.hasProfit])
  return (
    <div ref={ref} data-testid={`vote-table-row-${vote.token}`}>
      <PositionRow
        header={false}
        Position={
          <AutoColumn>
            <ThemedText.TextPrimary style={TextPrimarySpace} fontWeight={700} fontSize={16} ml="16px">
              {/* {vote.long0} */}
              {pool?.token0.symbol}-{pool?.token1.symbol}
            </ThemedText.TextPrimary>
            <Row gap="xs">
              <ThemedText.TextPrimary fontWeight={400} fontSize={14} ml="16px">
                {BN(vote.size.toString()).div(vote.collateral.toString()).toFixed(2)}x
              </ThemedText.TextPrimary>
              <LongIcon>
                <ThemedText.LargeHeader fontWeight={400} fontSize={12}>
                  {vote.long0 ? 'L' : 'S'}
                </ThemedText.LargeHeader>
              </LongIcon>
            </Row>
          </AutoColumn>
        }
        Size={
          <AutoColumn>
            <ThemedText.TextPrimary fontWeight={700}>
              {toFromBN(vote.size).toFixed(4)} {vote.long0 ? pool?.token1.symbol : pool?.token0.symbol}
            </ThemedText.TextPrimary>
          </AutoColumn>
        }
        NetValue={
          <AutoColumn>
            <ThemedText.TextPrimary fontWeight={700}>{fromWei(netVlue).toFixed(4)}</ThemedText.TextPrimary>
            <ThemedText.TextGreen fontWeight={400}>{netVlueRatio}%</ThemedText.TextGreen>
          </AutoColumn>
        }
        Collateral={
          <AutoColumn>
            <ThemedText.TextPrimary fontWeight={700}>{toFromBN(vote.collateral).toFixed(2)}</ThemedText.TextPrimary>
          </AutoColumn>
        }
        MarkPrice={
          <AutoColumn>
            <ThemedText.TextPrimary fontWeight={700}>
              {fromSqrt96Wei(pool ? pool?.sqrtRatioX96?.toString() : 0).toFixed(4, 1)}
            </ThemedText.TextPrimary>
          </AutoColumn>
        }
        EntryPrice={
          <AutoColumn>
            <ThemedText.TextPrimary fontWeight={700}>
              {fromSqrt96Wei(vote.sqrtPriceX96.toString()).toFixed(2)}
            </ThemedText.TextPrimary>
          </AutoColumn>
        }
        LiqPrice={
          <AutoColumn>
            <ThemedText.TextPrimary fontWeight={700}>
              {fromSqrt96Wei(vote.liqSqrtPriceX96.toString()).toFixed(4)}
            </ThemedText.TextPrimary>
          </AutoColumn>
        }
        Acitons={
          <Row gap="xmd">
            <ThemedText.TextPrimary fontWeight={400} onClick={() => closePosition(vote)}>
              Close
            </ThemedText.TextPrimary>
            <ThemedText.TextPrimary fontWeight={400}>Edit</ThemedText.TextPrimary>
          </Row>
        }
        first={voteListIndex === 0}
        last={voteListIndex === voteListLength - 1}
      />
    </div>
  )
})

LoadedRow.displayName = 'LoadedRow'
