import { Trans } from '@lingui/macro'
import { ReactComponent as ToIcon } from 'assets/imgs/to.svg'
import { ButtonConfirmed } from 'components/Button'
import Column from 'components/Column'
import SwapCurrencyInputPanel from 'components/CurrencyInputPanel/SwapCurrencyInputPanel'
import Row, { RowBetween } from 'components/Row'
import TransactionConfirmationModal, { ConfirmationModalContent } from 'components/TransactionConfirmationModal'
import { useRoguxCurrency } from 'constants/tokens'
import dayjs from 'dayjs'
import { useLockContract } from 'hooks/useContract'
import { useInputAmount } from 'hooks/useInputAmount'
import { useCallback, useMemo, useState } from 'react'
import { Box } from 'rebass'
import { useTransactionAdder } from 'state/transactions/hooks'
import { TransactionType } from 'state/transactions/types'
import styled from 'styled-components/macro'
import { ThemedText } from 'theme'
import { formatCurrencyAmount } from 'utils/formatCurrencyAmount'

import { AmountBox, BoxCard, LockCard } from '../CreateLockModal/CreateLockModal'
import LockPicker from '../CreateLockModal/LockPicker'
import TimeCard from '../CreateLockModal/TimeCard'

const TabCard = styled(Row)`
  margin-top: 8px;
  height: 36px;
  border-radius: 18px;
  background-color: ${({ theme }) => theme.primaryBorder};

  .tabItem {
    flex: 1;
    height: 100%;
    border-radius: 18px;
    cursor: pointer;
  }
  .tabItemActive {
    background-color: ${({ theme }) => theme.connectBtnBackground};
  }
`

const EndCard = styled(Row)`
  background-color: ${({ theme }) => theme.backgroundInteractive};
  border: 1px solid ${({ theme }) => theme.primaryBorder};
  padding: 16px;
  border-radius: 16px;
`

interface ManageModalProps {
  isOpen: boolean
  onDismiss: () => void
  tokenId: string
  endTime: string
  veAmount: any
}
export default function ManageModal({ isOpen, onDismiss, tokenId, endTime, veAmount }: ManageModalProps) {
  const [curr, setCurr] = useState<any>(0)
  const tabList = ['Add', 'Unlock']

  const isDisabled = useMemo(() => (curr == 0 ? false : true), [curr])

  const [attemptingTxn, setAttemptingTxn] = useState<boolean>(false)
  const [txHash, setTxHash] = useState<string>('')
  const pendingText = 'pending ...'

  const [weeks, setWeeks] = useState(2)
  const unLockDate = useMemo(() => {
    return dayjs()
      .add(weeks * 7, 'day')
      .format('YYYY/MM/DD')
  }, [weeks])

  const isIncreaseTime = useMemo(() => {
    return (
      dayjs()
        .add(weeks * 7, 'day')
        .unix() > Number(endTime)
    )
  }, [endTime, weeks])

  const RoguxCurrency = useRoguxCurrency()
  const { inputAmount: amount, handleUserInput: setAmount } = useInputAmount(RoguxCurrency)
  const handleAmountChange = useCallback(
    (amount: string) => {
      setAmount(amount)
    },
    [setAmount]
  )
  const LockContract = useLockContract()

  const addTransaction = useTransactionAdder()

  const handleAddLock = useCallback(async () => {
    if (!LockContract || !tokenId) return

    if (weeks) {
      setAttemptingTxn(true)
      try {
        const response = await LockContract.increase_unlock_time(tokenId, 86400 * 7 * weeks)
        setAttemptingTxn(false)
        addTransaction(response, {
          type: TransactionType.EDIT_LOCK,
          token0Address: '',
          token1Address: '',
        })
        setTxHash(response.hash)
      } catch (error) {
        setAttemptingTxn(false)
        console.log('add Lock time error', error)
      }
    }

    if (amount.amount?.numerator.toString()) {
      setAttemptingTxn(true)
      try {
        const response = await LockContract.increase_amount(tokenId, amount.amount.numerator.toString())
        setAttemptingTxn(false)
        addTransaction(response, {
          type: TransactionType.EDIT_LOCK,
          token0Address: '',
          token1Address: '',
        })
        setTxHash(response.hash)
      } catch (error) {
        setAttemptingTxn(false)
        console.log('add Lock number error', error)
      }
    }
  }, [LockContract, addTransaction, amount, tokenId, weeks])

  const handleRemoveLock = useCallback(async () => {
    if (!LockContract) return
    // setAttemptingTxn(true)
    // try {
    //   const response = await LockContract.increase_amount(tokenId)
    //   setAttemptingTxn(false)
    //   addTransaction(response, {
    //     type: TransactionType.EDIT_LOCK,
    //     token0Address: '',
    //     token1Address: '',
    //   })
    //   setTxHash(response.hash)
    // } catch (error) {
    //   setAttemptingTxn(false)
    //   console.log('remove Lock error', error)
    // }
  }, [LockContract])
  const modalContent = () => {
    return <ModalContent />
  }

  function ModalContent() {
    return (
      <>
        <TabCard>
          {tabList?.map((tab: any, i: number) => (
            <Row
              justify="center"
              className={`tabItem ${curr == i && 'tabItemActive'}`}
              key={i}
              onClick={() => setCurr(i)}
            >
              {curr == i ? (
                <ThemedText.BodySmall fontWeight={700} color="textAddButton" className="">
                  {tab}
                </ThemedText.BodySmall>
              ) : (
                <ThemedText.TextSecondary fontWeight={700} fontSize={14}>
                  {tab}
                </ThemedText.TextSecondary>
              )}
            </Row>
          ))}
        </TabCard>
        <AmountBox>
          <ThemedText.HeadNav054>
            <Trans>Amounts</Trans>
          </ThemedText.HeadNav054>
          <BoxCard isDisabled={isDisabled}>
            <Box p=" 0 8px" pb="12px">
              <SwapCurrencyInputPanel
                label={<Trans>From</Trans>}
                value={amount.value}
                showMaxButton={false}
                currency={RoguxCurrency}
                id="LockAmount"
                onUserInput={handleAmountChange}
                // onMax={handleMaxInput}
                fiatValue={{ data: Number(amount), isLoading: false }}
                // onCurrencySelect={handleInputSelect}
                // otherCurrency={currencies[Field.OUTPUT]}
                loading={false}
                onMaxTab={handleAmountChange}
                showMaxTab={!isDisabled}
                disabled={isDisabled}
              />
            </Box>
          </BoxCard>
        </AmountBox>
        {curr == 0 ? (
          <>
            <LockPicker title="Lock Until" />
            <Column gap="xmd" mt="8px">
              <TimeCard setWeeks={setWeeks} />
              <LockCard>
                <RowBetween gap="sm" className="infoItem">
                  <ThemedText.TextSecondary fontSize={14}>
                    <Trans>Voting Power</Trans>
                  </ThemedText.TextSecondary>
                  <Row gap="sm" width="auto">
                    <ThemedText.TextSecondary fontWeight={700} fontSize={14}>
                      <Trans>{formatCurrencyAmount(veAmount, 2)} ROX</Trans>
                    </ThemedText.TextSecondary>
                    {Number(amount?.value) > 0 && (
                      <>
                        <ToIcon />
                        <ThemedText.TextPrimary fontWeight={700}>
                          <Trans>3,100.99 ROX</Trans>
                        </ThemedText.TextPrimary>
                      </>
                    )}
                  </Row>
                </RowBetween>
                <RowBetween gap="sm" className="infoItem">
                  <ThemedText.TextSecondary fontSize={14}>
                    <Trans>Expires in</Trans>
                  </ThemedText.TextSecondary>
                  <Row gap="sm" width="auto">
                    <ThemedText.TextSecondary fontSize={14}>
                      <Trans>6</Trans>
                    </ThemedText.TextSecondary>
                    <ToIcon />
                    <ThemedText.TextPrimary>
                      <Trans>{weeks * 7} Days</Trans>
                    </ThemedText.TextPrimary>
                  </Row>
                </RowBetween>
                <RowBetween gap="sm" className="infoItem">
                  <ThemedText.TextSecondary fontSize={14}>
                    <Trans>Lock Until</Trans>
                  </ThemedText.TextSecondary>
                  <Row gap="sm" width="auto">
                    <ThemedText.TextSecondary fontSize={14}>
                      <Trans>{dayjs(Number(endTime) * 1000).format('YYYY/MM/DD')}</Trans>
                    </ThemedText.TextSecondary>
                    {isIncreaseTime && (
                      <>
                        <ToIcon />
                        <ThemedText.TextPrimary>
                          <Trans>{unLockDate}</Trans>
                        </ThemedText.TextPrimary>
                      </>
                    )}
                  </Row>
                </RowBetween>
              </LockCard>
              <ButtonConfirmed onClick={handleAddLock}>Lock</ButtonConfirmed>
            </Column>
          </>
        ) : (
          <>
            <Column gap="xmd" mt="8px">
              <EndCard>
                <RowBetween gap="sm" className="infoItem">
                  <ThemedText.TextSecondary fontSize={14}>
                    <Trans>Ends in</Trans>
                  </ThemedText.TextSecondary>
                  <ThemedText.TextPrimary fontWeight={700} fontSize={16}>
                    <Trans>2025/01/12</Trans>
                  </ThemedText.TextPrimary>
                </RowBetween>
              </EndCard>
              <ThemedText.BodySmall color="removeBackground">
                <Trans>
                  *As there is still 89% lock time remaining, you can only get 11% ROX back if you unlock it now. Rest
                  of the 89% ROX will be burnt by the contract.
                </Trans>
              </ThemedText.BodySmall>
              <LockCard>
                <RowBetween gap="sm" className="infoItem">
                  <ThemedText.TextSecondary fontSize={14}>
                    <Trans>Receive</Trans>
                  </ThemedText.TextSecondary>
                  <ThemedText.TextPrimary fontWeight={700}>
                    <Trans>3,100.99 ROX</Trans>
                  </ThemedText.TextPrimary>
                </RowBetween>
              </LockCard>
              <ButtonConfirmed onClick={handleRemoveLock}>Lock</ButtonConfirmed>
            </Column>
          </>
        )}
      </>
    )
  }

  return (
    <TransactionConfirmationModal
      isOpen={isOpen}
      onDismiss={onDismiss}
      attemptingTxn={attemptingTxn}
      hash={txHash}
      width="400px"
      reviewContent={() => (
        <ConfirmationModalContent
          title={<Trans>Create New Lock</Trans>}
          onDismiss={onDismiss}
          topContent={modalContent}
        />
      )}
      pendingText={pendingText}
    />
    // <Modal isOpen={isOpen} $scrollOverlay={true} onDismiss={onDismiss} maxHeight={90} width="500px">
    //   <ConfirmationModalContent title={<Trans>Manage</Trans>} onDismiss={onDismiss} topContent={modalHeader} />
    // </Modal>
  )
}
